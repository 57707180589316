<button type="button" class="btn-close mt-3 ms-3" (click)="closeProvidedByDialog()" aria-label="Close"></button>
<h1 mat-dialog-title class="text-center mb-sm-3 mb-0 full-screen-dialog">Booking Data</h1>
<div mat-dialog-content class="overflow-auto pb-5" #dialogContent>
  <form (submit)="searchMerchants()" [formGroup]="variationForm">
    <div class="row mx-auto" style="max-width: 800px;">
      <div class="col-12">
        <div class="form-group mt-2">
          <label for="" class="ms-2 fw-bold">Choose Activity</label>
          <select class="form-control  input-bg-color" *ngIf="!activityDefined"
            [ngClass]="{ 'is-invalid': isSubmitting && f['activityId'].errors }" formControlName="activityId"
            (change)="onActivityChange()">
            <option value="">Select..</option>
            <option *ngFor="let activities of merchantActivities" [value]="activities.activityid">{{
              activities.activityname + ' (' + activities.addressline1 +')'}}</option>
          </select>
          <div *ngIf="isSubmitting && f['activityId'].errors" class="invalid-feedback">
            <div *ngIf="f['activityId'].errors['required']">Activity is required</div>
          </div>
          <p class="ms-2" *ngIf="activityDefined">{{activityName}}</p>
        </div>
      </div>
    </div>
    <div class="row mx-auto" style="max-width: 800px;" *ngIf="category === 'camping'">
      <div class="col-6">
        <div class="form-group mt-2">
          <label for="" class="ms-2 fw-bold">Check-in </label>
          <input type="text" class="form-control datepicker input-bg-color"  (keydown)="preventToggleDefault($event)"
            [ngClass]="{ 'is-invalid': isSubmitting && f['date'].errors }" formControlName="date" placeholder="Date"
            [min]="startDate" [(ngModel)]="currentDate" />
          <div *ngIf="isSubmitting && f['date'].errors" class="invalid-feedback">
            <div *ngIf="f['date'].errors['required']">Check-in is required</div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group mt-2">
          <label for="" class="ms-2 fw-bold">Check-out </label>
          <input type="text" class="form-control datepicker1 input-bg-color"  (keydown)="preventToggleDefault($event)"
            [ngClass]="{ 'is-invalid': isSubmitting && f['checkoutdate'].errors }" formControlName="checkoutdate"
            placeholder="Date" [min]="startDate" [(ngModel)]="currentDate1" />
          <div *ngIf="isSubmitting && f['checkoutdate'].errors" class="invalid-feedback">
            <div *ngIf="f['checkoutdate'].errors['required']">Check-out is required</div>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="form-group mt-2">
          <label class="ms-2  fw-bold" for=""> Total Guests</label>
          <input type="text" class="form-control text-center  input-bg-color font-size-14px" readonly
            formControlName="guest" [(ngModel)]="totalGuest" />
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="form-group mt-2">
          <label class="ms-2  fw-bold" for=""> Adults</label>
          <div class="input-group ">
            <span class="input-group-btn my-auto ps-2 pointer" (click)="decreaseValue('adult')">
              <img src="/assets/minus.svg" width="25px" alt="minus" />
            </span>
            <input type="text" class="form-control input-number text-center font-size-14px" readonly formControlName="adult"
              [(ngModel)]="adultsCount" min="1" max="10">
            <span class="input-group-btn  my-auto pe-2 pointer" (click)="increaseValue('adult')">
              <img src="/assets/plus.svg" width="25px" alt="plus" />
            </span>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="form-group mt-2">
          <label class="ms-2  fw-bold" for="">Children</label>
          <div class="input-group ">
            <span class="input-group-btn my-auto ps-2 pointer " (click)="decreaseValue('children')">
              <img src="/assets/minus.svg" width="25px" alt="minus" />
            </span>
            <input type="text" class="form-control input-number font-size-14px text-center" readonly formControlName="children"
              [(ngModel)]="childrenCount" min="1" max="10">
            <span class="input-group-btn  my-auto pe-2 pointer" (click)="increaseValue('children')">
              <img src="/assets/plus.svg" width="25px" alt="plus" />
            </span>
          </div>
        </div>
      </div>
      <ng-container formArrayName="variations">
        <div class="col-6 col-md-4" *ngFor="let variation of variationsArrayControls; let i = index" [formGroupName]="i">
          <div class="form-group mt-2">
            <label for="" class="ms-2 fw-bold">{{variations[i].variations.type}}</label>
            <select name="book-time" class="form-control  input-bg-color" formControlName="variationvalueid"
              [ngClass]="{ 'is-invalid': isSubmitting && variation.get('variationvalueid')?.errors?.['required'] }">
              <option value="">Select</option>
              <option *ngFor="let variationValue of variationValues[i]" [value]="variationValue.variationvalues.id">{{
                variationValue.variationvalues.value }}</option>
            </select>
            <div *ngIf="isSubmitting && variation.get('variationvalueid')?.errors?.['required']" class="invalid-feedback">
              <div *ngIf="variation.get('variationvalueid')?.errors?.['required']">Variation Value is required</div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="row mx-auto" style="max-width: 800px;" *ngIf="category === 'rafting' || category === 'activity'">
      <div class="col-6 col-md-4">
        <div class="form-group mt-2">
          <label for="" class="ms-2 fw-bold">Date</label>
          <input type="text" class="form-control datepicker input-bg-color"  (keydown)="preventToggleDefault($event)"
            [ngClass]="{ 'is-invalid': isSubmitting && f['date'].errors }" formControlName="date" placeholder="Date"
            [min]="startDate" [(ngModel)]="currentDate" />
          <div *ngIf="isSubmitting && f['date'].errors" class="invalid-feedback">
            <div *ngIf="f['date'].errors['required']">Date is required</div>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="form-group mt-2">
          <label for="" class="ms-2 fw-bold">Time</label>
          <select name="book-time" id="book-time" class="form-control  input-bg-color"
            formControlName="time" [ngClass]="{ 'is-invalid': isSubmitting && f['time'].errors }">
            <option value="">Select</option>
            <option *ngFor="let timing of timings"  [disabled]="isPastDateTime(timing, 'time')">{{ timing }}</option>
          </select>
          <div *ngIf="isSubmitting && f['time'].errors" class="invalid-feedback">
            <div *ngIf="f['time'].errors['required']">Time is required</div>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="form-group mt-2">
          <label for="" class="ms-2 fw-bold">Guests</label>
          <input type="text" class="form-control  input-bg-color"
            [ngClass]="{ 'is-invalid': isSubmitting && f['guest'].errors }" formControlName="guest"
            placeholder="Total adults" />
          <div *ngIf="isSubmitting && f['guest'].errors" class="invalid-feedback">
            <div *ngIf="f['guest'].errors['pattern']">Guests should not be Zero & not be greater than 9.</div>
            <div *ngIf="f['guest'].errors['required']">No of adult is required</div>
          </div>
        </div>
      </div>
      <ng-container formArrayName="variations">
        <div class="col-6 col-md-4" *ngFor="let variation of variationsArrayControls; let i = index" [formGroupName]="i">
          <div class="form-group mt-2">
            <label for="" class="ms-2 fw-bold">{{variations[i].variations.type}}</label>
            <select name="book-time" class="form-control  input-bg-color" formControlName="variationvalueid"
              [ngClass]="{ 'is-invalid': isSubmitting && variation.get('variationvalueid')?.errors?.['required'] }">
              <option value="">Select</option>
              <option *ngFor="let variationValue of variationValues[i]" [value]="variationValue.variationvalues.id">{{
                variationValue.variationvalues.value }}</option>
            </select>
            <div *ngIf="isSubmitting && variation.get('variationvalueid')?.errors?.['required']" class="invalid-feedback">
              <div *ngIf="variation.get('variationvalueid')?.errors?.['required']">Variation Value is required</div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="row mx-auto" style="max-width: 800px;" *ngIf="category === 'bikerental'">
      <div class="col-6">
        <div class="form-group mt-2">
          <label for="" class="ms-2 fw-bold">Pickup Date</label>
          <input type="text" class="form-control datepicker input-bg-color"  (keydown)="preventToggleDefault($event)"
            [ngClass]="{ 'is-invalid': isSubmitting && f['date'].errors }" formControlName="date" placeholder="Date"
            [min]="startDate" [(ngModel)]="currentDate" />
          <div *ngIf="isSubmitting && f['date'].errors" class="invalid-feedback">
            <div *ngIf="f['date'].errors['required']">Date is required</div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group mt-2">
          <label for="" class="ms-2 fw-bold">Pickup Time</label>
          <select name="book-time" class="form-control  input-bg-color" formControlName="time"
            [ngClass]="{ 'is-invalid': isSubmitting && f['time'].errors }">
            <option value="">Select</option>
            <option *ngFor="let timing of timings"  [disabled]="isPastDateTime(timing, 'time')">{{ timing }}</option>
          </select>
          <div *ngIf="isSubmitting && f['time'].errors" class="invalid-feedback">
            <div *ngIf="f['time'].errors['required']">Time is required</div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group mt-2">
          <label for="" class="ms-2 fw-bold">Dropdown Date</label>
          <input type="text" class="form-control datepicker1 input-bg-color"  (keydown)="preventToggleDefault($event)"
          [ngClass]="{ 'is-invalid': isSubmitting && f['dropdowndate'].errors }" formControlName="dropdowndate" placeholder="Date"
          [min]="startDate" [(ngModel)]="dropDate" />
          <div *ngIf="isSubmitting && f['dropdowndate'].errors" class="invalid-feedback">
            <div *ngIf="f['dropdowndate'].errors['required']">Dropdown Date is required</div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group mt-2">
          <label for="" class="ms-2 fw-bold">Dropdown Time</label>
          <select name="book-time" class="form-control  input-bg-color" formControlName="dropdowntime"
            [ngClass]="{ 'is-invalid': isSubmitting && f['dropdowntime'].errors }">
            <option value="">Select</option>
            <option *ngFor="let timing of timings"  [disabled]="isPastDateTime(timing, 'drop')">{{ timing }}</option>
          </select>
          <div *ngIf="isSubmitting && f['dropdowntime'].errors" class="invalid-feedback">
            <div *ngIf="f['dropdowntime'].errors['required']">Dropdown Time is required</div>
          </div>
        </div>
      </div>
      <ng-container formArrayName="variations">
        <div class="col-6 col-md-4" *ngFor="let variation of variationsArrayControls; let i = index" [formGroupName]="i">
          <div class="form-group mt-2">
            <label for="" class="ms-2 fw-bold">{{variations[i].variations.type}}</label>
            <select name="book-time" class="form-control  input-bg-color"  (change)="changeRentalDefaultVariation(i)"  formControlName="variationvalueid"
              [ngClass]="{ 'is-invalid': isSubmitting && variation.get('variationvalueid')?.errors?.['required'] }">
              <option value="">Select</option>
              <option *ngFor="let variationValue of variationValues[i]" [value]="variationValue.variationvalues.id">{{
                variationValue.variationvalues.value }}</option>
            </select>
            <div *ngIf="isSubmitting && variation.get('variationvalueid')?.errors?.['required']" class="invalid-feedback">
              <div *ngIf="variation.get('variationvalueid')?.errors?.['required']">This filed is required</div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- <ng-container formArrayName="inventoryvariations">
        <div class="col-6 col-md-4" *ngFor="let inventoryVariation of inventoryvariationsArrayControls; let i = index" [formGroupName]="i">
          <div class="form-group mt-2">
            <label for="" class="ms-2 fw-bold">{{inventoryVariations[i].type}}</label>
            <select name="book-time" class="form-control  input-bg-color"  (change)="changeInventoryVariation(i)"  formControlName="variationvalueid"
              [ngClass]="{ 'is-invalid': isSubmitting && inventoryVariation.get('variationvalueid')?.errors?.['required'] }">
              <option value="">Select</option>
              <option *ngFor="let variationValue of inventoryVariationValues[i]" [value]="variationValue.id">{{
                variationValue.value }}</option>
            </select>
            <div *ngIf="isSubmitting && inventoryVariation.get('variationvalueid')?.errors?.['required']" class="invalid-feedback">
              <div *ngIf="inventoryVariation.get('variationvalueid')?.errors?.['required']">This field is required</div>
            </div>
          </div>
        </div>
      </ng-container> -->
    </div>
    <div class="row mx-auto" style="max-width: 800px;" *ngIf="category === 'trekking' || category === 'tour'">
      <div class="col-6">
        <div class="form-group mt-2">
          <label for="" class="ms-2 fw-bold">Month </label>
          <select name="" class="form-control  input-bg-color" [ngClass]="{ 'is-invalid': isSubmitting && f['month'].errors }" (change)="changeMonths()" formControlName="month">
            <option value="">Select</option>
            <option *ngFor="let month of months">{{ month }}</option>
          </select>
          <div *ngIf="isSubmitting && f['month'].errors" class="invalid-feedback">
            <div *ngIf="f['month'].errors['required']">Month is required</div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group mt-2">
          <label for="" class="ms-2 fw-bold">Slot</label>
          <select name="" class="form-control  input-bg-color" [ngClass]="{ 'is-invalid': isSubmitting && f['slot'].errors }" formControlName="slot">
            <option value="">Select</option>
            <option *ngFor="let slot of slots" >{{ slot }}</option>
          </select>
          <div *ngIf="isSubmitting && f['slot'].errors" class="invalid-feedback">
            <div *ngIf="f['slot'].errors['required']">Slot is required</div>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4" *ngIf="category === 'trekking'">
        <div class="form-group mt-2">
          <label class="ms-2  fw-bold" for=""> Total Guests</label>
          <input type="text" class="form-control text-center  input-bg-color font-size-14px" formControlName="guest" />
        </div>
      </div>
      <div *ngIf="category === 'tour'">
      <ng-container formArrayName="variations">
        <div class="col-6 col-md-4" *ngFor="let variation of variationsArrayControls; let i = index" [formGroupName]="i">
          <div class="form-group mt-2">
            <label for="" class="ms-2 fw-bold">{{variations[i].variations.type}}</label>
            <select name="book-time" class="form-control  input-bg-color" formControlName="variationvalueid"
              [ngClass]="{ 'is-invalid': isSubmitting && variation.get('variationvalueid')?.errors?.['required'] }">
              <option value="">Select</option>
              <option *ngFor="let variationValue of variationValues[i]" [value]="variationValue.variationvalues.id">{{
                variationValue.variationvalues.value }}</option>
            </select>
            <div *ngIf="isSubmitting && variation.get('variationvalueid')?.errors?.['required']" class="invalid-feedback">
              <div *ngIf="variation.get('variationvalueid')?.errors?.['required']">Variation Value is required</div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    </div>
 
    <div class="text-center mt-3">
      <button type="submit" class="btn search-btn px-5">Submit</button>
    </div>
  </form>