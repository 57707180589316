import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateConversionService {

  constructor() { }

  
  convertToDateRange(dateRangeStr: string): { startDate: string, endDate: string } {
    // Split the input string into start and end date parts
    const [startPart, endPart] = dateRangeStr.split(' to ');

    // Extract the day and month parts from start and end date strings
    const [startDay, startMonth] = startPart.split(' ');
    const [endDay, endMonth] = endPart.split(' ');

    // Get the month index for start and end months
    const startMonthIndex = this.getMonthIndex(startMonth);
    const endMonthIndex = this.getMonthIndex(endMonth);

    // Create Date objects for start and end dates
    const startDate = new Date();
    startDate.setDate(parseInt(startDay, 10));
    startDate.setMonth(startMonthIndex);

    const endDate = new Date();
    endDate.setDate(parseInt(endDay, 10));
    endDate.setMonth(endMonthIndex);

    // Format start and end dates to YYYY-MM-DD format
    const formattedStartDate = this.formatDate(startDate);
    const formattedEndDate = this.formatDate(endDate);

    return { startDate: formattedStartDate, endDate: formattedEndDate };
  }

  private getMonthIndex(month: string): number {
    // Define month names and find index of given month
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthNames.indexOf(month);
  }

  private formatDate(date: Date): string {
    // Format date to YYYY-MM-DD format
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    return `${year}-${month}-${day}`;
  }

  private padZero(num: number): string {
    // Add leading zero if needed
    return num < 10 ? `0${num}` : `${num}`;
  }

  public  convertTimestampToDate(timestamp: number): string {
    if (!timestamp) {
      return 'Invalid date';
    }
    const date = new Date(timestamp);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  
  }
}
