<!-- footer -->
<div
  *ngIf="!ifMobileApp() && !router.url.includes('agentbooking')  && !router.url.includes('partnerbooking')  && (!bookingSource || UserType == 'partner')">
  <footer class="text-center text-lg-start background">
    <!-- Grid container -->
    <div class="container p-4">
      <!--Grid row-->
      <div class="row">
        <div class="col-lg-3 col-md-6 text-center text-md-start mb-md-0">
          <div style="width: fit-content; align-items: center;" class="text-center d-md-block d-inline-flex ">
            <img src="/assets/new-logo.svg" width="45px" alt="logo">
            <h1 class="text-dark logo mt-2 mb-0"><b>Advenzone</b></h1>
           
          </div>
          <div class="d-flex justify-content-md-start justify-content-center mobile-footer">
            <a href="https://www.facebook.com/AdvenzoneApp" target="_blank">
              <img src="/assets/fb.svg" width="38px" alt="fb" class="ms-0 mt-2" ></a>
            <a href="https://www.instagram.com/advenzone/" target="_blank">
              <img src="/assets/instagram.svg" width="38px" alt="instagram" class="ms-2 mt-2"></a>
            <a href="https://www.linkedin.com/company/advenzone/" target="_blank">
              <img src="/assets/linkedIn.svg" width="38px" alt="linkedIn" class="ms-2 mt-2"></a>
          </div>
          <!-- <h5 class="mt-3 mb-0 text-dark heading">Address</h5>
          <div class=" mb-0 mt-3" style="line-height:3em">
            <h6 class="text-dark title">SMR Vinay City,</h6>
            <h6 class="text-dark title">Miyapur, </h6>
            <h6 class="text-dark title">Hyderabad 500049</h6>
          </div> -->
        </div>

        <div class="col-lg-3 col-md-6 text-start mb-md-0 mt-md-0 mt-3  d-md-block d-none">
          <h1 class=" mb-0 text-dark heading">Quick Links</h1>

          <ul class="list-unstyled mb-0  mt-3" *ngIf="UserType !== 'merchant' && UserType !== 'partner'"
            style="line-height:3em">
            <li>
              <a href="/home" class="text-dark text-decoration-none  title">Home</a>
            </li>
            <li>
              <a  href="javascript:void(0)" *ngIf="UserType !== 'user'" (click)="openProfile()" class="text-dark text-decoration-none">Register/Sign in</a>
            </li>
            <li>
              <a  href="javascript:void(0)" *ngIf="UserType !== 'user'" (click)="guestLogin()" class="text-dark text-decoration-none">Guest Login</a>
            </li>
            <li>
              <a  href="javascript:void(0)" *ngIf="UserType !== 'user'" (click)="openMerchantLoginDialogue()" class="text-dark text-decoration-none">Merchant Login</a>
            </li>
            <li>
                <a href="javascript:void(0)" *ngIf="UserType !== 'user'" (click)="loginPartner()" class="text-dark text-decoration-none title">Partner Login </a>
              </li>
            <!-- <li>
                <a href="/ProfileManagement" class="text-dark text-decoration-none">Profile</a>
              </li> -->
           
          </ul>
          <ul class="list-unstyled mb-0  mt-3" *ngIf="UserType === 'merchant'" style="line-height:3em">
            <li>
              <a href="/merchant-bookings" class="text-dark text-decoration-none  title">Dashboard</a>
            </li>
            <li>
              <a href="/merchant-reviews" class="text-dark text-decoration-none title">Reviews</a>
            </li>
            <li>
              <a href="/full-merchant-profile" class="text-dark text-decoration-none title">Profile</a>
            </li>
            <!-- <li>
                <a href="/ProfileManagement" class="text-dark text-decoration-none">Profile</a>
              </li> -->
            <!-- <li>
                <a href="#" class="text-dark text-decoration-none">Merchant Login</a>
              </li> -->
          </ul>
        </div>
        <!--Grid column-->
        <div class="col-lg-3 col-md-6 text-start mb-md-0  mt-md-0 mt-3  d-md-block d-none">
          <h1 class=" mb-0 text-dark heading">Support</h1>

          <ul class="list-unstyled mb-0 mt-3" style="line-height:3em">
            <!-- <li>
                <a href="#" class="text-dark text-decoration-none ">FAQ</a>
              </li> -->
            <li>
              <a href="/terms-condition" class="text-dark text-decoration-none title">Terms & Conditions</a>
            </li>
            <li>
              <a href="/about-us" class="text-dark text-decoration-none title">About Us</a>
            </li>
            <li>
              <a href="/faq" class="text-dark text-decoration-none title">FAQ</a>
            </li>
            <li>
              <a href="/cancellation-policy" class="text-dark text-decoration-none title">Cancellation Policy</a>
            </li>
            <li>
              <a href="/privacy-policy" class="text-dark text-decoration-none title">Privacy Policy</a>
            </li>
            <li>
              <a href="/contact-us" 
                class="text-dark text-decoration-none title">Contact Us </a>
                <!-- <a href="javascript:void(0)" (click)="openContactUsForm()"
                class="text-dark text-decoration-none title">Contact Us </a> -->
            </li>

          </ul>
        </div>
        <!-- <div class="col-lg-3 col-md-6 text-start mb-md-0  mt-md-0 mt-3">
          <h5 class=" mb-0 text-dark heading">Address</h5>
          <div class=" mb-0 mt-3" style="line-height:3em">
            <h6 class="text-dark title">SMR Vinay City,</h6>
            <h6 class="text-dark title">Miyapur, </h6>
            <h6 class="text-dark title">Hyderabad 500049</h6>
          </div>
        </div> -->
        <div class="col-lg-3 col-md-6 mb-md-0 text-center text-md-start  mt-md-0 mt-3">
          <h1 class="mb-0 text-dark heading">Download Our App</h1>
          <!-- <form class="mt-3" (submit)="onFormSubmit()" [formGroup]="contactUsForm">
            <div class="form-outline ">
              <input type="text" id="form3Example1" class="form-control inputbox" formControlName="name"
                placeholder="Name" [ngClass]="{ 'is-invalid': isSubmitting && f['name'].errors }" />
              <div *ngIf="isSubmitting && f['name'].errors" class="invalid-feedback">
                <div *ngIf="f['name'].errors['required']">
                  Name is required
                </div>
              </div>
            </div>
            <div class="row">
            <div class="form-outline mt-3  col-6 pe-1">
              <input type="email" id="form3Example3" class="form-control inputbox" formControlName="email"
                placeholder="Email address" [ngClass]="{ 'is-invalid': isSubmitting && f['email'].errors }" />
              <div *ngIf="isSubmitting && f['email'].errors" class="invalid-feedback">
                <div *ngIf="f['email'].errors['required']">Email is required</div>
                <div *ngIf="f['email'].errors['email']">Email is invalid</div>
              </div>

            </div>
            <div class="form-outline mt-3 col-6 ps-1">
              <input type="text" id="form3Example4" class="form-control inputbox" formControlName="phone"
                [ngClass]="{ 'is-invalid': isSubmitting && f['phone'].errors }" placeholder="Phone Number"
                maxlength="10" oninput="this.value = this.value.replace(/[^0-9 ]/,'')" />
              <div *ngIf="isSubmitting && f['phone'].errors" class="invalid-feedback">
                <div *ngIf="f['phone'].errors['required']">Phone Number is required</div>
                <div *ngIf="f['phone'].errors['invalidPhoneNumber']">Invalid Phone number.</div>
              </div>
            </div>
          </div>
            <div class="form-outline mt-3">
              <textarea name="description" id="description" class="form-control"
                [ngClass]="{ 'is-invalid': isSubmitting && f['message'].errors }" formControlName="message"
                placeholder="Enter your Feedback" rows="4" style="resize: none"></textarea>
              <div *ngIf="isSubmitting && f['message'].errors" class="invalid-feedback">
                <div *ngIf="f['message'].errors['required']">
                  Message is required
                </div>
              </div>
            </div>
            <div class=" text-center mt-2 ">
              <a><button type="submit" class="btn create-btn btn-primary btn-block ms-3 mt-2 border-0 text-light text-center">Submit
                </button></a>
            </div>
          </form> -->
          <div class="d-inline">
            <a class="btn px-0" (click)="openUrl('https://apps.apple.com/in/app/advenzone/id6451099198')"><img src="/assets/apple-available.png" class=" app-icon" alt="appleIcon"></a>
          <a class="btn px-0 app-icon" (click)="openUrl('https://play.google.com/store/apps/details?id=com.advenzone')"><img src="/assets/playstore-available.png" class="app-icon" alt="playstore"></a>
          </div>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
    <!-- Grid container -->

    <!-- Copyright -->
    <div class="text-center p-3" style="background-color: white;">

      <a class="text-black text-decoration-none d-flex justify-content-center align-items-end" href="#">www.{{hostName}} | © copyright 2023 | All Rights Reserved |
        Made with Thrill <img src="/assets/thrill-factor.gif" class="ms-1"  width="30px" height="30px" alt="thrillFactor"></a>
    </div>
    <!-- Copyright -->
  </footer>
</div>
<div *ngIf="router.url.includes('partnerbooking') || bookingSource && UserType != 'partner'">
  <footer class="text-center text-lg-start" style="background: #3EBDC6;">
    <!-- Grid container -->
    <div class="px-4 p-2">
      <!--Grid row-->
      <div class="row">
        <div class=" col-md-3 text-start mb-md-0">
          <div style="width: fit-content;" class="text-center">
            <img src="/assets/new-logo.svg" width="35px" alt="logo">
            <h5 class="text-dark title mt-2"><b>Advenzone</b></h5>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 text-start mb-md-0">
          <div class="d-md-flex d-block mt-3 align-items-center">
            <h5 class="fw-bold text-light">Download Our App</h5>
            <a class="btn" (click)="openUrl('https://apps.apple.com/in/app/advenzone/id6451099198')"><img src="/assets/apple-available.png" alt="appleIcon"></a>
            <a class="btn" (click)="openUrl('https://play.google.com/store/apps/details?id=com.advenzone')"><img src="/assets/playstore-available.png" alt="playstore"></a>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 text-md-end text-start mb-md-0">
          <div class="h-100 d-flex align-items-center justify-content-end">
            <button class="btn text-dark my-2 me-2" (click)="clear_localStorage()">Logout</button>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>