import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  private savedData: any[] = [];
  notification:any=[];
  private dataSubject = new BehaviorSubject<any[]>([]);
  private mobileDiscountPopup = new BehaviorSubject<boolean>(false);
  isMobileDiscountPopupShowed = this.mobileDiscountPopup.asObservable();

  setIsMobileDiscountPopupShowed(value: boolean): void {
    this.mobileDiscountPopup.next(value);
  }
    
  public data$: Observable<any[]> = this.dataSubject.asObservable();


  constructor(public http:HttpClient) {
    this.savedData = [];
   }

  addSavedData(data: any) {
    this.savedData = [data];
    this.dataSubject.next(this.savedData);
  }

  getSavedData(): Observable<any[]> {
    return this.data$;
  }



  getActivitiesDistance(latitudeFrom:any,longitudeFrom:any, latitudeTo:any, longitudeTo:any) : Observable<any[]>{
    return this.http.post<any>(environment.apiUrl + `/merchantactivities/activitiesdistance`, { latitudeFrom, longitudeFrom, latitudeTo, longitudeTo}, { responseType: 'json' })
  }

  openMap(latitude:any, longitude:any){
    if (!this.ifMobileApp()) {
      //web browser
       window.open(`https://maps.google.com/?q=${Number(latitude)},${Number(longitude)}`, '_system');
    }else{
      if ((window as any).cordova && (window as any).cordova.platformId === "ios") {
         //ios
        var mapLocationUrl = `http://maps.apple.com/?daddr=${Number(latitude)},${Number(longitude)}`;
        window.open(mapLocationUrl, '_system', 'location=yes');  
      }else{
        //android
        (window as any).cordova.InAppBrowser.open(`https://maps.google.com/?q=${Number(latitude)},${Number(longitude)}`, '_system', 'location=yes');

        // window.open(`https://maps.google.com/?q=${Number(latitude)},${Number(longitude)}`, '_system');
      }
    }
  }

  ifMobileApp() {
    if (window.navigator.userAgent.includes('AdvenzoneMobileApp')) {
      return true;
    } else {
      return false;
    }
  }

  private locationSource = new BehaviorSubject<any>(null);
  currentLocationSource = this.locationSource.asObservable();

  changelocationSource(location: any) {
    this.locationSource.next(null);
    this.locationSource.next(location);
  }
}
