import { Component, Input } from '@angular/core';


interface details {
  id: any;
  name: any;
  src: any;
  hasCoupon: any;
  min_price:any;
  locationid:any;
  availability:any;
}

@Component({
  selector: 'app-bike-rental-card',
  templateUrl: './bike-rental-card.component.html',
  styleUrls: ['./bike-rental-card.component.css']
})
export class BikeRentalCardComponent {
  @Input() data: details;

}
