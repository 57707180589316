<div class="pb-2 bike-rental-card-card shadow bg-white">
    <div class="position-relative">
        <ngb-carousel class="bike-rental-card-img-carousel-height" *ngIf="data.src && data.src.length > 0">
            <ng-template *ngFor="let data of data.src" ngbSlide (click)="$event.stopPropagation()">
                <div class="bike-rental-card-wrapper">
                    <img [src]="data" alt="image" class="image-data-src">
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
    <div class="px-2">
        <h3 class="bike-rental-card-title mt-2 text-truncate">{{data.name}}</h3>
        <div class="d-flex bike-rental-card-content">
            <p class="ms-2"><img src="/assets/cards/tick-icon.svg" class="me-2" alt="hardness icon">{{(data.availability == true)? 'Available' : 'Not Available'}}</p>
        </div>
        <hr>
        <div class="d-flex justify-content-between align-items-end" style="min-height: 57px;">
            <div *ngIf="data.min_price != 0">
                <h6 class="bike-rental-card-starts-from mb-1">Starting from</h6>
                <h4 class="bike-rental-card-price mb-1">₹ {{data.min_price}}</h4>
            </div>
            <div *ngIf="data.min_price == 0">
                <button type="button" class="btn rounded-pill px-4 bike-rental-card-enquiry">Enquiry</button>
            </div>
            <div>
                <button type="button" class="btn bike-rental-card-view-details rounded-pill pe-1 ps-3">View Providers <img
                        src="/assets/cards/view-details.svg" class="ms-2" width="25px" alt="view details arrow"></button>
            </div>
        </div>
    </div>
</div>