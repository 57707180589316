<section class="booking-section mb-2">
    <div class="content mx-auto px-2">
        <h1 class="heading text-center">Bookings</h1>
    <div class="d-flex justify-content-center">
        <mat-chip-listbox class="width-fit-content" (change)="switchData($event)">
            <mat-chip-option [selected]="upcoming" value="upcoming"
                [ngClass]="{'selected-chip': upcoming}" (click)="viewBookings()">Upcoming</mat-chip-option>
            <mat-chip-option [selected]="pendingbooking" value="pendingbooking"
                [ngClass]="{'selected-chip': pendingbooking}" (click)="viewPendingBooking()">Pending Booking</mat-chip-option>    
            <mat-chip-option [selected]="completed" value="completed"
                [ngClass]="{'selected-chip': completed}">Completed</mat-chip-option>
            <mat-chip-option [selected]="cancelled" value="cancelled" (click)="viewCancellBooking()"
                [ngClass]="{'selected-chip': cancelled}">Cancelled</mat-chip-option>
        </mat-chip-listbox>
    </div>
    <div class="mt-3">
        <div class="upcoming" *ngIf="upcoming">
            <h2 *ngIf="upcomingBookings.length === 0" class="mt-3 text-center">No Successful Bookings...</h2>
            <div class="py-2" *ngIf="upcomingBookings &&  upcomingBookings.length > 0">
                <h5 class="title text-success ms-2">Successful Bookings</h5>
                <div class="" *ngFor="let booking of upcomingBookings">
                    <div class="px-3 py-2 rounded shadow mt-3">
                        <h4 *ngIf="booking.category != 'bikerental' && booking.category != 'camping'" class="my-1 bookings">You Booked <b>{{booking.activitytypemastername}}</b> ({{booking.activityaddress}}) at
                            <b>{{booking.activitieslocationname}}</b> on <b>{{formatDate(booking.date)}}</b> at
                            <b>{{booking.time}}</b> for <b>{{booking.guest}} {{ booking.guest == 1 ? 'person' : 'persons' }}</b> provided by
                            <b>{{booking.merchantname}}</b></h4>
                        <h4  *ngIf="booking.category == 'bikerental'" class="my-1 bookings">You Booked <b>{{booking.activitytypemastername}}</b> {{(booking.category) != 'bikerental' ? '('+booking.activityaddress+')':''}} at
                            <b>{{booking.activitieslocationname}}</b> from <b>{{formatDate(booking.date) + ' ' +booking.time}}</b> to <b>{{formatDate(booking.todate) + ' ' +booking.totime}}</b>  provided by
                            <b>{{booking.merchantname}}</b></h4>
                        <h4  *ngIf="booking.category == 'camping'"  class="my-1 bookings">You Booked <b>{{booking.activitytypemastername}}</b> ({{booking.activityaddress}})  at
                            <b>{{booking.activitieslocationname}}</b> from <b>{{formatDate(booking.date)}}</b> at
                            <b>{{booking.time}}</b> to <b>{{formatDate(booking.todate) + ' ' +booking.time}}</b> for <b>{{booking.totalguest}} {{ booking.totalguest == 1 ? 'person' : 'persons' }}</b> provided by
                            <b>{{booking.merchantname}}</b></h4>    
                        <div class="d-flex justify-content-between">
                            <h6 class="text-end mb-1 date">{{ timestampsToDate(booking.createdon)}}</h6>
                            <!-- <button type="button" class="btn default-color mb-1 d-block"
                                style="font-size: 10px; width: 100px;" (click)="writeReview(booking.id, booking.merchantid, booking.activityid)">Write a
                                Review</button> -->
                                <div>
                                <a [routerLink]="['/bookings/', booking.referralbookingid]" class="btn default-color buttons mt-auto mb-1 me-2" style="height:fit-content;">Details</a>
                                <button type="button" class="btn danger-color buttons" (click)="cancellbooking(booking.id)"
                                >Cancel Booking</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="completed" *ngIf="completed">
            <h2 *ngIf="completedBookings.length === 0" class="mt-3 text-center">No Completed Bookings...</h2>
            <div class="py-2" *ngIf="completedBookings.length > 0">
            <h5 class="title text-success ms-2">Completed Bookings</h5>
                <div class="" *ngFor="let booking of completedBookings">
                    <div class="px-3 py-2 rounded shadow  mt-3">
                        <h4 *ngIf="booking.category != 'bikerental' && booking.category != 'camping'" class="my-1 bookings">You Booked <b>{{booking.activitytypemastername}}</b> ({{booking.activityaddress}}) at
                            <b>{{booking.activitieslocationname}}</b> on <b>{{formatDate(booking.date)}}</b> at
                            <b>{{booking.time}}</b> for <b>{{booking.guest}} {{ booking.guest == 1 ? 'person' : 'persons' }}</b> provided by
                            <b>{{booking.merchantname}}</b></h4>
                        <h4  *ngIf="booking.category == 'bikerental'" class="my-1 bookings">You Booked <b>{{booking.activitytypemastername}}</b> {{(booking.category) != 'bikerental' ? '('+booking.activityaddress+')':''}} at
                            <b>{{booking.activitieslocationname}}</b> from <b>{{formatDate(booking.date) + ' ' +booking.time}}</b> to <b>{{formatDate(booking.todate) + ' ' +booking.totime}}</b>  provided by
                            <b>{{booking.merchantname}}</b></h4>
                        <h4  *ngIf="booking.category == 'camping'"  class="my-1 bookings">You Booked <b>{{booking.activitytypemastername}}</b> ({{booking.activityaddress}})  at
                            <b>{{booking.activitieslocationname}}</b> from <b>{{formatDate(booking.date)}}</b> at
                            <b>{{booking.time}}</b> to <b>{{formatDate(booking.todate) + ' ' +booking.time}}</b> for <b>{{booking.totalguest}} {{ booking.totalguest == 1 ? 'person' : 'persons' }}</b> provided by
                            <b>{{booking.merchantname}}</b></h4>
                        <div class="d-flex justify-content-between">
                            <h6 class="mb-1 date">{{ timestampsToDate(booking.createdon)}}</h6>
                            <div>
                                <a [routerLink]="['/bookings/', booking.referralbookingid]" class="btn default-color buttons mt-auto mb-1 me-2" style="height:fit-content;">Details</a>
                                 <button type="button" class="btn default-color mb-1 buttons" (click)="writeReview(booking.id, booking.merchantid, booking.activityid)">Write a
                                    Review</button>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
        <div class="cancelled" *ngIf="cancelled">
            <h2 *ngIf="cancelledBookings.length === 0" class="mt-3 text-center">No Cancelled Bookings...</h2>
            <div class="py-2" *ngIf="cancelledBookings.length > 0">
            <h5 class="title text-danger ms-2">Cancelled Bookings</h5>
                <div class="" *ngFor="let booking of cancelledBookings">
                    <div class="px-3 py-2 rounded shadow  mt-3">
                        <h4 *ngIf="booking.category != 'bikerental' && booking.category != 'camping'" class="my-1 bookings">You Booked <b>{{booking.activitytypemastername}}</b> ({{booking.activityaddress}}) at
                            <b>{{booking.activitieslocationname}}</b> on <b>{{formatDate(booking.date)}}</b> at
                            <b>{{booking.time}}</b> for <b>{{booking.guest}} {{ booking.guest == 1 ? 'person' : 'persons' }}</b> provided by
                            <b>{{booking.merchantname}}</b></h4>
                        <h4  *ngIf="booking.category == 'bikerental'" class="my-1 bookings">You Booked <b>{{booking.activitytypemastername}}</b> {{(booking.category) != 'bikerental' ? '('+booking.activityaddress+')':''}} at
                            <b>{{booking.activitieslocationname}}</b> from <b>{{formatDate(booking.date) + ' ' +booking.time}}</b> to <b>{{formatDate(booking.todate) + ' ' +booking.totime}}</b>  provided by
                            <b>{{booking.merchantname}}</b></h4>
                        <h4  *ngIf="booking.category == 'camping'"  class="my-1 bookings">You Booked <b>{{booking.activitytypemastername}}</b> ({{booking.activityaddress}})  at
                            <b>{{booking.activitieslocationname}}</b> from <b>{{formatDate(booking.date)}}</b> at
                            <b>{{booking.time}}</b> to <b>{{formatDate(booking.todate) + ' ' +booking.time}}</b> for <b>{{booking.totalguest}} {{ booking.totalguest == 1 ? 'person' : 'persons' }}</b> provided by
                            <b>{{booking.merchantname}}</b></h4>
                        <h6 class="text-end mb-1">{{ timestampsToDate(booking.createdon)}}</h6>
                        </div>
                </div>
            </div>
        </div>
        <div class="pendingbooking" *ngIf="pendingbooking">
            <h2 *ngIf="pendingBookings.length === 0" class="mt-3 text-center">No Pending Bookings...</h2>
            <div class="py-2" *ngIf="pendingBookings.length > 0">
            <h5 class="title text-danger ms-2">Pending Bookings</h5>
                <div class="" *ngFor="let booking of pendingBookings">
                    <div class="px-3 py-2 rounded shadow mt-3">
                        <h4 *ngIf="booking.category != 'bikerental' && booking.category != 'camping'" class="my-1 bookings">You Booked <b>{{booking.activitytypemastername}}</b> ({{booking.activityaddress}}) at
                            <b>{{booking.activitieslocationname}}</b> on <b>{{formatDate(booking.date)}}</b> at
                            <b>{{booking.time}}</b> for <b>{{booking.guest}} {{ booking.guest == 1 ? 'person' : 'persons' }}</b> provided by
                            <b>{{booking.merchantname}}</b></h4>
                        <h4  *ngIf="booking.category == 'bikerental'" class="my-1 bookings">You Booked <b>{{booking.activitytypemastername}}</b> {{(booking.category) != 'bikerental' ? '('+booking.activityaddress+')':''}} at
                            <b>{{booking.activitieslocationname}}</b> from <b>{{formatDate(booking.date) + ' ' +booking.time}}</b> to <b>{{formatDate(booking.todate) + ' ' +booking.totime}}</b>  provided by
                            <b>{{booking.merchantname}}</b></h4>
                        <h4  *ngIf="booking.category == 'camping'"  class="my-1 bookings">You Booked <b>{{booking.activitytypemastername}}</b> ({{booking.activityaddress}})  at
                            <b>{{booking.activitieslocationname}}</b> from <b>{{formatDate(booking.date)}}</b> at
                            <b>{{booking.time}}</b> to <b>{{formatDate(booking.todate) + ' ' +booking.time}}</b> for <b>{{booking.totalguest}} {{ booking.totalguest == 1 ? 'person' : 'persons' }}</b> provided by
                            <b>{{booking.merchantname}}</b></h4>
                        <div class="d-flex justify-content-between">
                            <h6 class="tmb-1 date">{{ timestampsToDate(booking.createdon)}}</h6>
                            <div>
                            <button type="button" class="btn default-color buttons" *ngIf="booking.paymentid" (click)="payNow(booking.paymentid, 'paymentid')">Pay Now</button>
                            <button type="button" class="btn default-color buttons" *ngIf="booking.referralbookingid && !booking.paymentid" (click)="payNow(booking.referralbookingid, 'referralbookingid')">Pay Now</button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  <app-footer></app-footer>
</section>