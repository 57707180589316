import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup,FormBuilder,AbstractControl, Validators } from '@angular/forms';
import { HttpClient ,HttpErrorResponse} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {catchError} from 'rxjs/operators';
import { ModalAlertComponent } from '../modal-alert/modal-alert.component';

@Component({
  selector: 'app-forget-password-popup',
  templateUrl: './forget-password-popup.component.html',
  styleUrls: ['./forget-password-popup.component.css']
})
export class ForgetPasswordPopupComponent {
  forgetpasswordForm!: FormGroup;
  isSubmitting: boolean = false;
  type:string ="";
  heading:string="";
  hostName:any="";
  userAgent:string ="";

  constructor (@Inject(MAT_DIALOG_DATA) public data:any,private http: HttpClient,public dialog: MatDialog, public forgetPasswordDialogRef: MatDialogRef<ForgetPasswordPopupComponent>, private formBuilder: FormBuilder){
    this.userAgent = window.navigator.userAgent;
    this.hostName = window.location.host;
    this.type = data.type;
    if(data.type === "user"){
      this.heading = "User Email address";
    }else if(data.type === "merchant"){
      this.heading = "Merchant Email address";
    }else if(data.type === "partner"){
      this.heading = "Partner Email address";
    }
  }

  ngOnInit(){
    this.forgetpasswordForm = this.formBuilder.group({ email: new FormControl('',[Validators.required, Validators.email])});
  }

  closeForgetPasswordDialog(){
    this.forgetPasswordDialogRef.close();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.forgetpasswordForm.controls;
  }

  ifMobileApp(){
    if(this.userAgent.includes('AdvenzoneMobileApp')){
      return true;
    }else{
      return false;
    }
  }

  onSubmit(){
    this.isSubmitting = true;
    if (this.forgetpasswordForm.invalid) {
      return;
    }
    if (this.forgetpasswordForm.valid) {
      let sizes = {
        height: 'fit-content',
        width: 'fit-content'
      };
      if(this.type === "user"){
        let addClientHost:any ={};
        if(!this.ifMobileApp()){
          addClientHost.clienthost = 'https://'+this.hostName+'/'
        }
      this.http.post(`${environment.apiUrl}/users/forgotpassword`,{email:this.forgetpasswordForm.value.email, ...addClientHost}).pipe(
        catchError((_err: HttpErrorResponse)=> {
          this.forgetPasswordDialogRef.close();
          this.dialog.open(ModalAlertComponent, {
            ...sizes,
            disableClose: true,
            data: {
              title:'Some Error occurs',
              message: JSON.stringify(_err.error.errors),
              status:'danger'
            },
          })
          throw new Error("Error");
        })
      ).subscribe((data:any)=>{
        if(data.status === "SUCCESS"){
          this.forgetPasswordDialogRef.close();
          this.dialog.open(ModalAlertComponent, {
            ...sizes,
            disableClose: true,
            data: {
              title:'Forget Password',
              message: `${data.message}`,
              status:'success'
            },
          });
        }
      });
    }else if(this.type === "merchant"){
      let addClientHost:any ={};
      if(!this.ifMobileApp()){
        addClientHost.clienthost = 'https://'+this.hostName+'/'
      }
      this.http.post(`${environment.apiUrl}/merchants/forgotpassword`,{email:this.forgetpasswordForm.value.email, ...addClientHost}).pipe(
        catchError((_err: HttpErrorResponse)=> {
          this.forgetPasswordDialogRef.close();
          this.dialog.open(ModalAlertComponent, {
            ...sizes,
            disableClose: true,
            data: {
              title:'Some Error occurs',
              message: JSON.stringify(_err.error.errors),
              status:'danger'
            },
          })
          throw new Error("Error");
        })
      ).subscribe((data:any)=>{
        if(data.status === "SUCCESS"){
          this.forgetPasswordDialogRef.close();
          this.dialog.open(ModalAlertComponent, {
            ...sizes,
            disableClose: true,
            data: {
              title:'Forget Password',
              message: `${data.message}`,
              status:'success'
            },
          });
        }
      });
    }else if(this.type === "partner"){
      let addClientHost:any ={};
      if(!this.ifMobileApp()){
        addClientHost.clienthost = 'https://'+this.hostName+'/'
      }
      this.http.post(`${environment.apiUrl}/partners/forgotpassword`,{email:this.forgetpasswordForm.value.email, ...addClientHost}).pipe(
        catchError((_err: HttpErrorResponse)=> {
          this.forgetPasswordDialogRef.close();
          this.dialog.open(ModalAlertComponent, {
            ...sizes,
            disableClose: true,
            data: {
              title:'Some Error occurs',
              message: JSON.stringify(_err.error.errors),
              status:'danger'
            },
          })
          throw new Error("Error");
        })
      ).subscribe((data:any)=>{
        if(data.status === "SUCCESS"){
          this.forgetPasswordDialogRef.close();
          this.dialog.open(ModalAlertComponent, {
            ...sizes,
            disableClose: true,
            data: {
              title:'Forget Password',
              message: `${data.message}`,
              status:'success'
            },
          });
        }
      });
    }
  }
}

}

