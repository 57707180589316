import { Component,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import {  Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-write-review',
  templateUrl: './write-review.component.html',
  styleUrls: ['./write-review.component.css']
})
export class WriteReviewComponent {

  starRatingThrill:number = 0;
  starRatingMerchant:number = 0;
  textAdventureReview:string="";
  textMerchantReview:string="";
  userId:string="";
  bookingId:string="";
  profileSrc:any="";
  name:string="";
  activityId:string= "";
  merchantId:string= "";
  reviewAdventureId: string="";
  reviewMerchantId: string="";
  reviewAdventureUpdate:boolean= false;
  reviewMerchantUpdate:boolean= false;
  merchantReplyData:any;
  isReviewProcessing:boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,public dialog: MatDialog, private router: Router,  private http: HttpClient, public writeReviewRef: MatDialogRef<WriteReviewComponent> , private auth: AuthService, private snackBar: MatSnackBar ) {
    this.merchantId = data.merchantid;
    this.activityId = data.activityid;
    this.bookingId = data.bookingid;
    this.auth.userName.subscribe(name=>{
      this.name=name;
   })
   this.auth.userInfo.subscribe(info=>{
    if(info !== null){
        this.userId=info.id;
        this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=userprofile&dataforid=${this.userId}`).subscribe((data: any) => {
          if (data.status === "SUCCESS" && data.uploads.length > 0) {
            let uploadid = data.uploads[0].id;
            this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${uploadid}`).subscribe((value: any) => {
              if(value.status === "SUCCESS"){
                this.profileSrc = value.upload.thumbnail;
              }
            })
          }
        });
    }
  })
  }

  ngOnInit(){
    this.http.get(environment.apiUrl+`/reviews/userReview?createdBy=${this.userId}&dataFor=activity&dataForId=${this.activityId}&bookingId=${this.bookingId}`).subscribe((data:any)=>{
      if(data.status === "SUCCESS" && data.reviews){
        this.starRatingThrill = data.reviews.star;
        this.textAdventureReview = data.reviews.review;
        this.reviewAdventureUpdate = true;
        this.reviewAdventureId = data.reviews.id;
      }
    })

    this.http.get(environment.apiUrl+`/reviews/userReview?createdBy=${this.userId}&dataFor=merchant&dataForId=${this.merchantId}&bookingId=${this.bookingId}`).subscribe((data:any)=>{
      if(data.status === "SUCCESS" && data.reviews){
        this.starRatingMerchant = data.reviews.star;
        this.textMerchantReview = data.reviews.review;
        this.reviewMerchantUpdate = true;
        this.reviewMerchantId = data.reviews.id;
        if(data.reviews.id){
          this.http.post(environment.apiUrl+`/reviews/bymerchant`,{reviewForId: data.reviews.id, dataFor: "merchant"}).subscribe((data:any)=>{
            if(data.reviews.length > 0){
              this.merchantReplyData = data.reviews[0];
              this.merchantReplyData.src="";
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantprofile&dataforid=${this.merchantReplyData.createdby}`).subscribe((data: any) => {
                if (data.status === "SUCCESS" && data.uploads.length > 0) {
                  let uploadid = data.uploads[0].id;
                  this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${uploadid}`).subscribe((value: any) => {
                    if(value.status === "SUCCESS"){
                      this.merchantReplyData.src = value.upload.thumbnail;
                    }
                  })
                }
              });
            }
          })
        }
      }
    })
  }

  closeReviewDialogue(){
    this.writeReviewRef.close();
  }

  submitReview(type:string){
    if(type === "activity"){
      if(this.starRatingThrill === 0){
        this.snackBar.open('Please select the thrill rating.', 'close', { duration: 3000, verticalPosition: 'top' },);
      }else if(this.textAdventureReview === ""){
        this.snackBar.open('Review should not be empty.', 'close', { duration: 3000, verticalPosition: 'top' },);
      }else{
        if(this.reviewAdventureUpdate){
          let data={id: this.reviewAdventureId,updatedBy:this.userId, star:this.starRatingThrill.toString(), review:this.textAdventureReview };
          try{
            this.http.post(environment.apiUrl + `/reviews/update`, data, { responseType: 'json' }).subscribe(async(data: any) => {
              if(data.status === "SUCCESS"){
               this.snackBar.open('Review Updated successfully.', 'close', { duration: 3000, verticalPosition: 'top' },);
              }else{
               this.snackBar.open('Some error occured while adding review.', 'close', { duration: 3000, verticalPosition: 'top' },);
              }
            });
          }catch(err:any){
            console.log(err.message)
          }
        }else{
          let data={createdBy:this.userId, dataForId:this.activityId,dataFor:'activity', star:this.starRatingThrill.toString(),bookingId:this.bookingId, review:this.textAdventureReview };
          if(!this.isReviewProcessing){
            this.isReviewProcessing = true;
            this.http.post(environment.apiUrl + `/reviews/create`, data, { responseType: 'json' }).subscribe(async(data: any) => {
              if(data.status === "SUCCESS"){
                this.reviewAdventureId = data.reviews.id;
                this.reviewAdventureUpdate = true;
              this.snackBar.open('Review added successfully.', 'close', { duration: 3000, verticalPosition: 'top' },);
              }else{
              this.snackBar.open('Some error occured while adding review.', 'close', { duration: 3000, verticalPosition: 'top' },);
              }
              this.isReviewProcessing = false;
            });
         }
        }
      }
    }
    if(type === "merchant"){
      if(this.starRatingMerchant === 0){
        this.snackBar.open('Please Select the Rating for Experience with Our Guide.', 'close', { duration: 3000, verticalPosition: 'top' },);
      }else if(this.textMerchantReview === ""){
        this.snackBar.open('Review should not be empty.', 'close', { duration: 3000, verticalPosition: 'top' },);
      }else{
        if(this.reviewMerchantUpdate){
          let data={id: this.reviewMerchantId,updatedBy:this.userId, star:this.starRatingMerchant.toString(), review:this.textMerchantReview };
          try{
            this.http.post(environment.apiUrl + `/reviews/update`, data, { responseType: 'json' }).subscribe(async(data: any) => {
              if(data.status === "SUCCESS"){
               this.snackBar.open('Review Updated successfully.', 'close', { duration: 3000, verticalPosition: 'top' },);
              }else{
               this.snackBar.open('Some error occured while adding review.', 'close', { duration: 3000, verticalPosition: 'top' },);
              }
            });
          }catch(err:any){
            console.log(err.message)
          }
        }else{
          let data={createdBy:this.userId, dataForId:this.merchantId,dataFor:'merchant', star:this.starRatingMerchant.toString(),bookingId:this.bookingId, review:this.textMerchantReview };
          if(!this.isReviewProcessing){
          this.isReviewProcessing = true;
            this.http.post(environment.apiUrl + `/reviews/create`, data, { responseType: 'json' }).subscribe(async(data: any) => {
              if(data.status === "SUCCESS"){
                this.reviewMerchantId = data.reviews.id;
                this.reviewMerchantUpdate = true;
              this.snackBar.open('Review added successfully.', 'close', { duration: 3000, verticalPosition: 'top' },);
              }else{
              this.snackBar.open('Some error occured while adding review.', 'close', { duration: 3000, verticalPosition: 'top' },);
              }
              this.isReviewProcessing = false;
            });
          }  
        }
      }
    }
  }
}
