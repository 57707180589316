import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LoginDialogComponent } from './auth/login-dialog/login-dialog.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ProfileManagementComponent } from './profile-management/profile-management.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { VerificationCodeComponent } from './verification-code/verification-code.component';
import { MatCardModule } from '@angular/material/card';
import { CloseNavbarDirective } from './directives/navbar-close.directive';
import { CarouselModule } from "./carousel/carousel.module";
import { ProfileBookingsComponent } from './profile-bookings/profile-bookings.component';
import { ModalAlertComponent } from './modal-alert/modal-alert.component';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MerchantLoginDialogueComponent } from './auth/merchant-login-dialogue/merchant-login-dialogue.component';
import { MerchantSignupComponent } from './auth/merchant-signup/merchant-signup.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgImageSliderModule } from 'ng-image-slider';
import { WriteReviewComponent } from './write-review/write-review.component';
import { DatePipe } from '@angular/common';
import { PopupProvidedbyComponent } from './popups/popup-providedby/popup-providedby.component';
import { ForgetPasswordPopupComponent } from './forget-password-popup/forget-password-popup.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { GoogleLoginProvider } from "@abacritt/angularx-social-login";
import { environment } from 'src/environments/environment';
import { ContactUsFormComponent } from './contact-us-form/contact-us-form.component';
import { QRCodeModule } from 'angularx-qrcode';
import { DownloadQrcodeComponent } from './download-qrcode/download-qrcode.component';
import { MerchantAgreementComponent } from './merchant-agreement/merchant-agreement.component';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { MatGridListModule } from '@angular/material/grid-list';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import { NewProvidedByComponent } from './new-provided-by/new-provided-by.component';
import { PopupBookingDetailsComponent } from './popups/popup-booking-details/popup-booking-details.component';
import { MerchantProvidedByComponent } from './merchant-provided-by/merchant-provided-by.component';
import { AgentProvidedByComponent } from './agent-provided-by/agent-provided-by.component';
import { PartnerLoginDialogueComponent } from './auth/partner-login-dialogue/partner-login-dialogue.component';
import { GuestLoginDialogueComponent } from './auth/guest-login-dialogue/guest-login-dialogue.component';
import { FetchLocationComponent } from './location/fetch-location/fetch-location.component';
import { PopupPaymentDetailsComponent } from './payment/popup-payment-details/popup-payment-details.component';
import { InfoComponent } from './info/info.component';
import { EnquiryFormComponent } from './enquiry-form/enquiry-form.component';
import { SurveyPopupComponent } from './survey-popup/survey-popup.component';
import { SharedModule } from './shared/shared.module';
import { FooterModule } from './shared/footer/footer.module';
import { ItineraryPopupComponent } from './itinerary-popup/itinerary-popup.component';
import { SvgIconModule } from './svg-icon/svg-icon.module';
import { CouponsPopupComponent } from './coupons-popup/coupons-popup.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MobileDiscountPopupComponent } from './popups/mobile-discount-popup/mobile-discount-popup.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    LoginDialogComponent,
    SignUpComponent,
    ProfileManagementComponent,
    ProfileEditComponent,
    VerificationCodeComponent,
    CloseNavbarDirective,
    ProfileBookingsComponent,
    ModalAlertComponent,
    MerchantLoginDialogueComponent,
    MerchantSignupComponent,
    WriteReviewComponent,
    PopupProvidedbyComponent,
    ForgetPasswordPopupComponent,
    NewPasswordComponent,
    ContactUsFormComponent,
    DownloadQrcodeComponent,
    MerchantAgreementComponent,
    NewProvidedByComponent,
    PopupBookingDetailsComponent,
    MerchantProvidedByComponent,
    AgentProvidedByComponent,
    PartnerLoginDialogueComponent,
    GuestLoginDialogueComponent,
    FetchLocationComponent,
    PopupPaymentDetailsComponent,
    InfoComponent,
    SurveyPopupComponent,
    EnquiryFormComponent,
    ItineraryPopupComponent,
    CouponsPopupComponent,
    LandingPageComponent,
    MobileDiscountPopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatDialogModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    MatIconModule,
    HttpClientModule,
    MatSnackBarModule,
    MatCardModule,
    CarouselModule,
    MatTooltipModule,
    NgImageSliderModule,
    QRCodeModule,
    MatGridListModule,
    MatNativeDateModule,
    MatRippleModule,
    SharedModule,
    FooterModule,
    SvgIconModule,
    NgxIntlTelInputModule,
    MatBottomSheetModule
  ],
  providers: [MdbModalService,
    File,
    FileTransfer,
    DatePipe, {

      provide: 'SocialAuthServiceConfig',

      useValue: {

        autoLogin: false,

        providers: [{

          id: GoogleLoginProvider.PROVIDER_ID,

          provider: new GoogleLoginProvider(environment.GOOGLE_CLIENT_ID)

        }]

      }

    }],
  bootstrap: [AppComponent],
})
export class AppModule { }
