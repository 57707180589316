import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { EnquiryFormComponent } from 'src/app/enquiry-form/enquiry-form.component';
import { InfoComponent } from 'src/app/info/info.component';


interface CampingData {
  activitiesaddressline1: any;
  activitytypemastername: any;
  min_price: any;
  id: any;
  src: any;
  description: any;
  campingName: any;
  merchantname: any;
  distanceAway: any;
  campingLongitude: any;
  campingLatitude: any;
  merchantid: any;
  activitieslocationid: any;
  avgReview: any;
  hasCoupon: any;
  activitiescategory: any;
  activitieslocationname: any;
  total_star:any;
  rating:any;
}
@Component({
  selector: 'app-activity-camping-card',
  templateUrl: './activity-camping-card.component.html',
  styleUrls: ['./activity-camping-card.component.css']
})
export class ActivityCampingCardComponent {
  @Input() data: CampingData;
  @Input()  exploreData :any;

  constructor(private router: Router, public dialog: MatDialog, public dataService: DataService) {
  }

  ngOnInit() {
  console.log(this.data, this.exploreData)
  }
  openInfoDialog(locationid: any, event: any, description: any, id: string, datafrom: string) {
    event.stopPropagation();
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '500px',
      width: '50%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      }
    }
    this.dialog.open(InfoComponent, {
      ...sizes,
      disableClose: true,
      data: {
        locationid, id, description, datafrom
      },
    });
  }

  openEnquireDialog(addressline: any, merchantid:any) {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '87%',
      width: '33%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      };
    }

    this.dialog.open(EnquiryFormComponent, {
      ...sizes,
      disableClose: true,
      data: {
        datafrom: addressline,
        activityType: "0",
        merchantId:merchantid
      },
    });

  }

  openMerchantPage(locationid: any, id: any,city:any,name:any) {
    // this.router.navigate(['/merchant-profile', 'location', locationid, 'merchantid', id])
    city = city.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    name = name.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/merchant-profile','location',locationid,'merchantid',id,city,name])
  }

  openMap(event: any, latitude: any, longitude: any) {
    event.stopPropagation()
    this.dataService.openMap(latitude, longitude);
  }

  openCamping(activitieslocationid: any, id: any, e: any, activitieslocationname: any, campingName: any) {
    if (e.target instanceof HTMLElement && (e.target.classList.contains('carousel-control-next-icon') || e.target.classList.contains('carousel-control-prev-icon'))) {
      e.stopPropagation();
      return;
    }
    campingName = campingName.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    activitieslocationname = activitieslocationname.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/location', activitieslocationid, 'camping-details', id, activitieslocationname, campingName])
  }

  openData(ID: number, locationId: any, datafrom: any, e: any,locationname: any, activitiesaddressline1: any, campingName: any,merchantid:any) {
    console.log(ID,locationId,datafrom,locationname,activitiesaddressline1,campingName,merchantid);
    if (e.target instanceof HTMLElement && (e.target.classList.contains('carousel-control-next-icon') || e.target.classList.contains('carousel-control-prev-icon'))) {
      e.stopPropagation();
      return;
    }
    if (datafrom === 'camping') {
      this.openCamping(locationId, ID,e, locationname, campingName)
    }
  
  }
  get fireRating() {
    return this.data.avgReview ?? this.data.total_star;
  }
  get likeRating() {
    return this.data.avgReview ?? this.data.rating;
  }
}
