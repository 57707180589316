import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ContactUsFormComponent } from '../contact-us-form/contact-us-form.component';

@Component({
  selector: 'app-itinerary-popup',
  templateUrl: './itinerary-popup.component.html',
  styleUrls: ['./itinerary-popup.component.css']
})
export class ItineraryPopupComponent {
  isSubmitting: boolean = false;
  contactUsForm!: FormGroup;
  userAgent: string = "";
  url: SafeResourceUrl | undefined;
  imageUrl: string | undefined;

  constructor(public dialog: MatDialog, private contactUsDialogRef: MatDialogRef<ContactUsFormComponent>, @Inject(MAT_DIALOG_DATA) data: { url:string,imageUrl:string},private sanitizer: DomSanitizer) {
    this.userAgent = window.navigator.userAgent;


   if (data?.url) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
  }

  if (data?.imageUrl) {
    this.imageUrl = data.imageUrl;
  }

 
   }
  closeContactUsDialog(){
    this.contactUsDialogRef.close();
  }
}
