<section class="mt-4">
  <div class="card custom-card mx-auto border-0">
    <h1 class="title text-center mb-0">Profile</h1>
    <img src="assets/default-profile-pic.svg" *ngIf="!profileSrc" [alt]="firstname" class="profile-img mx-auto">
    <img [src]="profileSrc" *ngIf="profileSrc" [alt]="firstname" class="profile-img mx-auto">
    <label for="uploadImage" class="uploadImage"> <img
        src="/assets/upload-img.svg" class="upload-img" alt="upload">Change Profile Image</label>
    <input type="file" id="uploadImage" class="d-none" accept="image/*" (change)="handleProfileChange($event)">
    <form class="form-inline px-3">
      <div class="row mb-0">
        <div class="col-6">
          <div class="form-outline ">
            <label for="">First Name</label>
            <input type="text" class="form-control" [(ngModel)]="firstname" [ngModelOptions]="{standalone: true}"
              #nameInput>
          </div>
        </div>
        <div class="col-6">
          <div class="form-outline ">
            <label for="">Last Name</label>
            <input type="text" class="form-control" [(ngModel)]="lastname" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
      <div class="form-outline mt-2 ">
        <label for="">Email</label>
        <input type="email" class="form-control" [(ngModel)]="email" [ngModelOptions]="{standalone: true}"
          disabled="true">
      </div>

      <div class="form-outline mt-3 mb-4 ">
        <label for="">Phone number</label>
        <input type="text" class="form-control" placeholder="Phone number" [(ngModel)]="phone"
          [ngModelOptions]="{standalone: true}" disabled="true">
      </div>
      <div class="text-center">
        <button class="btn search-btn fw-bold px-md-5 border-0" (click)="onSubmit()">Update</button>
      </div>
    </form>
  </div>
  <app-footer></app-footer>
</section>