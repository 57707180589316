<button type="button" class="btn-close mt-2 ms-2" (click)="closeReviewDialogue()" aria-label="Close"></button>
<!-- <h1 mat-dialog-title class="text-center">Review</h1> -->
<div mat-dialog-content class="overflow-auto">
    <div class="mt-3">
        <h1 class="title">Rate this Activity</h1>
        <div class="d-flex mt-2 align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <img class="rounded-circle profile-img" *ngIf="!profileSrc" src="assets/default-profile-pic.svg" alt="profile">
                <img class="rounded-circle profile-img" *ngIf="profileSrc" [src]="profileSrc" alt="profile">
                <h6 class="name ms-2 mb-0 text-capitalize">{{name}}</h6>
            </div>
            <div>
                <ng-template #t let-fill="fill">
                    <i class="bi bi-fire">
                        <i *ngIf="fill > 0" class="bi bi-fire filled" [style.width.%]="fill"></i>
                    </i>    
                </ng-template>
                <ngb-rating [max]=5   [starTemplate]="t" [(rate)]="starRatingThrill"></ngb-rating>
            </div>
        </div>
        <div class="ratings-div mx-md-4 mx-2 mt-2">
            <textarea [(ngModel)]="textAdventureReview" rows="3" class="text-muted" placeholder="Tell as About the Adventure"></textarea>
            <button type="submit" class="btn btn-primary save-btn" (click)="submitReview('activity')"> Post </button>
        </div>
    </div>
    <div class="mt-3">
        <h1 class="title">Rate the Merchant</h1>
        <div class="d-flex mt-2 align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <img class="rounded-circle profile-img" *ngIf="!profileSrc" src="assets/default-profile-pic.svg" alt="profile">
                <img class="rounded-circle profile-img" *ngIf="profileSrc" [src]="profileSrc" alt="profile">
                <h6 class="name ms-2 mb-0 text-capitalize">{{name}}</h6>
            </div>
            <div>
                <ngb-rating [max]=5 [(rate)]="starRatingMerchant"></ngb-rating>
            </div>
        </div>
        <div class="ratings-div mx-md-4 mx-2 mt-2">
            <textarea [(ngModel)]="textMerchantReview" rows="3" class="text-muted" placeholder="Write a Review"></textarea>
            <button type="submit" class="btn btn-primary save-btn" (click)="submitReview('merchant')"> Post </button>
        </div>
        <div *ngIf="merchantReplyData" class="mt-2">
            <h1 class="title">Reply by Merchant</h1>
            <div class="d-flex align-items-center">
                <img class="rounded-circle profile-img" *ngIf="!merchantReplyData.src" src="assets/default-profile-pic.svg" alt="profile">
                <img class="rounded-circle profile-img" *ngIf="merchantReplyData.src" [src]="merchantReplyData.src" alt="profile">
                <h6 class="name ms-2 mb-0 text-capitalize">{{merchantReplyData.firstname}}</h6>
            </div>
            <div class="mx-4 mt-2" >
                <h6 style="padding-left:16px; font-size: 15px;">{{merchantReplyData.review}}</h6>
            </div>
      </div>
    </div>
</div>