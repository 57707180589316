
<div mat-dialog-content class="overflow-auto">
    <button type="button" class="btn-close mt-2 ms-2 p-4 pt-2" (click)="closeContactUsDialog()" aria-label="Close"></button>

    <div *ngIf="url">
        <iframe width="580" height="395" class="p-3"  [src]="url" >
        </iframe>
    </div>
    <div *ngIf="imagesrc ">
        <img  [src]="imagesrc" alt="Image" width="580" height="395" class="img-fluid" class="p-3"  >
    </div>
    <!-- <div class="d-flex align-items-center pb-md-3 pb-2"> 580 455-->  <!-- </div> -->
      <!-- <button type="button" class="btn-close mt-2 ms-2" (click)="closeContactUsDialog()" aria-label="Close"></button>
      <h1 mat-dialog-title  class="text-center mb-0 contact-us pb-0" >Contact Us Form</h1>
  
    <form (submit)="onFormSubmit()" class="mt-2" [formGroup]="contactUsForm">
        <div class="form-outline ">
            <input type="text" id="form3Example1" class="form-control inputbox" formControlName="name"
                placeholder="Name" [ngClass]="{ 'is-invalid': isSubmitting && f['name'].errors }" />
            <div *ngIf="isSubmitting && f['name'].errors" class="invalid-feedback">
                <div *ngIf="f['name'].errors['required']">
                    Name is required
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-outline mt-3 col-6">
                <input type="email" id="form3Example3" class="form-control inputbox" formControlName="email"
                    placeholder="Email address" [ngClass]="{ 'is-invalid': isSubmitting && f['email'].errors }" />
                <div *ngIf="isSubmitting && f['email'].errors" class="invalid-feedback">
                    <div *ngIf="f['email'].errors['required']">Email is required</div>
                    <div *ngIf="f['email'].errors['email']">Email is invalid</div>
                </div>

            </div>
            <!-- phone number -->
            <!-- <div class="form-outline mt-3 col-6">
                <input type="text" id="form3Example4" class="form-control inputbox" formControlName="phone"
                    [ngClass]="{ 'is-invalid': isSubmitting && f['phone'].errors }" placeholder="Phone Number"
                    maxlength="10" oninput="this.value = this.value.replace(/[^0-9 ]/,'')" />
                <div *ngIf="isSubmitting && f['phone'].errors" class="invalid-feedback">
                    <div *ngIf="f['phone'].errors['required']">Phone Number is required</div>
                    <div *ngIf="f['phone'].errors['invalidPhoneNumber']">Invalid Phone number.</div>
                </div>
            </div>
        </div>
        <div class="form-outline mt-3">
            <textarea name="description" id="description" class="form-control"
                [ngClass]="{ 'is-invalid': isSubmitting && f['message'].errors }" formControlName="message"
                placeholder="Enter your feedback" rows="4" style="resize: none"></textarea>
            <div *ngIf="isSubmitting && f['message'].errors" class="invalid-feedback">
                <div *ngIf="f['message'].errors['required']">
                    Feedback is required
                </div>
            </div>
        </div>
        <div class=" text-center mt-2 ">
            <button type="submit" class="btn create-btn btn-block mt-2 text-light text-center">Submit
                </button>
        </div>
        <div class="text-center mt-2">
            <a href="javascript:void(0)" (click)="callSupport()" class="btn call-btn"><img src="/assets/phone.svg" alt="call"> Call Support</a>
        </div>

    </form> --> 
</div>