import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FetchLocationComponent } from '../location/fetch-location/fetch-location.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet'; 
import { MobileDiscountPopupComponent } from '../popups/mobile-discount-popup/mobile-discount-popup.component';
import { DataService } from '../data.service';
import { CouponService } from '../coupon.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements AfterViewInit {
  cursorDown = { x: 0, y: 0 };
  cursorUp = { x: 0, y: 0 };
  mouseDown = false;
  startX: any;
  startY: any;
  scrollLeft: any;
  visibilityStates: boolean[] = [];
  showMoreReview:boolean = false;
  reviewLoading:boolean = false;
  reviews:any =[];
  reviewPage:number=1;
  userAgent:any;
  coupons:any=[];

 constructor( public dialog: MatDialog,private http: HttpClient,private router: Router, private couponService: CouponService, private bottomSheet: MatBottomSheet, private dataservice:DataService){
      this.visibilityStates = Array(7).fill(false); 
      this.userAgent = window.navigator.userAgent;
 }
 ngOnInit() {
  this.reviews =[];
  this.reviewPage =1;
  this.loadReviews();
  let isMobileDiscountPopupShowed :any;
  this.dataservice.isMobileDiscountPopupShowed.subscribe(currentValue => {
    isMobileDiscountPopupShowed = currentValue;
  });
  if(!isMobileDiscountPopupShowed){
  if(this.dataservice.ifMobileApp()){
        this.loadCoupon();
    }
  }
 }

 ngAfterViewInit(): void{
  window.scroll(0,0)
 }

  startDragging(e: any, flag: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, type: string, child: any, spot: string) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.cursorDown.x = e.pageX;
    this.cursorDown.y = e.pageY;
    if (type === 'mouse') {
      this.startX = e.pageX - el.offsetLeft;
    } else {
      // this.startX = e.touches[0].pageX - el.offsetLeft;
      // this.startY = e.touches[0].pageY - el.offsetTop;
    }



    this.scrollLeft = el.scrollLeft;
    // setTimeout(() => { this.isDragging = true }, 300);
  }

  stopDragging(e: any, flag: any, type: string) {
    this.cursorUp.x = e.pageX;
    this.cursorUp.y = e.pageY;
    // setTimeout(() => { this.isDragging = false });
    this.mouseDown = false;
  }

  moveEvent(e: any, el: { offsetLeft: number; scrollLeft: number; offsetTop: number }, type: string, child: any) {
    // e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    let x = 0, y = 0;
    if (type === 'mouse') {
      x = e.pageX - el.offsetLeft;
    } else {
      // x = e.touches[0].pageX - el.offsetLeft;
      // y = e.touches[0].pageY - el.offsetTop;
    }
    const scrollX = x - this.startX;
    const scrollY = y - this.startY;
    if (Math.abs(scrollY) > Math.abs(scrollX)) {
      document.body.scrollTo(window.scrollX, window.scrollY - scrollY);
    } else {
      el.scrollLeft = this.scrollLeft - scrollX;
    }
  }


  onScroll(e: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, child: any) {
    if ((child.clientWidth - el.scrollLeft) <= el.clientWidth + 1) {
      if (this.showMoreReview && !this.reviewLoading) {
        this.reviewLoading = true;
        this.loadReviews();
      }
    }
  }
 
  loadReviews(){
    this.reviewLoading = true;
    try{
      this.http.post(environment.apiUrl + '/reviews/list',{page:this.reviewPage,count:5,sortColumn: 'COALESCE(CAST(reviews.star AS INTEGER), -1)'}, { responseType: 'json' }).subscribe((data: any) => {
        if (data.status === "SUCCESS" ) {
          this.reviewLoading = false;
          if(data.reviews.length > 0){
            data.reviews.forEach((x:any)=>{
              let userId =x.createdby;
              x.src="";
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=userprofile&dataforid=${userId}`).subscribe((data: any) => {
                if (data.status === "SUCCESS" && data.uploads.length > 0) {
                  let uploadid = data.uploads[0].id;
                  this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${uploadid}`).subscribe((value: any) => {
                    if (value.status === "SUCCESS") {
                      x.src = value.upload.thumbnail;
                    }
                  })
                }
              });
            })
            this.reviews.push(...data.reviews);
            this.reviewLoading = false;
            if(this.reviews.length < Number(data.total)){
              this.showMoreReview = true;
              this.reviewPage += 1;
            }else{
              this.showMoreReview = false;
            }
          }else{
            this.reviewLoading = false;
            this.showMoreReview = false
          }
          }else{
            this.reviewLoading = false;
          }
          })
    }catch(error){
      this.reviewLoading = false;
    }
  }

  openLocation(event?: Event){
    if (event) {
      event.stopPropagation();
    }
    // if(localStorage.getItem('location') != null && localStorage.getItem('location') != undefined){
    //   this.router.navigate(['/home']);
    //   return
    // }
 
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: 'fit-content',
      width: 'fit-content',
      maxWidth: 'unset',
      position: { top: '15%' }
    };
    if (isMobile) {
      sizes = {
        height: 'fit-content',
        width: '90%',
        maxWidth: 'unset',
        position: { top: '15%' }
      }
    }
    this.dialog.open(FetchLocationComponent, {
      ...sizes,
      disableClose: true,
      data: {
        displayForm: '',
      },
    });
  }

  toggleContent(index: number) {
    // Set all other questions to false and toggle only the clicked one
    this.visibilityStates = this.visibilityStates.map((state, i) => i === index ? !state : false);
  }

  async loadCoupon() {
    try {
      let data: any = await this.couponService.getCoupon("");
      if (data.status === "SUCCESS") {
        if (data.coupons.length > 0) {
          this.coupons = data.coupons;
          let findCouponIndex = this.coupons.findIndex((x:any)=> x.validforapplication == 'mobileapp');
          if(findCouponIndex != -1){
            const bottomSheetRef =  this.bottomSheet.open(MobileDiscountPopupComponent, {
              data: this.coupons[findCouponIndex],
            });
            bottomSheetRef.afterDismissed().subscribe(() => {
            this.dataservice.setIsMobileDiscountPopupShowed(true);
            });
          }
        } 
      }
    } catch (error) {
      console.error('An error occurred while fetching coupons:', error);
    }
  }
}
