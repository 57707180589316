import {  Component ,Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent {
  id='';
  description = '';
  datafrom= '';
  locationid = '';
  locationname = '';
  activitiesaddressline1 = '';

  constructor(public dialog: MatDialog, private modalInfoRef: MatDialogRef<InfoComponent>,  private router: Router, @Inject(MAT_DIALOG_DATA) data: {locationid:string, id:string, description: string, datafrom:string ,locationname:string,activitiesaddressline1:string}) {
    this.locationid = data ? data.locationid : '';
    this.id = data ? data.id : '';
    this.description = data ? data.description : '';
    this.datafrom = data ? data.datafrom : '';
  }
  
  closeInfo(){
    this.modalInfoRef.close();
  }

  navigatePage(){
    this.modalInfoRef.close();
    if(this.datafrom == 'rafting'){
      this.router.navigate(['/location',this.locationid,'activity-details',this.id,this.locationname, this.activitiesaddressline1])
    }else if(this.datafrom == 'bikerental'){
      this.router.navigate(['/merchant-profile','location',this.locationid,'merchantid',this.id])
    }else if(this.datafrom == 'camping'){
      this.router.navigate(['/location',this.locationid,'camping-details',this.id,this.locationname, this.activitiesaddressline1])
    }
    else if(this.datafrom == 'trekking'){
      this.router.navigate(['/location',this.locationid,'trekking-detail',this.id,this.locationname, this.activitiesaddressline1])
    }
    else if(this.datafrom == 'tour'){
      this.router.navigate(['/location',this.locationid,'package-tour-detail',this.id,this.locationname, this.activitiesaddressline1])
    }
    else if(this.datafrom == 'activity'){
      this.router.navigate(['/location',this.locationid,'activity-details',this.id,this.locationname, this.activitiesaddressline1])

    }
  }
}
