<div class="mobile-coupon-container overflow-hidden">
    <img src="/assets/close-btn.svg" class="mx-auto d-block my-2 pointer" alt="close" (click)="close()" />
    <div class="coupon-content text-center py-3 position-relative">
        <div class="rounded-circle position-absolute"
            style="width: 80px; height: 80px; left:-40px; background: #00B1BC;"></div>
        <div class="rounded-circle position-absolute"
            style="width: 80px; height: 80px; border: 20px solid #0EBA92; bottom: -40px; left: -40px;"></div>
        <div class="rounded-circle position-absolute"
            style="width: 80px; height: 80px; border: 20px solid #0EBA92; top: 50%; right: -40px;"></div>
        <h6 class="coupon-desc-content">Welcome Discount</h6>
        <h3 class="coupon-off mb-1">{{data.discountpercentage}}% Off</h3>
        <h3 class="coupon-off-content">for APP First Booking Discount</h3>
        <img src="/assets/mobile-discount-coupon.png" width="50%" alt="coupon" />
        <!-- <h6 class="coupon-desc-content my-3">Max. discount INR 250. Applicable only for Indian user and first booking
            via the app. one-time use only</h6> -->
        <div class="pt-3 mx-3 coupon-desc-content my-3">
            <div class="d-flex align-items-baseline justify-content-center">
                <h3 class="offer-details mb-0 me-md-3 "
                    *ngIf="data.validforusertype !== undefined && data.validforusertype!== ''">*{{
                    (data.validforusertype === 'register' ? 'registered' : data.validforusertype) | titlecase }}
                    users are eligible </h3>
                <h3 class="offer-details mb-0 " *ngIf="data.validto !== undefined && data.validto!== ''">*Offer valid till {{this.validto}} </h3>
            </div>
            <div class="d-flex align-items-baseline justify-content-center">
                <h3 class="offer-details mb-0 me-md-3" *ngIf="data.maxdiscount !== undefined && data.maxdiscount!== ''">
                    *Maximum discount upto Rs. {{data.maxdiscount}}
                </h3>
                <h3 class="offer-details mb-0 " *ngIf="data.validforfirst !== undefined && data.validforfirst!== ''"> {{
                    getValidForFirstMessage(data.validforfirst) }}</h3>
            </div>
            <h3 class="offer-details mb-0" *ngIf="data.validforminamount !== undefined && data.validforminamount!== ''">
                *Valid on the net transaction amount of Rs. {{data.validforminamount}} </h3>
        </div>
    </div>
</div>