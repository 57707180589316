import { Component } from '@angular/core';

@Component({
  selector: 'app-top-vendor-card',
  templateUrl: './top-vendor-card.component.html',
  styleUrls: ['./top-vendor-card.component.css']
})
export class TopVendorCardComponent {

}
