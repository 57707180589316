<div mat-dialog-content class="overflow-auto">
    <div class="d-flex align-items-center pb-md-3 pb-2 ">
        <button type="button" class="btn-close mt-2 ms-2" (click)="closeMerchantDialogue()" aria-label="Close"></button>
    </div>
    <h1 mat-dialog-title id="form-login" class="text-center full-screen-dialog mb-0 pb-0 d-none d-md-block heading mt-2">Merchant Login</h1>
    <div class="logo d-block d-md-none my-5">
        <img src="/assets/new-logo.svg" alt="Logo">
    </div>
    <form class="mt-md-5 mt-0" (submit)="onMerchantLogin()" [formGroup]="merchantLoginForm">
        <!-- Email input -->
        <div class="form-outline mb-2 mt-2">
            <input type="email" id="form2Example1" formControlName="email" class="form-control email"
                placeholder=" Email/Phone Number" [ngClass]="{ 'is-invalid': isSubmitting && f['email'].errors }" />
            <div *ngIf="isSubmitting && f['email'].errors" class="invalid-feedback">
                <div *ngIf="f['email'].errors['required']">Email/Phone Number is required</div>
            </div>
        </div>


        <!-- Password input -->


        <div class="form-outline mb-2" style="position: relative;">
            <mat-icon class="pwd-eye" (click)="togglePasswordVisibility()">
                {{ passwordVisible ? 'visibility' : 'visibility_off ' }}
            </mat-icon>
            <input type="{{ passwordVisible ? 'text' : 'password' }}" id="form2Example2"
                [ngClass]="{ 'is-invalid': isSubmitting && f['password'].errors }" formControlName="password"
                class="form-control password" placeholder="Password" />
            <div *ngIf="isSubmitting && f['password'].errors" class="invalid-feedback">
                <div *ngIf="f['password'].errors['required']">Password is required</div>
            </div>
        </div>


        <!-- 2 column grid layout for inline styling -->
        <div class="row mb-2" style="margin:0 10% 0 10%;">
            <div class="col text-end pe-0">
                <!-- Simple link -->
                <a href="javascript:void(0)" class="font-size-12 text-decoration-none forget-password"
                    (click)="openForgetPasswordDialog()">Forgot password?</a>
            </div>
        </div>

        <!-- Submit button -->
        <button type="submit" class="login-btn btn text-light mb-2  px-5 py-1">Login</button>
        <div class="d-flex justify-content-center align-items-center">
            <hr class="hr-or">
            <span class="mx-2 text-dark">or</span>
            <hr class="hr-or">
        </div>
        <div class="text-center mt-3">
            <div class="mt-3">
                <p class="skip-login"><span class="text-light me-2 "> New Merchant?</span> <a class="pointer text-dark" (click)="openGuideSignUpDialog()">Register here</a></p>
            </div>
        </div>
    </form>
</div>