import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { interval } from 'rxjs';
import { PopupBookingDetailsComponent } from 'src/app/popups/popup-booking-details/popup-booking-details.component';

@Component({
  selector: 'app-popup-payment-details',
  templateUrl: './popup-payment-details.component.html',
  styleUrls: ['./popup-payment-details.component.css']
})
export class PopupPaymentDetailsComponent {

  amount:any="";
  seconds:number=5;
  interval:any;
  closedBy:string= 'cancel';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public paymentDetailsDialogRef: MatDialogRef<PopupPaymentDetailsComponent>){
    this.amount = data.message.amount;
  }

  ngOnDestroy(){
    clearInterval(this.interval)
  }

  ngOnInit(){

   this.interval = setInterval(()=>{
    if(this.seconds !=0){
      this.seconds = this.seconds -1;
    }else{
      this.closedBy ='time';
      this.closePopUpDialog();
    }
   },1000)
  }
  

  closePopUpDialog(){
    this.paymentDetailsDialogRef.close({closedBy:this.closedBy});
  }
}
