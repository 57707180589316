import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { EnquiryFormComponent } from 'src/app/enquiry-form/enquiry-form.component';
import { MatDialog } from '@angular/material/dialog';

interface ProvidedData {
  id: any;
  locationid: any;
  city: any;
  name: any;
  shopsrc: any;
  src: any;
  shopname: any;
  hasCoupon: any;
  latitude: any;
  longitude: any;
  distanceAway: any;
  price: any;
  activityId: any;
  rating: any;
  timefrom: any;
  timeto: any;
  merchantshopname: any;
  avgRating: any;
  date: any;
  time: any;
  guest: any;
  variations: any;
}

interface merchantIDetail {
  latitude: any;
  longitude: any;
  id: any;
  name: any;
  city: any;
  src: any;
  shopsrc: any;
  distanceAway: any;
  shopname: any;
  avgRating: any;
  locationid: any;
  hasCoupon: any;
}

interface activityDetails {
  timefrom: any;
  timeto: any;
}

@Component({
  selector: 'app-provided-by-card',
  templateUrl: './provided-by-card.component.html',
  styleUrls: ['./provided-by-card.component.css'],
})
export class ProvidedByCardComponent {
  @Input() data: ProvidedData;
  @Input() merchant: merchantIDetail;
  @Input() activityId: any;
  @Input() merchantData: any;
  @Input() activityDetails: activityDetails;
  @Input() exploreData: any;
  @Input() activityName: any;

  constructor(private router: Router, public dialog: MatDialog, public dataService: DataService) {}
  ngOnInit() {
    
   }
  openMerchant(locationid: any, id: any, city: any, name: any) {
    city = city.trimEnd().replace(/\s+/g, '-').toLowerCase();
    name = name.trimEnd().replace(/\s+/g, '-').toLowerCase();
    this.router.navigate([
      '/merchant-profile',
      'location',
      locationid,
      'merchantid',
      id,
      city,
      name,
    ]);
  }

  bookMerchant(id: any, activityid: any) {
    // if (this.name) {
    this.router.navigate(['/book-merchant'], {
      queryParams: { merchantId: id, activityId: activityid, type: 'User' },
    });
    // } else {
    //   if (!this.ifMobileApp()) {
    //     let queryParams = this.route.snapshot.queryParams;
    //     const currentUrl = this.router.url.split('?')[0];
    //     let q = Object.keys(queryParams).map(k => k !== 'dialogue' ? `${k}=${queryParams[k]}` : '').filter(k => k !== '').join('&');
    //     q = q !== '' ? q + '&dialogue=user' : q + `dialogue=user`
    //     window.location.href = `${currentUrl}?${q}`;
    //   } else {
    //     this.openLoginDialog();
    //   }
    // }
  }

  bookRaftingMerchant(id: any, activityid: any, merchantid: any) {
    // this.isSubmitting = true;
    // if (this.variationForm.invalid) {
    //   if (this.dialogContent && this.dialogContent.nativeElement) {
    //     this.dialogContent.nativeElement.scrollTop = 0;
    //   }
    //   return;
    // }
    let data: any = {};
    data.activityId = this.data.activityId;
    data.date = this.data.date;
    data.time = this.data.time;
    data.guest = this.data.guest;
    this.data.variations = [];
    this.router.navigate(['/book-merchant/rafting'], {
      queryParams: {
        merchantId: merchantid,
        merchantactivityid: id,
        activityId: activityid,
        type: 'User',
        data: JSON.stringify(data),
      },
    });
  }
  
  openEnquireDialog(merchantId:any) {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '87%',
      width: '33%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      };
    }

    this.dialog.open(EnquiryFormComponent, {
      ...sizes,
      disableClose: true,
      data: {
        datafrom: (this.activityName)? this.activityName : '',
        activityType: "0",
        merchantId: merchantId
      },
    });

  }
}
