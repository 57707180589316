import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ForgetPasswordPopupComponent } from 'src/app/forget-password-popup/forget-password-popup.component';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';

@Component({
  selector: 'app-partner-login-dialogue',
  templateUrl: './partner-login-dialogue.component.html',
  styleUrls: ['./partner-login-dialogue.component.css']
})
export class PartnerLoginDialogueComponent {
  public passwordVisible: boolean = false;
  isMerchantLoggedIn: boolean = false;
  isMerchantSubmitting: boolean = false;
  isSubmitting: boolean = false;
  partnerLoginForm: any;
  userAgent: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public partnerLoginDialogRef: MatDialogRef<PartnerLoginDialogueComponent>, private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, public loginDialogRef: MatDialogRef<LoginDialogComponent>, public dialog: MatDialog, private authService: AuthService, private snackBar: MatSnackBar) {
    var raftingDetails = localStorage.getItem('raftingDetails');
    var campingDetails = localStorage.getItem('campingDetails');
    var rentalDetails = localStorage.getItem('rentalDetails');
    var locationDetails = localStorage.getItem('location');
    var linkFrom = localStorage.getItem('linkFrom');
    var appToken = localStorage.getItem('appToken');
    var trekkingDetails = localStorage.getItem('trekkingDetails');
    var tourDetails = localStorage.getItem('tourDetails');
    localStorage.clear();
    if(raftingDetails && raftingDetails != ""){
      localStorage.setItem('raftingDetails',raftingDetails);
      }
    if(campingDetails && campingDetails != ""){
    localStorage.setItem('campingDetails',campingDetails);
    }
    if(locationDetails && locationDetails != ""){
      localStorage.setItem('location',locationDetails);
    }
    if(trekkingDetails && trekkingDetails != ""){
      localStorage.setItem('trekkingDetails',trekkingDetails);
    }
    if(rentalDetails && rentalDetails != ""){
      localStorage.setItem('rentalDetails',rentalDetails);
    }
    if(linkFrom && linkFrom != ""){
      localStorage.setItem('linkFrom',linkFrom);
    }
    if(tourDetails && tourDetails != ""){
      localStorage.setItem('tourDetails',tourDetails);
    }
    localStorage.setItem('appToken', appToken);
  }

  ngOnInit() {
    this.userAgent = window.navigator.userAgent;
    this.partnerLoginForm = this.formBuilder.group({ email: new FormControl('', Validators.required), password: new FormControl('', Validators.required) });

  }

  get f(): { [key: string]: AbstractControl } {
    return this.partnerLoginForm.controls;
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  closeLoginPartnerDialogue() {
    this.partnerLoginDialogRef.close();
  }

  onPartnerLogin() {
    this.isSubmitting = true;
    if (this.partnerLoginForm.invalid) {
      return;
    }
    this.authService.partnerlogin(this.partnerLoginForm.value).subscribe({
      next: (res) => {
        this.isSubmitting = false;
        this.partnerLoginDialogRef.close();
        this.router.navigate(['/partnerdashboard']);
        this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' },);
      },
      error: (err) => {
        this.isSubmitting = false;
        this.snackBar.open('Email/Phone Number or Password is Incorrect ', 'close', { duration: 3000, verticalPosition: 'top' },);

      }
    });
  }



  ifMobileApp() {
    if (this.userAgent.includes('AdvenzoneMobileApp')) {
      return true;
    } else {
      return false;
    }
  }

  openForgetPasswordDialog() {
    this.partnerLoginDialogRef.close();
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: 'fit-content',
      width: '50%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      }
    }
    this.dialog.open(ForgetPasswordPopupComponent, {
      ...sizes,
      disableClose: true,
      data: {
        type: "partner"
      },
    });
  }
}
