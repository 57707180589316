import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DateConversionService } from 'src/app/services/date-conversion.service';

@Component({
  selector: 'app-mobile-discount-popup',
  templateUrl: './mobile-discount-popup.component.html',
  styleUrls: ['./mobile-discount-popup.component.css']
})
export class MobileDiscountPopupComponent {
  validto: any;

  constructor(private bottomSheetRef: MatBottomSheetRef<MobileDiscountPopupComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private dateService: DateConversionService) {
    let dateValid = Number(data.validto);
    this.validto = this.dateService.convertTimestampToDate(dateValid)
  }

  close(): void {
    this.bottomSheetRef.dismiss(); // This closes the bottom sheet
  }

  
  getValidForFirstMessage(validforfirst: string): string {
    if (validforfirst === 'all') {
      return '*Applicable for all bookings';
    } else {
      return `*Applicable for first ${validforfirst} bookings`;
    }
  }
}
