import { Component,Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';

@Component({
  selector: 'app-download-qrcode',
  templateUrl: './download-qrcode.component.html',
  styleUrls: ['./download-qrcode.component.css']
})
export class DownloadQrcodeComponent {
  merchantId:any;
  url:string= 'advenzone.in/merchant-profile?merchantid=';
  name:any;
  userAgent:string="";
  type:string="";
  uniqueid:string="";
  hostName:any="";
  locationId:any="";
  locationName:any="";
  urlMerchantName:any="";

    constructor(@Inject(MAT_DIALOG_DATA) public data:any,public qrcodeDialogRef: MatDialogRef<DownloadQrcodeComponent>,public dialog: MatDialog,private fileTransfer: FileTransfer, private file: File ) {
    this.hostName = window.location.host;
    if(this.hostName.includes('localhost')){
      this.hostName = 'advenzone.com'
    }
      console.log(data)
      this.type = data.type;
        if(data.type === 'merchant'){
          this.merchantId= data.id;
          this.name = data.merchantname.shopname;
          this.locationId = data.locationId;
          this.urlMerchantName = data.urlMerchantName;
          this.locationName = data.locationName;
          this.url = this.hostName+'/merchant-profile/location/'+data.locationId+'/merchantid/' + this.merchantId +'/'+data.locationName +'/'+ data.urlMerchantName + '?linkfrom=merchant';
        }else if(data.type === 'agent'){
          this.url = `${this.hostName}/agentbooking/${data.uniqueid}`;
          this.uniqueid = data.uniqueid;
          this.name = data.agentname;
        }else if(data.type === "partner"){
          this.url = `${this.hostName}/partnerbooking/${data.uniqueid}`;
          this.uniqueid = data.uniqueid;
          this.name = data.partnername;
        }else if(data.type === "uniquepartner"){
          this.url = `${this.hostName}/partneractivities/${data.uniqueid}`;
          this.uniqueid = data.uniqueid;
          this.name = data.partnername;
        }
        const encodedName = encodeURIComponent(this.name);
    }
    ngOnInit(): void {
      this.userAgent = window.navigator.userAgent;
      document.addEventListener('deviceready', () => {
        console.log("device is now ready")
      }, false);
    }

    @ViewChild('download_qr_code', { static: true })
    elementRef!: ElementRef;

    downloadQrCode() {
      const element:any = this.elementRef.nativeElement;
      if(this.ifMobileApp()){
        html2canvas(element,{
          scale: 2, 
        }).then((canvas) => {
          const imageUrl = canvas.toDataURL('image/png'); // Replace with the actual image URL
          const targetPath = (window as any).cordova.file.externalRootDirectory + 'Download/'; // Specify the target directory path
          const fileName = 'merchant_qr_code.png'; // Specify the desired file name
          let url="";
         
          if(this.type === 'agent'){
            url= "https://advenzone.com/generate-qr-code?uniqueid="+this.uniqueid+ "&name="+ this.name+"&type=agent";
            // window.location.href ="https://advenzone.in/generate-qr-code?agentuniqueid="+this.uniqueid+ "&name="+ this.name+"&type=agent";
          }else if(this.type === 'merchant'){
            const encodedName = encodeURIComponent(this.name);
            url ="https://advenzone.com/generate-qr-code?merchantid="+this.merchantId+ 
            "&name="+ encodedName+"&type=merchant&locationId="+this.locationId+"&locationName="+ this.locationName+ "&urlMerchantName=" + this.urlMerchantName;
            // url ="https://advenzone.com/generate-qr-code?merchantid="+this.merchantId+ "&name="+ this.name+"&type=merchant";
            // window.location.href ="https://advenzone.in/generate-qr-code?merchantid="+this.merchantId+ "&name="+ this.name+"&type=merchant";
          }else if(this.type === 'partner'){
            url ="https://advenzone.com/generate-qr-code?uniqueid="+this.uniqueid+ "&name="+ this.name+"&type=partner";
          }else if(this.type === 'uniquepartner'){
            url ="https://advenzone.com/generate-qr-code?uniqueid="+this.uniqueid+ "&name="+ this.name+"&type=uniquepartner";
          }
           (window as any).cordova.InAppBrowser.open(url, '_system', 'location=yes');
          // this.downloadImage(imageUrl, targetPath, fileName);
        });

        return;
      }
      html2canvas(element,{
        scale: 2, // Increase the scale for higher resolution
      }).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        if(this.type == 'agent'){
          link.download ="agent_qr_code.png";
        }else if(this.type == 'merchant'){
          link.download ="merchant_qr_code.png";
        }else if(this.type == 'partner'){
          link.download ="partner.png";
        }else {
          link.download ="qr_code.png";
        }
        link.click();
      });

    }
    
    ifMobileApp(){
      if(this.userAgent.includes('AdvenzoneMobileApp')){
        return true;
      }else{
        return false;
      }
    }

  closeDialog(){
    this.qrcodeDialogRef.close();
  }

  downloadImage(imageUrl: string, targetPath: string, fileName: string) {
    // const fileTransfer: FileTransferObject = this.fileTransfer.create();
    // fileTransfer.download(imageUrl, targetPath + fileName).then((entry) => {
    //   console.log('Image downloaded successfully. Path:', entry.toURL());
    //   // The image has been successfully downloaded to the specified path
    // }, (error) => {
    //   console.error('Error downloading image:', error);
    // });

    const fileTransfer = new FileTransfer();
    const fileURL = targetPath + fileName;
    const options={};
  
    (window as any).cordova.plugin.http.downloadFile(imageUrl, options, {}, fileURL,
      (response :any) =>{
        console.log('Image downloaded successfully. Path:', fileURL, JSON.stringify(response));
        // The image has been successfully downloaded to the specified path
      },
      (error :any)=> {
        console.error('Error downloading image:', error);
      }
    );
  }

  
}
