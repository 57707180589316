import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { EnquiryFormComponent } from 'src/app/enquiry-form/enquiry-form.component';
import { InfoComponent } from 'src/app/info/info.component';


interface ActivityData {
  addressline1: any;
  difficulty: any;
  min_price: any;
  id: any;
  locationid: any;
  category: any;
  src: any;
  rating: any;
  totalaltitude: any;
  numberofdays: any;
  slot_available: any;
  description: any;
  activitiesaddressline1: any;
  campingName: any;
  merchantname: any;
  distanceAway: any;
  campingLongitude: any;
  campingLatitude: any;
  merchantid: any;
  activitieslocationid: any;
  avgReview: any;
  hasCoupon: any;
  activitiescategory: any;
  city: any;
  activitieslocationname:any;
  locationname:any;
}
@Component({
  selector: 'app-other-activities-card',
  templateUrl: './other-activities-card.component.html',
  styleUrls: ['./other-activities-card.component.css']
})
export class OtherActivitiesCardComponent {
  @Input() data: ActivityData;

  constructor(private router: Router, public dialog: MatDialog, public dataService: DataService) {
  }
ngOnInit(){
}
  openActivitie(locationid: any, id: any, e: any, category: any, addressline1: any,locationname:any,activitieslocationname:any,activitiesaddressline1:any) {
    if (e.target instanceof HTMLElement && (e.target.classList.contains('carousel-control-next-icon') || e.target.classList.contains('carousel-control-prev-icon'))) {
      e.stopPropagation();
      return;
    }
  
    // this.router.navigate(['/location',locationid,'activity-details',id ,category,addressline1])

    if (category === 'rafting' || category === 'activity') {
      addressline1 = addressline1.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
      locationname = locationname.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
      this.router.navigate(['/location', locationid, 'activity-details', id, locationname, addressline1])
    } else if (category === 'trekking') {
      addressline1 = addressline1.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
      locationname = locationname.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
      this.router.navigate(['/location', locationid, 'trekking-detail', id, locationname, addressline1])
    } else if (category === 'tour') {
      addressline1 = addressline1.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
      locationname = locationname.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
      this.router.navigate(['/location', locationid, 'package-tour-detail', id, locationname, addressline1])
    }else if (category === 'camping' ) {
      activitiesaddressline1 = activitiesaddressline1.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
      locationname = locationname.trim().replace(/[^a-zA-Z0-9\s]/g, '-').replace(/\s+/g, '-').toLowerCase();
      // activitiesaddressline1 = activitiesaddressline1.trimEnd().replace(/\s+/g, '-').toLowerCase();
      // locationname = activitieslocationname.trimEnd().replace(/\s+/g, '-').toLowerCase();
      this.router.navigate(['/location', locationid, 'camping-details', id, locationname, activitiesaddressline1])
      
    }
  };

  openInfoDialog(locationid: any, event: any, description: any, id: string, datafrom: string,locationname:any,addressline1:any) {
    event.stopPropagation();
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '500px',
      width: '50%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      }
    }
    this.dialog.open(InfoComponent, {
      ...sizes,
      disableClose: true,
      data: {
        locationid, id, description, datafrom,locationname,addressline1
      },
    });
  }

  openEnquireDialog(addressline: any) {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '87%',
      width: '33%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      };
    }

    this.dialog.open(EnquiryFormComponent, {
      ...sizes,
      disableClose: true,
      data: {
        datafrom: addressline,
        activityType: "0",
      },
    });

  }

  openMerchantPage(locationid: any, id: any,city:any,name:any) {
    city = city.trimEnd().replace(/\s+/g, '-').toLowerCase();
    name = name.trimEnd().replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/merchant-profile','location',locationid,'merchantid',id,city,name])
  }

  openMap(event: any, latitude: any, longitude: any) {
    event.stopPropagation()
    this.dataService.openMap(latitude, longitude);
  }

  openCamping(activitieslocationid: any, id: any, e: any, activitieslocationname: any, campingName: any) {
    if (e.target instanceof HTMLElement && (e.target.classList.contains('carousel-control-next-icon') || e.target.classList.contains('carousel-control-prev-icon'))) {
      e.stopPropagation();
      return;
    }
    campingName = campingName.trimEnd().replace(/\s+/g, '-').toLowerCase();
    activitieslocationname = activitieslocationname.trimEnd().replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/location', activitieslocationid, 'camping-details', id, activitieslocationname, campingName])
  }

  
  get fireRating() {
    return this.data.avgReview ?? this.data.rating;
  }
}
