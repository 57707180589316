<app-header></app-header>
<!-- <div class="position-absolute me-3">
  <div class="connect-container">
  <img src="/assets/connect.svg" class="pointer " alt="" [class.shown]="!additionalImagesVisible" [class.hidden]="additionalImagesVisible" width="50px" id="connect-button" (click)="toggleImage($event)" >
  <img src="/assets/connect.svg" class="pointer" alt="" [class.shown]="!additionalImagesVisible " [class.hidden]="additionalImagesVisible " width="50px" id="connect-button" (click)="toggleImage($event)" *ngIf="isHomePage()">
  <img src="/assets/whatsapp.svg" class="pointer" width="50px" [class.hidden]="additionalImagesVisible || isHomePage()" alt="" (click)="openWhatsapp()">
  </div>
  <img src="/assets/enquiry.svg" class="pointer" width="50px" [class.hidden]="!additionalImagesVisible" alt="" id="enquiry-button" (click)="openDialog()"*ngIf="isHomePage()" >
  <img src="/assets/whatsapp.svg" class="pointer" width="50px" [class.hidden]="!additionalImagesVisible" alt="" (click)="openWhatsapp()" >
  <img src="/assets/close.svg"class="pointer" width="50px" [class.hidden]="!additionalImagesVisible" alt="" (click)="closeImages()" *ngIf="isHomePage()">
</div> -->

<div *ngIf="showCallback" class="callback">
  <label class="pointer  px-3 "  (click)="openDialog()">Request a Call Back</label>
</div>

<!-- <div class="whatsapp-img animated tada">
    <div class="whatsappDisplay" (mouseenter)="hovering = true" (mouseleave)="hovering = false">
      <img *ngIf="showCallback" src="../assets/whatsapp.svg" class="pointer" alt="" (click)="toggleLabelVisibility()">
      <div class="whatsappDisplay-content " [style.display]="(showLabel || hovering) ? 'block' : 'none'" >
        <label class="pointer px-3 fw-bold shadow" (click)="openWhatsapp()">Click to chat</label> 
      </div>
    </div>
  </div> -->

<div class="whatsapp-img" (mouseenter)="animateTada = true" (mouseleave)="animateTada = false">
  <div class="whatsappDisplay"  (mouseenter)="hovering = true" (mouseleave)="hovering = false" >
      <img *ngIf="showCallback" src="../assets/whatsapp.svg" [ngClass]="{'animated tada': animateImgTada}"
        class="pointer " alt="whatsapp" (click)="toggleLabelVisibility()">
    <div [style.display]="(showLabel || hovering) ? 'block' : 'none'" >
      <label class="pointer px-3 fw-bold shadow" (click)="openWhatsapp()"
        [ngClass]="{'animated tada': animateLabelTada}"[style.top.%]="animateLabelTada ? 15 : 50">Click to chat</label>
    </div>
  </div>
</div>




<div class="phone-img"  (mouseenter)="animateCallTada = true" (mouseleave)="animateCallTada = false">
  <div  class="phoneDisplay"(mouseenter)="hoveringCall = true" (mouseleave)="hoveringCall = false">
    <img *ngIf="showCallback" src="../assets/contact.svg" class="pointer" alt="contact" [ngClass]="{'animated tada': animateCallImgTada}"(click)="toggleCallLabelVisibility()">
    <div  [style.display]="(showCallLabel || hoveringCall) ? 'block' : 'none'">
      <label class="pointer px-3 fw-bold shadow" (click)="callSupport()" [ngClass]="{'animated tada': animateCallLabelTada}"[style.top.%]="animateCallLabelTada ? 18 : 53">Call us</label>
    </div>
  </div>
</div>

<!-- <div class="whatsapp-img">
    <img *ngIf="showCallback" src="/assets/whatsapp.svg" class="pointer "  alt=""   (click)="toggleLabelVisibility()">
    <label class="pointer  px-sm-3 fw-bold "  *ngIf="showLabel" (click)="openWhatsapp()" >Click to chat</label> 
    <div class="test ">
      <img *ngIf="showCallback" src="/assets/whatsapp.svg" class="pointer "  alt=""   (click)="toggleLabelVisibility()">
  <div class="test-content">
    <label class="pointer  px-sm-3 fw-bold labely"  *ngIf="showLabel" (click)="openWhatsapp()" >Click to chat</label> 
  </div>
</div>
</div> -->
<!-- <div class="text-center mt-2" animated tada>
<a href="javascript:void(0)" (click)="callSupport()" class="btn call-btn phone-img">
 <img src="/assets/phone.svg" alt="call" class=""> Call Us
</a>
</div> -->


<router-outlet></router-outlet>