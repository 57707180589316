import { Component, Inject } from '@angular/core';
import {MatDialog,  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormBuilder, FormGroup, AbstractControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ModalAlertComponent } from '../modal-alert/modal-alert.component';
import {catchError} from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.css']
})
export class ContactUsFormComponent {
  isSubmitting: boolean = false;
  contactUsForm!: FormGroup;
  userAgent: string = "";
  url: SafeResourceUrl | undefined;
  imagesrc: string | undefined;

  constructor(private formBuilder: FormBuilder,public dialog: MatDialog,private router: Router, private contactUsDialogRef: MatDialogRef<ContactUsFormComponent>, private http: HttpClient,  @Inject(MAT_DIALOG_DATA) data: { url:string,imagesrc:string},private sanitizer: DomSanitizer) {
    this.userAgent = window.navigator.userAgent;

   if (data?.url) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
  }

  if (data?.imagesrc) {
    this.imagesrc = data.imagesrc;
  }

 
   }
  
   

  ngOnInit(): void {

    this.contactUsForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('',[Validators.required,this.validatePhoneNumber]),
      name: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required)
    });

  }

  validatePhoneNumber(control: any) {
    const phoneNumber = control.value;
    const phoneNumberPattern = /^([6-9]\d{9})$/; // Regular expression for 10-digit number

    if (phoneNumber && !phoneNumber.match(phoneNumberPattern)) {
      return { invalidPhoneNumber: true };
    }

    return null;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.contactUsForm.controls;
  }

  onFormSubmit(){
    this.isSubmitting = true;
    if (this.contactUsForm.invalid) {
      return;
    }
    this.contactUsDialogRef.close();
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: 'fit-content',
      width: 'fit-content',
      maxWidth: '50vw',
    };
    if (isMobile) {
      sizes = {
        height: 'fit-content',
      width: 'fit-content',
      maxWidth: 'unset',
      }
    }
    this.http.post(`${environment.apiUrl}/users/contactus`,{...this.contactUsForm.value}).pipe(
      catchError((_err: HttpErrorResponse)=> {
        this.dialog.open(ModalAlertComponent, {
          ...sizes,
          disableClose: true,
          data: {
            title:'Some Error occurs',
            message: JSON.stringify(_err.error.errors),
            status:'danger'
          },
        })
        throw new Error("Error");
      })
    ).subscribe((data:any)=>{
      if(data.status === "SUCCESS"){
        this.dialog.open(ModalAlertComponent, {
          ...sizes,
          disableClose: true,
          data: {
            title:'Thank you',
            message: 'The Admin will contact you within 24 to 48 hours.',
            status:'success'
          },
        })
      }
    });
  }

  callSupport(){
    if (this.ifMobileApp()) {
      (window as any).cordova.InAppBrowser.open("tel:+917252927302", '_system', 'location=yes');
    } else {
      window.location.href="tel:+917252927302"
    }
  }

  ifMobileApp() {
    if (this.userAgent.includes('AdvenzoneMobileApp')) {
      return true;
    } else {
      return false;
    }
  }


  closeContactUsDialog(){
    this.contactUsDialogRef.close();
  }
}
