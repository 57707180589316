import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Inject, Renderer2 } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/data.service';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-fetch-location',
  templateUrl: './fetch-location.component.html',
  styleUrls: ['./fetch-location.component.css']
})
export class FetchLocationComponent {
  
  displayForm:any 
  locations:any=[];
  locationForm!: FormGroup;
  isSubmitting:boolean=false;
  locationparamId:any;
  locationInputFrom:string="";
  userId: any;

  constructor(private http: HttpClient,public fetchLocationDialogRef: MatDialogRef<FetchLocationComponent>, private formBuilder: FormBuilder, private dataService: DataService, public router: Router,private route: ActivatedRoute, private renderer: Renderer2 , public auth: AuthService,public ElementRef: ElementRef,@Inject(MAT_DIALOG_DATA) data: { displayForm:string, locationInputFrom:string }) {
    this.displayForm = data?.displayForm;
    if(data?.locationInputFrom){
      this.locationInputFrom = data?.locationInputFrom
    }else{
      this.locationInputFrom ="";
    }
    this.auth.userInfo.subscribe(info => {
      if (info !== null) {
        this.userId = info.id;
      }
    })
  }
 



  ngOnInit(){
    this.http.post(environment.apiUrl + `/locations/list`, {sortColumn: 'locationname', sort: 'ASC', count:500}, { responseType: 'json' }).subscribe(async (data: any) => {
      this.locations = data.locations;
    })
    this.locationForm = this.formBuilder.group({
      location:  new FormControl('', Validators.required),
    });
    this.applyBackdropClass();
    // this.getLocation()
  }

  // async getLocation(){
  //   const url = window.location.href;
  //   if (url.includes('/location')) {
  //     const parts = url.split('/');
  //     const locationId = parts[parts.indexOf('location') + 1];

  //     const storedLocation = localStorage.getItem('location');
  //     if (storedLocation || storedLocation === undefined || storedLocation === null) {
  //       this.fetchLocationData(locationId);
  //       this.displayForm = true;
  //       this.applyBackdropClass();
  //     } else {
  //       this.displayForm = false;
  //         try {
  //           const response: any = await this.http.post(environment.apiUrl + `/locations/list`, {
  //             status: 1,
  //             sortColumn: 'locationname',
  //             sort: 'ASC',
  //             count: 500
  //           }, { responseType: 'json' }).toPromise();
        
  //           this.locations = response.locations;
  //         } catch (error) {
  //           console.error('Error fetching locations:', error);
  //         }
        
  //     }
  //   }
  //   this.locationForm = this.formBuilder.group({
  //     location:  new FormControl('', Validators.required),
  //   });
  // }


  get f(): { [key: string]: AbstractControl } {
    return this.locationForm.controls;
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.submitForm();
    }
  }

  submitForm(){
    this.isSubmitting = true;
    if (this.locationForm.invalid) {
      return
    }
  
    let location = JSON.parse(this.locationForm.value.location);
    if(!this.router.url.includes('search') && !this.router.url.includes('merchant-profile')){
      if(location.status == 1){
        localStorage.setItem('location', this.locationForm.value.location);
        this.dataService.changelocationSource(this.locationForm.value.location);
        if(this.locationInputFrom != ""){
          this.http.post(environment.apiUrl + `/usersearch/create`, { userId: (this.userId) ? this.userId : 0, page: 'home', searchData: this.locationInputFrom }, { responseType: 'json' }).subscribe(async (data: any) => { })
          this.router.navigate(['/search'], { queryParams: { q: this.locationInputFrom } });
        }else{
          this.router.navigate(['/home/location/'+location.id+'/'+location.locationname.trimEnd().toLowerCase().replaceAll(" ", "-")]);
        }
      }else{
        this.router.navigate(['/service-unavailable']);
      }
    }else{
      if(location.status != 1){
        this.router.navigate(['/service-unavailable']);
      }
    }
    this.fetchLocationDialogRef.close();
  }

  
  closeDialog() {
    this.fetchLocationDialogRef.close();
  }

  stringifyLocation(location: any): string {
    return JSON.stringify(location);
  }

  closeFetchLocationDialog() {
    this.fetchLocationDialogRef.close();
  }
  
  highlightInputGroup() {
    const inputGroup:any = document.querySelector(`.input-group.fetch-location`);
    inputGroup.classList.add('highlight-input-group');
  }

  removeHighlightInputGroup() {
    const inputGroup:any = document.querySelector(`.input-group.fetch-location`);
    inputGroup.classList.remove('highlight-input-group');
  }
  fetchLocationData(id: string): void {
    this.http.get(environment.apiUrl + `/locations/get?id=${id}`).subscribe(async (data: any) => {
      if (data.location && data.location.id) {
        localStorage.setItem('location', JSON.stringify(data.location));
        this.dataService.changelocationSource(JSON.stringify(data.location));
       
      }
    });
  }

  private  applyBackdropClass() {
    
      if (this.displayForm == true) {
        const overlayContainer = document.querySelector('.cdk-overlay-container');
        if (overlayContainer) {
          this.renderer.removeClass(overlayContainer, 'cdk-overlay-container');
        }
        const htmlElement = this.renderer.selectRootElement('html', true);
        const overlayBackdrop = document.querySelector('.cdk-overlay-backdrop');
        if (overlayBackdrop) {
        this.renderer.removeClass(htmlElement, 'cdk-global-scrollblock');
        
      }
       else {
        const htmlElement = this.renderer.selectRootElement('html', true);
        this.renderer.removeClass(htmlElement, 'cdk-global-scrollblock');
        this.renderer.addClass(htmlElement, 'pointer');

      }
    }
  }

}
