import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DataService } from '../data.service';
declare var $: any;
@Component({
  selector: 'app-enquiry-form',
  templateUrl: './enquiry-form.component.html',
  styleUrls: ['./enquiry-form.component.css']
})

export class EnquiryFormComponent {
  enquiryForm!: FormGroup;
  isSubmitting: boolean = false;
  currentUrl: string;
  datafrom = '';
  type = '';
  name = '';
  activityType='';
  heading: boolean = false;
  activityname:boolean = true;
  rows: number = 3; 
  startDate: string = '';
  isDateFocused: boolean = false;
  currentDate: string | undefined;
  savedData: any[] = [];
  merchantId: string = "";


  constructor(private http: HttpClient, private formBuilder: FormBuilder, private router: Router, public snackBar: MatSnackBar, private modalRef: MatDialogRef<EnquiryFormComponent>, private elementRef: ElementRef,private dataService:DataService, @Inject(MAT_DIALOG_DATA) data: { datafrom:string ,activityType:string,name:string ,merchantId:string , })  {
    this.currentUrl = this.router.url;

   this.dataService.getSavedData().subscribe(data => {
      if (data[0].datafrom) {
        this.activityname = false;
        this.datafrom = data[0].datafrom;
      } else {
        this.datafrom = '';
      }

      if (data[0].name) {
        this.name = data[0].name;
      } else {
        this.name = '';
      }
      if (data[0].merchantId) {
        this.merchantId = data[0].merchantId;
      }

      if (data[0].activityType === "0") {
        this.activityType = data[0].activityType;
      } else if (data[0].activityType === '') {
        this.activityType = '';
      }
 
  });
     
  if (data?.datafrom) {
    this.activityname = false;
    this.datafrom = data.datafrom;
  } else {
    this.datafrom = '';
  }

  if (data?.name) {
    this.name = data.name;
  } else {
    this.name = '';
  }

  if (data?.activityType == "0") {
    this.activityType = data.activityType;
  } else if(data?.activityType == ''){
  }
  if (data?.merchantId) {
    this.merchantId = data.merchantId;
  }
  }

  ngOnInit() {
    this.enquiryForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      phone: new FormControl('', [Validators.required,this.validatePhoneNumber]),
      email: new FormControl(''),
      activityName: new FormControl(''),
      noOfPeople: new FormControl(''),
      message: new FormControl(''),
      travelDate: new FormControl(''),
      url: this.currentUrl,
    });
    this.enquiryForm.patchValue({
      activityName: this.datafrom ,
    });
  }


  ngAfterViewInit(){
  $('#date-input').datepicker({
    minDate:new Date(),
    dateFormat: 'yy/mm/dd',
    autoclose: true,
    onSelect: (dateText: string) => {
      this.currentDate = dateText;
      this.enquiryForm.get('travelDate')?.setValue(dateText);
    },
  });
  }


      
  submit() {

   if(this.merchantId){
      this.enquiryForm.addControl('merchantId', new FormControl(this.merchantId));
   }else{
    this.enquiryForm.removeControl('merchantId');
   }
   
    this.isSubmitting = true
    if (this.enquiryForm.invalid) {
      const element = this.elementRef.nativeElement.querySelector('.fixed-margin-top');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
      return;
    }
    if (!this.enquiryForm.invalid) {    
      this.http.post(environment.apiUrl + `/enquiries/create`, { ...this.enquiryForm.value, }, { responseType: 'json' }).subscribe(async (data: any) => {
        this.snackBar.open(' Submitted Successfully ', 'close', { duration: 3000, verticalPosition: 'top' });
      })
      this.modalRef.close();
      // this.enquiryForm.reset();
      // this.enquiryForm.get('name').setErrors(null);
      // this.enquiryForm.get('phone').setErrors(null);
    }
  }

  isHomePage(): boolean {
    const currentRoute = this.router.url;
    const homeRoute = '/home';
    return currentRoute === homeRoute;
  }

  isIncludedRoute(): boolean {
    const currentRoute = this.router.url;
    
    const excludedRoutes = ['/trekkingdetail', '/camping-details', '/activities', '/package-tour-merchant-details', '/trekking-merchant-details', '/package-tour-detail'];
    if(!excludedRoutes.includes(currentRoute)){
      return true
    }
    else{
      return false
    }
  }
  isHeaderVisible(): boolean {
    const currentRoute = this.router.url;
    const merchantRoute = ['/merchant-profile','/agentbooking','/partnerbooking'];
    // const isVisible = currentRoute.startsWith(merchantRoute);
    const isVisible = merchantRoute.some(route => currentRoute.startsWith(route));
    return isVisible;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.enquiryForm.controls;
  }

  validatePhoneNumber(control: any) {
    const phoneNumber = control.value;
    const phoneNumberPattern = /^([6-9]\d{9})$/; // Regular expression for 10-digit number
    if (phoneNumber && !phoneNumber.match(phoneNumberPattern)) {
      return { invalidPhoneNumber: true };
    }
    return null;
  }
   closeEnquiry() {
    this.modalRef.close();
  }
}
