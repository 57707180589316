<h1 mat-dialog-title id="form-login" class="text-center" style="text-align: center;" class="full-screen-dialog">New
    Password</h1>
<div mat-dialog-content class="overflow-auto">

    <form class="h-100" (submit)="onSubmit()" [formGroup]="newpasswordForm">
        <div class="form-outline mb-4 mt-3 position-relative">
            <mat-icon class="pwd-eye" (click)="togglePasswordVisibility()">
                {{ passwordVisible ? 'visibility' : 'visibility_off ' }}
            </mat-icon>
            <input type="{{ passwordVisible ? 'text' : 'password' }}" id="currentpassword" formControlName="currentpassword"
                class="form-control password" placeholder="Current Password"
                [ngClass]="{ 'is-invalid': isSubmitting && f['currentpassword'].errors }" />
            <div *ngIf="isSubmitting && f['currentpassword'].errors" class="invalid-feedback">
                <div *ngIf="f['currentpassword'].errors['required']">Current Password is required</div>
            </div>
        </div>
        <div class="form-outline mb-4 mt-3 position-relative">
            <mat-icon class="pwd-eye" (click)="toggleNewPasswordVisibility()">
                {{ passwordNewVisible ? 'visibility' : 'visibility_off ' }}
            </mat-icon>
            <input type="{{ passwordNewVisible ? 'text' : 'password' }}" id="password" formControlName="password"
                class="form-control password" placeholder="New Password"
                [ngClass]="{ 'is-invalid': isSubmitting && f['password'].errors }" />
            <div *ngIf="isSubmitting && f['password'].errors" class="invalid-feedback">
                <div *ngIf="f['password'].errors['required']">New Password is required</div>
            </div>
        </div>
        <div class="form-outline mb-4 mt-3 position-relative">
            <mat-icon class="pwd-eye" (click)="toggleConfirmPasswordVisibility()">
                {{ confirmPasswordVisible ? 'visibility' : 'visibility_off ' }}
            </mat-icon>
            <input type="{{ confirmPasswordVisible ? 'text' : 'password' }}" id="confirmpassword"
                formControlName="confirmpassword" class="form-control password" placeholder="Confirm Password"
                [ngClass]="{ 'is-invalid': isSubmitting && f['confirmpassword'].errors }" />
            <div *ngIf="isSubmitting && f['confirmpassword'].errors" class="invalid-feedback">
                <div *ngIf="f['confirmpassword'].errors['required']">Confirm Password is required</div>
                <div *ngIf="f['confirmpassword'].errors['passwordMismatch']">Password does not match.</div>
            </div>
        </div>
        <div class="text-center mt-3">
            <button type="submit" class="login-btn btn text-light mb-2 ">Submit</button>
        </div>
    </form>
</div>