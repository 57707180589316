<div>
    <div class="d-flex justify-content-around">
        <div>
            <img src="/assets/icons/footer-category-icon.svg" alt="category icon" class="pointer">
            <h6>category</h6>
        </div>
        <div>
            <img src="/assets/icons/footer-search-icon.svg" alt="category icon" class="pointer">
            <h6>Search</h6>
        </div>
        <div>
            <img src="/assets/icons/footer-location-icon.svg" alt="category icon" class="pointer">
            <h6>Rishikesh</h6>
        </div>
    </div>
</div>
