<div mat-dialog-content class="overflow-auto">
    <div class="d-flex align-items-center mb-3">
        <button type="button" class="btn-close mt-3 ms-3" (click)="closeDialog()" aria-label="Close"></button>
        <h1 mat-dialog-title  class="text-center mb-0 full-screen-dialog" style="text-align: center; width: calc(100% - 50px);">View QR-Code
        </h1>
    </div>
    <div class="w-100">
    <div #download_qr_code class="qr-code mx-auto">
        <div class="qr-code-border bg-white">
            <div class="position-relative h-100">
            <div class="d-flex align-items-center justify-content-around mt-2">
                <img src="/assets/new-logo.svg" width="28px" alt="logo" />
                <h3 class="banner-img mb-0"><b>Advenzone</b></h3>
            </div>
            <hr class="m-2">
            <h3 class="scan-to-book text-center">Scan To Book</h3>
            <div class="qrcodeImage justify-content-center">
                <qrcode [qrdata]="url" [allowEmptyString]="true" [alt]="url"
                    [ariaLabel]="'QR Code image with the following content...'" [cssClass]="'center'"
                    [colorDark]="'#000000'" [colorLight]="'#ffffff'" [elementType]="'img'" [errorCorrectionLevel]="'M'"
                    [margin]="4" [scale]="1" [title]="url" [width]="153"></qrcode>
            </div>
            <h6 class="text-center merchantname" [innerHTML]="name"></h6>
            <h6 class="text-center w-100 position-absolute website">{{(!hostName.startsWith("www."))? 'www.'+ hostName :  hostName}}</h6>
        </div>
        </div>
    </div>
    <button class="btn mx-auto d-block text-light px-md-5 my-3  fw-bold"
    style="background: linear-gradient(90.97deg, #3EBDC6 3.2%, #0DD27F 97.12%);" (click)="downloadQrCode()">Download QR Code</button>
</div>
</div>