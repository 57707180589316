import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  userAgent:any;
  constructor(public http:HttpClient) { 
    this.userAgent = window.navigator.userAgent;
  }

  
  ifMobileApp() {
    if (this.userAgent.includes('AdvenzoneMobileApp')) {
      return true;
    } else {
      return false;
    }
  }

  getCoupon(locationId:any){
    let currentDate = new Date();
    let Timestamp = currentDate.getTime();
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiUrl + `/coupons/currentcoupon?currentTimeStamp=${Timestamp}`)
        .subscribe((data: any) => {
          resolve(data);
        }, (error) => {
          reject(error);
        });
    });
  }

  Coupon(locationId:any,merchantId:any,activityId:any){
    let currentDate = new Date();
    let Timestamp = currentDate.getTime();
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiUrl + `/coupons/view-coupon-list`,{status:1,locationId, merchantId,activityId ,currentTimeStamp: Timestamp})
        .subscribe((data: any) => {
          if(this.ifMobileApp()){
            if(data.coupons.length > 0){
              data.coupons = data.coupons.filter((x:any)=> (x.validforapplication	== "mobileapp" || x.validforapplication	== "all" || x.validforapplication	== null ))
            }
          }else{
            if(data.coupons.length > 0){
              data.coupons = data.coupons.filter((x:any)=> (x.validforapplication	== "web" || x.validforapplication	== "all" || x.validforapplication	== null ))
            }
          }
          resolve(data)
        }, (error) => {
          reject(error);
        });
    });
  }
}
