<h1 mat-dialog-title class="text-center heading full-screen-dialog">Booking Successful</h1>
<div mat-dialog-content class="overflow-auto">
    <h1><b>Advance Amount:</b> <span class="ms-2">{{amount}}</span></h1>
    <h5 class="mt-2 mb-0"><b>Note:</b> <span class="ms-2">For confirm booking 20% of the total amount should be paid as
            advance. This amount is non refundable.</span></h5>
    <h5><span> 80% of the amount should be paid to the merchant on the day of the activity.</span></h5>
    <h5><span> Payment link expires in 30 mins.</span></h5>

    <h5 class="redirecting">Redirecting you to payment page in ... {{seconds}} seconds</h5>
    <div class="text-center">
        <button class="btn cancel-btn "  (click)="closePopUpDialog()">Cancel</button>
    </div>
</div>