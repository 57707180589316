import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.css']
})
export class SvgIconComponent {
  @Input() fill: string = 'gray';
  @Input() width: number = 64;
  @Input() height: number = 64;
  @Input() icon: string = '';
}
