import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn:'root'
})

export class AppService{
  userLocation = new BehaviorSubject({latitude: 0,longitude:0});
  
  getDistanceFromLatLonInMeters(latLon1, latLon2) {
    const [lat1, lon1] = latLon1;
    const [lat2, lon2] = latLon2;
    
    const R = 6371000; // Radius of the Earth in meters
    const dLat = this.deg2rad(lat2 - lat1);
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in meters
    return distance;
  }
  
  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  
  isDistanceGreaterThanTenMeters(latLon1, latLon2) {
    const distance = this.getDistanceFromLatLonInMeters(latLon1, latLon2);
    return distance > 10;
  }
  
}