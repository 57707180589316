<div mat-dialog-content class="confirm-container p-0  ">
  <div data-bs-spy="scroll" class="edit-scroll">
    <div class="  m-2 mb-0 p-2  ps-3 connect-container">
      <div class="d-flex">
        <div class="p-0" style="width: calc(100% - 1em);">
          <h1 class="fw-bold default-font-family title mb-0 justify-content-center"
            *ngIf="!isHeaderVisible() && isIncludedRoute() && datafrom === ''">
            Hey! Wanna book an Adventure Activity? Connect with us
          </h1>

          <h1 class="fw-bold default-font-family name mb-0 justify-content-center text-truncate"
            *ngIf="datafrom !== '' && isIncludedRoute()">
            Hey, Wanna know more about <br>{{ datafrom }}
          </h1>

          <h1 class="fw-bold default-font-family name mb-0 justify-content-center text-truncate"
            *ngIf="isHeaderVisible() && !isHomePage() && isIncludedRoute()">
            Hey! Wanna connect with <br>{{name}}
          </h1>



        </div>
        <div class=" p-0">
          <button type="button" class="btn-close  justify-content-end" (click)="closeEnquiry()" data-dismiss="modal"
            aria-label="Close"></button>
        </div>
      </div>
    </div>

    <section class="fixed-margin-top check mt-0 row  ">
      <form class="trekking-form" (submit)="submit()" [formGroup]="enquiryForm">
        <div class="row mx-1 mx-md-2">
          <div class="form-group mt-3">
            <input class="form-control  inputbox" placeholder="NAME *" formControlName="name" type="text"
              [ngClass]="{ 'is-invalid': isSubmitting && f['name'].errors }">
            <div *ngIf="isSubmitting && f['name'].errors" class="invalid-feedback">
              <div *ngIf="f['name'].errors['required']">Name is required</div>
            </div>
          </div>
          <div class="form-group mt-3">
            <input class="form-control  inputbox" placeholder="PHONE NUMBER *" formControlName="phone" type="text"
              [ngClass]="{ 'is-invalid': isSubmitting && f['phone'].errors }" maxlength="10"
              oninput="this.value = this.value.replace(/[^0-9 ]/,'')" />
            <div *ngIf="isSubmitting && f['phone'].errors" class="invalid-feedback">
              <div *ngIf="f['phone'].errors['required']">Phone Number is required</div>
              <div *ngIf="f['phone'].errors['invalidPhoneNumber']">Invalid Phone number.</div>
            </div>
          </div>
          <div class="form-group mt-3">
            <input type="email" class="form-control   inputbox" placeholder="EMAIL " formControlName="email">
          </div>
          <div class="form-group mt-3" *ngIf="activityname">
            <input class="form-control  inputbox" type="text" placeholder="ACTIVITY NAME"
              formControlName="activityName">
          </div>
          <div *ngIf="activityType == '0'" class="row pe-0">
            <div class="form-group mt-3 col-6 position-relative">
              <input placeholder="TRAVEL DATE" class="form-control " type="text" id="date-input"  formControlName="travelDate" autocomplete="off">
            </div>
            <div class="form-group mt-3 col-6 pe-0">
              <input class="form-control inputbox" type="text" placeholder="NUMBER OF PEOPLE"
                formControlName="noOfPeople">
            </div>
          </div>

          <div *ngIf="activityType == ''" class="form-group mt-3 ">
            <input class="form-control inputbox" type="text" placeholder="NUMBER OF PEOPLE"
              formControlName="noOfPeople">
          </div>

          <div class="form-group mt-3">
            <!-- <label class=" default-font-family  name labelname" for="">MESSAGE </label> -->
            <textarea class="form-control inputbox" id="exampleFormControlTextarea1" [rows]="activityname ? 2 : 3"
              style="resize: none;" placeholder="MESSAGE" formControlName="message"></textarea>

          </div>
          <div class="my-2">
            <button type="submit" class="btn text-light px-sm-5 mt-2  py-2 fw-bold"
              style="background: #3EBDC6; font-size: 15px;">
              SUBMIT
            </button>
          </div>
        </div>
      </form>


    </section>
  </div>
</div>