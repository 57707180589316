<button type="button" class="btn-close mt-3 ms-3" (click)="closeMerchantAgreementDialog()" aria-label="Close"></button>

<h1 mat-dialog-title class="text-center">
  Legal Consent Form
</h1>
<div  mat-dialog-content class="overflow-auto">
  <div class="content mx-sm-5 mx-2 ">
    <h3>Advenzone Adventure Company</h3>
    <h3>Adventure Activities Consent Agreement </h3>
    <p class="description">This Adventure Activities Consent Agreement (the "Agreement") is entered into between
      Advenzone Adventure Company, hereinafter referred to as the "Company," and {{vendorName ? vendorName : "[Vendor Name]"}}, hereinafter referred to as the
      "Vendor," collectively referred to as the "Parties."
    </p>
    <p>By participating in any adventure activities facilitated through the Company's website, the Vendor acknowledges
      and agrees to the terms and conditions outlined in this Agreement.</p>
    <h3>Scope of Agreement</h3>
    <ul>
      <li>The Vendor agrees to provide adventure activities, as described on the Company's website, to Users who have
        booked these activities through the Company's platform.</li>
      <li>The Company acts solely as an intermediary, facilitating the booking process and collecting payments on behalf
        of the Vendor. The Company is not responsible for the provision of adventure activities or any liability arising
        from the activities</li>
    </ul>
    <h3>Vendor Responsibilities </h3>
    <ul>
      <li>The Vendor represents and warrants that they are duly authorized and possess all necessary licenses, permits,
        and certifications to offer and conduct adventure activities in compliance with applicable laws and regulations.
      </li>
      <li>The Vendor shall ensure that all adventure activities are conducted in a safe and professional manner,
        adhering to industry best practices and safety standards.
      </li>
      <li>The Vendor shall provide qualified and trained personnel to lead and supervise the adventure activities,
        ensuring the safety and well-being of the participants.
      </li>
      <li>The Vendor shall maintain appropriate insurance coverage for liability, accidents, and damages arising from
        the adventure activities.
      </li>
    </ul>
    <h3>Indemnification</h3>
    <ul>
      <li>
        The Vendor agrees to indemnify and hold harmless the Company, its officers, directors, employees, and agents
        from and against any claims, damages, losses, liabilities, costs, and expenses (including legal fees) arising
        out of or in connection with the provision of adventure activities by the Vendor.
      </li>
      <li>
        The Vendor acknowledges that the Company assumes no liability for any injuries, accidents, or damages incurred
        during the adventure activities, and that the Vendor is solely responsible for any claims or disputes arising
        from such incidents.
      </li>
    </ul>
    <h3>Confidentiality</h3>
    <ul>
      <li>The Parties agree to maintain the confidentiality of any confidential information disclosed by either Party
        during the course of their business relationship. This includes, but is not limited to, trade secrets, customer
        data, marketing strategies, and any proprietary information.
      </li>
      <li>The Parties shall not disclose or use any confidential information for purposes other than those necessary for
        the fulfillment of this Agreement, unless otherwise agreed in writing.</li>
    </ul>
    <h3> Term and Termination </h3>
    <ul>
      <li>This Agreement shall commence on the effective date and remain in effect until terminated by either Party with
        written notice to the other Party.
      </li>
      <li>Termination of this Agreement shall not relieve the Parties of their obligations accrued prior to the
        termination.</li>
    </ul>
    <h3>Governing Law and Jurisdiction</h3>
    <ul>
      <li>
        This Agreement shall be governed by and construed in accordance with the laws of [Jurisdiction].
      </li>
      <li>Any disputes arising out of or in connection with this Agreement shall be resolved through amicable
        negotiations between the Parties. If a resolution cannot be reached, the dispute shall be subject to the
        exclusive jurisdiction of the courts located in [Jurisdiction].
      </li>
    </ul>
    <p>By signing below, the Parties acknowledge that they have read, understood, and agreed to the terms and conditions
      of this Adventure Activities Consent Agreement.
    </p>
    <div>&nbsp;</div>
    <h3>Advenzone Adventure Company: </h3>
    <h3>Authorized Signature: <u>Advenzone</u></h3>
    <h3>Printed Name: <u>Advenzone</u> </h3>
    <h3>Date: <u>{{ currentDate }}</u> </h3>
    <div>&nbsp;</div>
    <h3>{{vendorName ? vendorName : "[Vendor Name]"}}:</h3>
    <h3>Authorized Signature: <u>{{vendorName ? vendorName : ""}} </u></h3>
    <h3>Printed Name: <u>{{vendorName ? vendorName : ""}} </u></h3>
    <h3>Date: <u>{{ currentDate }}</u></h3>
  </div>
</div>