import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { MerchantSignupComponent } from 'src/app/auth/merchant-signup/merchant-signup.component';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { ForgetPasswordPopupComponent } from 'src/app/forget-password-popup/forget-password-popup.component';

@Component({
  selector: 'app-merchant-login-dialogue',
  templateUrl: './merchant-login-dialogue.component.html',
  styleUrls: ['./merchant-login-dialogue.component.css']
})
export class MerchantLoginDialogueComponent {
  public passwordVisible: boolean = false;
  isMerchantLoggedIn: boolean = false;
  isMerchantSubmitting: boolean = false;
  isSubmitting: boolean = false;
  merchantLoginForm: any;
  userAgent:string= "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public merchantLoginDialogRef: MatDialogRef<MerchantLoginDialogueComponent>, private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, public loginDialogRef: MatDialogRef<LoginDialogComponent>, public dialog: MatDialog, private authService: AuthService, private snackBar: MatSnackBar) {
    var raftingDetails = localStorage.getItem('raftingDetails');
    var campingDetails = localStorage.getItem('campingDetails');
    var rentalDetails = localStorage.getItem('rentalDetails');
    var locationDetails = localStorage.getItem('location');
    var linkFrom = localStorage.getItem('linkFrom');
    var appToken = localStorage.getItem('appToken');
    var trekkingDetails = localStorage.getItem('trekkingDetails');
    var tourDetails = localStorage.getItem('tourDetails');
    localStorage.clear();
    if(raftingDetails && raftingDetails != ""){
      localStorage.setItem('raftingDetails',raftingDetails);
      }
    if(campingDetails && campingDetails != ""){
    localStorage.setItem('campingDetails',campingDetails);
    }
    if(locationDetails && locationDetails != ""){
      localStorage.setItem('location',locationDetails);
    }
    if(trekkingDetails && trekkingDetails != ""){
      localStorage.setItem('trekkingDetails',trekkingDetails);
    }
    if(rentalDetails && rentalDetails != ""){
      localStorage.setItem('rentalDetails',rentalDetails);
    }
    if(linkFrom && linkFrom != ""){
      localStorage.setItem('linkFrom',linkFrom);
    }
    if(tourDetails && tourDetails != ""){
      localStorage.setItem('tourDetails',tourDetails);
    }
      localStorage.setItem('appToken', appToken);
  }

  ngOnInit() {
    this.userAgent = window.navigator.userAgent;
    this.merchantLoginForm = this.formBuilder.group({ email: new FormControl('', Validators.required), password: new FormControl('', Validators.required) });

  }

  get f(): { [key: string]: AbstractControl } {
    return this.merchantLoginForm.controls;
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  closeMerchantDialogue() {
    this.merchantLoginDialogRef.close();
  }

  onMerchantLogin() {
    this.isSubmitting = true;
    if (this.merchantLoginForm.invalid) {
      return;
    }
    this.authService.merchantlogin(this.merchantLoginForm.value).subscribe({
      next: (res) => {
        this.isSubmitting = false;
        this.merchantLoginDialogRef.close();
        this.router.navigate(['/full-merchant-profile']);
        this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' },);
      },
      error: (err) => {
        this.isSubmitting = false;
        this.snackBar.open('Email/Phone Number or Password is Incorrect ', 'close', { duration: 3000, verticalPosition: 'top' },);

      }
    });
  }

  openGuideSignUpDialog() {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '90vh',
      width: '50%',
      maxWidth: 'unset',
      panelClass: 'bg-merchant-signup-class'
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
        panelClass: 'bg-merchant-signup-class'
      }
    }
    this.merchantLoginDialogRef.close();
    this.dialog.open(MerchantSignupComponent, {
      ...sizes,
      disableClose: true,
      data: {

      },
    });
  }

  
  ifMobileApp(){
    if(this.userAgent.includes('AdvenzoneMobileApp')){
      return true;
    }else{
      return false;
    }
  }

  openForgetPasswordDialog() {
    this.loginDialogRef.close();
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: 'fit-content',
      width: '50%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      }
    }
    this.dialog.open(ForgetPasswordPopupComponent, {
      ...sizes,
      disableClose: true,
      data: {
        type: "merchant"
      },
    });
  }

}
