<!-- <button type="button" class="btn-close mt-2 ms-2" (click)="closeLoginDialog()" aria-label="Close"></button> -->
<div mat-dialog-content class="overflow-hidden">
  <h1 mat-dialog-title id="form-login" class="text-center">Login to AdvenZone</h1>

  <form class="h-100" [formGroup]="verificationForm">
    <div class="otp-input-container">
      <input type="number" maxlength="1" formControlName="firstNum" >
      <input type="number" maxlength="1" formControlName="secondNum" >
      <input type="number" maxlength="1" formControlName="thirdNum" >
      <input type="number" maxlength="1" formControlName="fourNum" >
    </div>


    <div class="text-center mt-4" style="display: grid;">
      <button type="submit" class="btn  btn-color mx-auto mt-2  text-center" (click)="onSignIn()">Submit</button>
      <button class="btn btn-color  mx-auto mt-2  text-center" (click)="resendotp()">Resend code</button>
    </div>
  </form>
</div>