import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Renderer2, HostListener, ViewChild, AfterViewInit, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, NavigationEnd, RouteReuseStrategy } from '@angular/router';
import { AppService } from '../app.service';
import { DataService } from '../data.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth/auth.service';
import { LoginDialogComponent } from '../auth/login-dialog/login-dialog.component';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormBuilder, FormGroup, AbstractControl, Validators, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InfoComponent } from '../info/info.component';
import * as mapboxgl from 'mapbox-gl';
import { CouponService } from '../coupon.service';
import { FormatDateRangeService } from '../services/format-date-range.service';
import { DateConversionService } from '../services/date-conversion.service';
import { EnquiryFormComponent } from '../enquiry-form/enquiry-form.component';
import { DomSanitizer } from '@angular/platform-browser';


interface dataCount {
  rafting: any;
  topadventurists: any;
  camping: any;
  bikerental: any;
  trekking: any;
  tour: any;
  quickactivities: any
}

declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [NgbCarouselConfig]
})

export class HomeComponent implements AfterViewInit, OnInit {

  @ViewChild('child0', { static: true }) divElement!: ElementRef;
  @ViewChild('filteredBikeDiv') filteredBikeDiv: ElementRef;
  dataFromPage:string ="homePage";
  dataPage:string ="landingPage";
  categoryData:string="bikerental"
  map!: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v12';
  searchKey: string = '';
  raftingData: any = [];
  activityData: any = [];
  bikeRentalData: any = [];
  campingData: any = [];
  trekkingData: any = [];
  bikeTourData: any = [];
  topAdventuristsData: any = [];
  quickActivitiesData: any = [];
  raftingLoadMore: boolean = false;
  trekkingLoadMore: boolean = false;
  activityLoadMore: boolean = false;
  bikeRentalLoadMore: boolean = false;
  bikeTourLoadMore: boolean = false;
  campingLoadMore: boolean = false;
  topAdventuristsLoadMore: boolean = false;
  quickActivitiesLoadMore: boolean = false;
  filteredQuickActivitiesLoadMore: boolean = false;
  raftingPage: number = 1;
  bikeRentalPage: number = 1;
  campingPage: number = 1;
  topAdventuristsPage: number = 1;
  trekkingPage: number = 1;
  bikeTourPage: number = 1;
  activityPage: number = 1;
  viewCount: number = 4;
  mouseDown = false;
  startX: any;
  startY: any;
  scrollLeft: any;
  slider = document.querySelector<HTMLElement>('.parent');
  userLocation = { latitude: 0, longitude: 0 };
  isDragging = false;
  locationName: any;
  cursorDown = { x: 0, y: 0 };
  cursorUp = { x: 0, y: 0 };
  userId: string = "";
  userAgent: string = "";
  active_category: string = "";
  showBackToTop = false;
  nonFiteredCampingData: any = [];
  raftingLoading: boolean = true;
  trekkingLoading: boolean = true;
  bikeTourLoading: boolean = true;
  rentalLoading: boolean = true;
  campingLoading: boolean = true;
  adventuristsLoading: boolean = true;
  quickActivitiesLoading: boolean = true;
  filteredQuickActivitiesLoading: boolean = true;
  raftingForm!: FormGroup;
  isSubmittingRafting: boolean = false;
  trekkingForm!: FormGroup;
  isSubmittingTrekking: boolean = false;
  biketourForm!: FormGroup;
  isSubmittingBikeTour: boolean = false;
  rentalForm!: FormGroup;
  isSubmittingBikerental: boolean = false;
  campingForm!: FormGroup;
  isSubmittingCamping: boolean = false;
  activitiesForm!: FormGroup;
  isSubmittingActivities: boolean = false;
  currentDate: string | undefined;
  currentDate1: string | undefined;
  dropDate: string | undefined;
  startDate: string = '';
  variationid: any = [];
  variationValueId: any = [];
  variations: any = [];
  variationValues: any = [];
  timings:any = ["12:00 am","1:00 am","2:00 am","3:00 am","4:00 am","5:00 am","6:00 am", "7:00 am", "8:00 am", "9:00 am", "10:00 am", "11:00 am", "12:00 pm", "1:00 pm", "2:00 pm", "3:00 pm", "4:00 pm", "5:00 pm", "6:00 pm", "7:00 pm", "8:00 pm", "9:00 pm", "10:00 pm","11:00 pm"];
  newTimings: any = [];
  adultsCount: number = 1;
  childrenCount: number = 0;
  totalGuest: number = 1;
  inventoryVariations: any = [];
  inventoryVariationValues: any = [];
  inventoryVariationValueIds: any = [];
  locationId: any = null;
  sort: any = null;
  sortColumn: any = null;
  filteredRentalMerchants: any = [];
  filterRentalMerchantsloading: boolean = false;
  showFilteredRentalMerchants: boolean = false;
  showFilteredBikes: boolean = false;
  loadingFilteredBikes: boolean = false;
  showFilteredRafting: boolean = false;
  providedByRafting: any[] = [];
  loadingprovidedByRafting: boolean = false;
  nonFilteredBikeCard: any[] = [];
  bikeCard: any[] = [];
  filteredBikeCard: any;
  selectedRentalMerchant: any = {};
  viewSearchedCamping: boolean = false;
  loadSearchCampingData: boolean = false;
  searchCampingData: any = [];
  filteredRaftingData: any = [];
  filteredInventoryMerchants: any = [];
  indexLoading: boolean = true;
  hasCoupon: boolean = false;
  couponPercentage: any = "";
  numberofdays: any;
  totalaltitude: any;
  allSlots: any = [];
  slots: any = [];
  months: any = [];
  loadingMonths:boolean = false;
  visibleFilteredTrekking: boolean = false;
  filterTrekkingData: any = [];
  onBodyScrollBound: any;
  visibleFilteredTour: boolean = false;
  filteredTourData: any = [];
  landingPageData: any = [];
  nonFiteredLandingData: any = [];
  landingPageImages: any = [];
  landingPageLoading: boolean = false;
  landingPageLoadMore: boolean = false;
  landingPage: number = 1;
  showDateField: boolean = false;
  mySubscription: any;
  showShadow: boolean = false;
  showSize: boolean = true;
  quickActivityPage: number = 1;
  filteredQuickActivityPage: number = 1;
  showFilteredActivities: boolean = false;
  filteredActivitiesData: any = [];
  activitytypeid: any;
  quickActivityTypes: any = [];
  additionalInfo: any = {};
  selectedSortBy: any = "Rating - high to low";
  couponsData: any = [];
  iscoupon: boolean = false;
  rentalOfferAvailable:boolean = false;
  locationname:any;
  sanitizedDescription:any;
  latitude: any;
  longitude: any;
  showBikeFilter:boolean = false;

  public indexData: dataCount = {
    rafting: 0,
    topadventurists: 0,
    camping: 0,
    bikerental: 0,
    trekking: 0,
    tour: 0,
    quickactivities: 0
  }
  
  constructor(public dialog: MatDialog, private auth: AuthService, private datePipe: DatePipe, config: NgbCarouselConfig, private elementRef: ElementRef, private formatDateRangeService: FormatDateRangeService, private dateConversionService: DateConversionService, private renderer: Renderer2, private route: ActivatedRoute, private http: HttpClient, private router: Router, private formBuilder: FormBuilder, private appService: AppService, private dataService: DataService, private routeReuseStrategy: RouteReuseStrategy, private couponService: CouponService, private snackBar: MatSnackBar, private cdr: ChangeDetectorRef,private sanitizer:DomSanitizer,) {
    // config.interval = 2000;
    // config.keyboard = true;
    // config.pauseOnHover = true;
    this.auth.userInfo.subscribe(info => {
      if (info !== null) {
        this.userId = info.id;
      }
    })
    this.auth.UserType.subscribe(type => {
      if (type === "merchant") {
        this.router.navigate(['/full-merchant-profile']);
      }
    })
    this.auth.UserType.subscribe(type => {
      if (type === "partner") {
        this.router.navigate(['/partnerdashboard']);
      }
    })
    this.appService.userLocation.subscribe(data => {
      this.userLocation = data;
      // if (data.latitude !== 0 && data.longitude !== 0) {
      //   this.http.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${data.longitude},${data.latitude}.json?access_token=${environment.mapbox.accessToken}`).subscribe((data: any) => {
      //     let loc = data?.features[1]?.place_name?.split(',');
      //     this.locationName = loc[loc.length - 1];
      //   });
      // }
    })
    // this.onBodyScrollBound = this.onBodyScroll.bind(this);
    // document.body.addEventListener('scroll', this.onBodyScrollBound);

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
    this.selectedSortBy = localStorage.getItem('selectedSortBy') || 'Rating - high to low';;
  }

  preventToggle(event: MouseEvent) {
    event.stopPropagation();
  }
  preventToggleDefault(event: MouseEvent): void {
    event.preventDefault();
  }
  toggleDateField() {

    this.showDateField = !this.showDateField
    this.showShadow = this.showDateField;
    this.showSize = !this.showDateField;
    // }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (this.elementRef.nativeElement.contains(event.target)) {

      this.showShadow = this.showDateField;
      this.showSize = !this.showDateField;
      // this.cdr.detectChanges();
      // this.showDateField = false;
      // this.showShadow =false; 
      // this.showSize =true; 

    }
  }

  ngOnInit() {
    let location = localStorage.getItem('location');
    let linkFrom = localStorage.getItem('linkFrom');
    if (location != null && location != undefined && location != "") {
      this.landingPage = 1;
      this.landingPageLoadMore = false;
      this.locationId = JSON.parse(location).id;
      this.latitude =JSON.parse(location).latitude;
      this.longitude =JSON.parse(location).longitude;
    }
    let url = window.location.pathname;
    if(url.includes('home/location')){
          const locationid = this.route.snapshot.paramMap.get('locationid');
          const locationName = this.route.snapshot.paramMap.get('locationname');
          if(this.locationId != locationid){
            this.http.get(`${environment.apiUrl}/locations/get?id=${locationid}`).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                if(data.location && data.location.status == 1){
                  this.locationId == data.location.id;
                  localStorage.setItem('location', JSON.stringify(data.location));
                  this.dataService.changelocationSource(JSON.stringify(data.location));
                  if(locationName !== data.location.locationname){
                    const newUrl = `/home/location/${this.locationId}/${data.location.locationname.trimEnd().toLowerCase().replaceAll(" ", "-")}`;
                    this.router.navigate([newUrl],{ replaceUrl: true});
                  }
                }else{
                  this.router.navigate(['/service-unavailable']);
                }
              }
            })
          }else{
            this.http.get(`${environment.apiUrl}/locations/get?id=${locationid}`).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                if(!data.location || data.location.status != 1){
                  this.router.navigate(['/service-unavailable']);
                }
              }
            })
          }
    }
    this.dataService.currentLocationSource.subscribe((location) => {
      if (location != null && location != "") {
        this.landingPage = 1;
        this.landingPageLoadMore = false;
        this.locationId = JSON.parse(location).id;
      }
      this.raftingData = [];
      this.bikeRentalData = [];
      this.campingData = [];
      this.trekkingData = [];
      this.bikeTourData = [];
      this.topAdventuristsData = [];
      this.quickActivitiesData = [];
      this.visibleFilteredTrekking = false;
      this.filterTrekkingData = [];
      this.visibleFilteredTour = false;
      this.filteredTourData = [];
      // this.showPhase('rafting');
      this.onSearch();
    })
    this.userAgent = window.navigator.userAgent;
    // this.FormRafting();
    this.currentDate = this.getCurrentDate();
    this.dropDate = this.getCurrentDate();
    this.currentDate1 = this.getNextDate();
    this.startDate = this.getCurrentDate();

 
  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.addOrRemoveClassBasedOnWidth();
  }

  ngAfterViewInit() {
    this.addOrRemoveClassBasedOnWidth();
    setTimeout(()=>{
    if (typeof $ !== 'undefined' && $.fn.datepicker) {
      // Use jQuery Datepicker
      $('.datepicker').datepicker({
        minDate: new Date(this.startDate),
        dateFormat: 'yy-mm-dd',
        changeMonth: true,
        changeYear: true,
        onSelect: (dateText: any) => {
          this.currentDate = dateText;
        }
      });
      $('.datepicker1').datepicker({
        minDate: new Date(this.startDate),
        dateFormat: 'yy-mm-dd',
        changeMonth: true,
        changeYear: true,
        onSelect: (dateText: any) => {
          this.dropDate = dateText;
        }
      });

      let content_this = this;
        $(".datepicker").focus(function() {
          let month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
          let year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
          month = Number(month)+1;
          $(".ui-datepicker-calendar .ui-state-default").each(function() {
            if($(this).parent().hasClass('ui-state-disabled')){
              $(this).addClass('text-center');
              $(this).css('background','#FFFFFF');
              return
            }else if(!$(this).hasClass('data-taken')){
              $(this).addClass('data-taken');
              $(this).addClass('text-center');
              $(this).css('background','#FFFFFF');
              let day= $(this).attr('data-date');
                let date = `${year}-${(month.length == 1)? '0'+month : month}-${(day.length == 1)? '0'+day : day}`;
                $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center;'><img src='/assets/loader.svg' width=25px alt='loader' /></span>");
                if(content_this.active_category == "rafting"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=rafting&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "bike-rental"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=bikerental&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "camping"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=camping&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "tour"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=tour&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "trekking"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=trekking&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "quickactivities"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=activity&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
            }

          });
        });
        $(".datepicker1").focus(function() {
          let month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
          let year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
          month = Number(month)+1;
          $(".ui-datepicker-calendar .ui-state-default").each(function() {
            if($(this).parent().hasClass('ui-state-disabled')){
              $(this).addClass('text-center');
              return
            }else if(!$(this).hasClass('data-taken')){
              $(this).addClass('data-taken');
              $(this).addClass('text-center');
              $(this).css('background','#FFFFFF');
              let day= $(this).attr('data-date');
                let date = `${year}-${(month.length == 1)? '0'+month : month}-${(day.length == 1)? '0'+day : day}`;
                if(content_this.active_category == "bike-rental"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=bikerental&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "camping"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=camping&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
            }
          });
        });
    } else {
      console.error('jQuery or jQuery UI Datepicker not available');
    }
  },500)
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    this.showBackToTop = window.scrollY >= 100;
    const scrollY = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;

    const offsetHeight = document.documentElement.offsetHeight || document.body.offsetHeight;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    if (scrollHeight - scrollY < 1500) {
      if (this.active_category == "landing-page" && this.landingPageLoadMore && !this.landingPageLoading) {
        this.loadMoreLandingPage('showmore', this.selectedSortBy)

      } else if (this.active_category == "rafting" && this.raftingLoadMore && !this.raftingLoading) {
        this.loadMoreRafting();
      } else if (this.active_category == "camping" && this.campingLoadMore && !this.campingLoading) {
        this.loadMoreCampings();
      } else if (this.active_category == "topAdventurists" && this.topAdventuristsLoadMore && !this.adventuristsLoading) {
        this.loadMoreAdventurists();
      } else if (this.active_category == "trekking" && this.trekkingLoadMore && !this.trekkingLoading) {
        this.loadMoreTrekkings();
      } else if (this.active_category == "tour" && this.bikeTourLoadMore && !this.bikeTourLoading) {
        this.loadMoreBiketour();
      } else if (this.active_category == "quickactivities" && !this.showFilteredActivities && this.quickActivitiesLoadMore && !this.quickActivitiesLoading) {
        this.loadMoreQuickActivities();
      } else if (this.active_category == "quickactivities" && this.showFilteredActivities && this.filteredQuickActivitiesLoadMore && !this.filteredQuickActivitiesLoading) {
        this.loadMoreFilteredQuickActivities();
      }

    }
  }


  private addOrRemoveClassBasedOnWidth() {
    this.renderer.removeClass(this.divElement.nativeElement, 'w-100');
    const divWidth = this.divElement.nativeElement.offsetWidth;
    const deviceWidth = window.innerWidth;
    if (divWidth < deviceWidth) {
      // Add the class when the device width is greater than the div width
      this.renderer.addClass(this.divElement.nativeElement, 'w-100');
    } else {
      // Remove the class when the device width is not greater than the div width
      this.renderer.removeClass(this.divElement.nativeElement, 'w-100');
    }
  }

  startDragging(e: any, flag: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, type: string, child: any, spot: string) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.cursorDown.x = e.pageX;
    this.cursorDown.y = e.pageY;
    if (type === 'mouse') {
      this.startX = e.pageX - el.offsetLeft;
    } else {
      // this.startX = e.touches[0].pageX - el.offsetLeft;
      // this.startY = e.touches[0].pageY - el.offsetTop;
    }



    this.scrollLeft = el.scrollLeft;
    // setTimeout(() => { this.isDragging = true }, 300);
  }

  stopDragging(e: any, flag: any, type: string) {
    this.cursorUp.x = e.pageX;
    this.cursorUp.y = e.pageY;
    // setTimeout(() => { this.isDragging = false });
    this.mouseDown = false;
  }

  moveEvent(e: any, el: { offsetLeft: number; scrollLeft: number; offsetTop: number }, type: string, child: any) {
    // e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    let x = 0, y = 0;
    if (type === 'mouse') {
      x = e.pageX - el.offsetLeft;
    } else {
      // x = e.touches[0].pageX - el.offsetLeft;
      // y = e.touches[0].pageY - el.offsetTop;
    }
    const scrollX = x - this.startX;
    const scrollY = y - this.startY;
    if (Math.abs(scrollY) > Math.abs(scrollX)) {
      document.body.scrollTo(window.scrollX, window.scrollY - scrollY);
    } else {
      el.scrollLeft = this.scrollLeft - scrollX;
    }
  }

  onScroll(e: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, child: any, spot: string) {
    if ((child.clientWidth - el.scrollLeft) <= el.clientWidth + 1) {
      if (spot === 'rafting' && this.raftingLoadMore) {
        this.loadMoreRafting()
      }
      if (spot === 'trekking' && this.trekkingLoadMore) {
        this.loadMoreTrekkings()
      }
      if (spot === 'activity' && this.activityLoadMore) {
        let filter: any = { count: this.viewCount, location: this.location, page: this.activityPage, status: 1 };
        if (this.locationId != null) {
          filter.locationId = this.locationId;
        }
        this.http.post(environment.apiUrl + `/activities/list`, filter, { responseType: 'json' }).subscribe(async (data: any) => {
          {
            if (data.status === "SUCCESS") {
              const loadPromises = data.activities.map((activity: any) => this.loadImages('activity', activity.id));
              const loadRating = data.activities.map((activity: any) => this.loadRating('activity', activity.id));
              const loadPriceStartsFrom = data.activities.map((activity: any) => this.loadPriceStartsFrom(activity.id));
              const results = await Promise.all(loadPromises);
              const rating = await Promise.all(loadRating);
              const priceStartsFrom = await Promise.all(loadPriceStartsFrom);
              data.activities.forEach((activity: any, index: number) => {
                activity.src = results[index];
                activity.avgReview = rating[index].average;
                activity.total = rating[index].total;
                activity.priceFrom = priceStartsFrom[index];
              });
              this.activityData.push(...data.activities);
              if (Number(data.count) > this.activityData.length) {
                this.activityLoadMore = true;
                this.activityPage += 1;
              } else {
                this.activityLoadMore = false;
              }
            }
          }
        });
      }
      if (spot === 'camping' && this.campingLoadMore) {
        this.loadMoreCampings();
      }
      if (spot === 'bike-rental' && this.bikeRentalLoadMore) {
        this.loadMoreBikeRentals();
      }
      if (spot === 'merchant' && this.topAdventuristsLoadMore) {
        this.loadMoreAdventurists();
      }
    }
  }


  ngOnDestroy() {
    // document.body.removeEventListener('scroll', this.onBodyScrollBound);
    // if (this.mySubscription) {
    //   this.mySubscription.unsubscribe();
    // }
  }

  onBodyScroll() {
    const scrollPosition = document.body.scrollTop;
    const offsetHeight = document.documentElement.offsetHeight || document.body.offsetHeight;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    if (scrollHeight < scrollPosition) {
      if (this.active_category == "landing-page" && this.landingPageLoadMore && !this.landingPageLoading) {
        this.loadMoreLandingPage('showmore', this.selectedSortBy)
      } else if (this.active_category == "rafting" && this.raftingLoadMore && !this.raftingLoading) {
        this.loadMoreRafting();
      } else if (this.active_category == "camping" && this.campingLoadMore && !this.campingLoading) {
        this.loadMoreCampings();
      } else if (this.active_category == "topAdventurists" && this.topAdventuristsLoadMore && !this.adventuristsLoading) {
        this.loadMoreAdventurists();
      } else if (this.active_category == "trekking" && this.trekkingLoadMore && !this.trekkingLoading) {
        this.loadMoreTrekkings();
      } else if (this.active_category == "tour" && this.bikeTourLoadMore && !this.bikeTourLoading) {
        this.loadMoreBiketour();
      } else if (this.active_category == "quickactivities" && !this.showFilteredActivities && this.quickActivitiesLoadMore && !this.quickActivitiesLoading) {
        this.loadMoreQuickActivities();
      } else if (this.active_category == "quickactivities" && this.showFilteredActivities && this.filteredQuickActivitiesLoadMore && !this.filteredQuickActivitiesLoading) {
        this.loadMoreFilteredQuickActivities();
      }
    }
  }

  async onSearch() {
    let filter: any = { count: this.viewCount, location: this.location, page: 1, status: 1 };
    if (this.locationId != null) {
      filter.locationId = this.locationId;
    }
    let indexCount: any = await this.http.post(environment.apiUrl + `/activities/indexcount`, { ...filter }, { responseType: 'json' }).toPromise();
    if (indexCount.status === "SUCCESS") {
      this.indexLoading = false;
      this.indexData.rafting = indexCount.rafting;
      this.indexData.camping = indexCount.camping;
      this.indexData.trekking = indexCount.trekking;
      this.indexData.topadventurists = indexCount.topAdventurists;
      this.indexData.bikerental = indexCount.bikeRentals;
      this.indexData.tour = indexCount.tour;
      this.indexData.quickactivities = indexCount.quickactivities;

      if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
        let userData = JSON.parse(sessionStorage.getItem('userData'));

        if (userData.category == 'rafting') {
          this.showPhase('rafting')
        }
        if (userData.category == 'bike-rental') {
          this.showPhase('bike-rental')
        }
        if (userData.category == 'trekking') {
          this.showPhase('trekking')
        }
        if (userData.category == 'camping') {
          this.showPhase('camping')
        }
        if (userData.category == 'tour') {
          this.showPhase('tour')
        }
        if (userData.category == 'topAdventurists') {
          this.showPhase('topAdventurists')
        }
        if (userData.category == 'activity') {
          this.showPhase('quickactivities')
        }
      } else {
        this.showPhase('landing-page')
        // if (this.indexData.rafting > 0) {
        //   this.showPhase('rafting')
        // } else 
        // if (this.indexData.bikerental > 0) {
        //   this.showPhase('bike-rental')
        // } else if (this.indexData.camping > 0) {
        //   this.showPhase('camping')
        // }
        // else if (this.indexData.trekking > 0) {
        //   this.showPhase('trekking')
        // }
        // else if (this.indexData.tour > 0) {
        //   this.showPhase('tour')
        // }
        // else {
        //   this.showPhase('topAdventurists');
        // }
      }

      setTimeout(() => {
        this.addOrRemoveClassBasedOnWidth();
      }, 300)
    }
  };


  getCurrentDate(): string {
    return this.datePipe.transform(new Date(), 'yyyy-MM-dd')!;
  }
  getNextDate(): string {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1); // Adding 1 day to the current date
    return this.datePipe.transform(currentDate, 'yyyy-MM-dd')!;
  }


  FormRafting() {
    this.isSubmittingRafting = false;
    this.newTimings = [];
    this.newTimings.push(...this.timings);
    this.raftingForm = this.formBuilder.group({
      activityId: new FormControl(''),
      date: new FormControl('', Validators.required),
      time: new FormControl('', Validators.required),
      distance: new FormControl('16 Km', Validators.required),
      guest: new FormControl('1', [Validators.required, Validators.pattern(/^[1-9]*$/)]),
    });
    this.currentDate = this.getCurrentDate();
    this.raftingForm.get('date').setValue(this.currentDate);
    try {
      this.http.get(environment.apiUrl + `/range/minpricebycategory?category=rafting&date=${this.currentDate}${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe((data: any) => {
        if (data.status === "SUCCESS") {
          if(Number(data.min_price) == 0){
            this.currentDate = "";
            this.raftingForm.get('date').setValue(this.currentDate);
          }
        }
      });
    } catch (error) {
      console.log(error)
    }
    this.raftingPage = 1;
    this.raftingData = [];
    this.loadMoreRafting();
    this.onChangeRaftingValues();
    if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
      let userData = JSON.parse(sessionStorage.getItem('userData'));
      if (userData.category === 'rafting') {
        this.raftingForm.setValue({
          activityId: userData.value.activityId,
          date: userData.value.date,
          time: userData.value.time,
          distance: userData.value.distance,
          guest: userData.value.guest,
        });
        if (userData.isSubmittingRafting) {
          this.searchRaftingMerchants();
        }
        this.showDateField = true;
        this.showShadow = true;
      } else {
        sessionStorage.setItem('userData', JSON.stringify({ category: 'rafting', value: this.raftingForm.value }))
      }

    } else {
      sessionStorage.setItem('userData', JSON.stringify({ category: 'rafting', value: this.raftingForm.value }))
    }
  }

  FormTrekking() {
    // this.showDateField = true;
    // this.showShadow = this.showDateField
    this.isSubmittingTrekking = false;
    this.visibleFilteredTrekking = false;
    this.trekkingForm = this.formBuilder.group({
      month: new FormControl(''),
      slot: new FormControl('', Validators.required),
    });
    this.loadingMonths = true;
    this.http.get(environment.apiUrl + `/merchantactivities/packagetourslots?category=trekking${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        let startDate = data.slots.map(x => x.split(' - ')[0]);
        data.slots = data.slots.map(x => this.formatDateRangeService.formatDateRange(x));
        
        data.slots.sort((a, b) => {
          const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          const partsA = a.split(' ');
          const dayA = parseInt(partsA[0].replace(/\D/g, ''), 10);
          const monthA = partsA[1];
          const partsB = b.split(' ');
          const dayB = parseInt(partsB[0].replace(/\D/g, ''), 10);
          const monthB = partsB[1];
          if (months.indexOf(monthA) === months.indexOf(monthB)) {
            return dayA - dayB;
          }
          return months.indexOf(monthA) - months.indexOf(monthB);
        });
        
        const months = data.slots.map(dateRange => {
          const month = dateRange.split(' ')[1];
          return month;
        });
        this.months = [...new Set(months)];
          this.months.sort((a, b) => {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            return months.indexOf(a) - months.indexOf(b);
          });
        this.loadingMonths = false;
        data.slots.forEach(async(x:any, i)=>{
          try {
            this.http.get(environment.apiUrl + `/range/minpricebycategory?category=trekking&date=${startDate[i]}${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe((res: any) => {
              if (res.status === "SUCCESS") {
                data.slots[i]= x+ ' ₹'+ res.min_price;
              }
            });
          } catch (error) {
            console.log(error)
          }
        })
        this.allSlots = data.slots;
        this.slots = data.slots;
        if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
          let userData = JSON.parse(sessionStorage.getItem('userData'));
          if (userData.category === 'trekking') {
            setTimeout(() => {
              this.trekkingForm.setValue({
                month: userData.value.month,
                slot: userData.value.slot
              });
              if (userData.isSubmittingTrekking) {
                this.submitTrekking();
              }
            })
            this.showDateField = true;
            this.showShadow = true;
          } else {
            sessionStorage.setItem('userData', JSON.stringify({ category: 'trekking', value: this.trekkingForm.value }))
          }

        } else {
          sessionStorage.setItem('userData', JSON.stringify({ category: 'trekking', value: this.trekkingForm.value }))
        }
      }
    })
    this.trekkingData = [];
    this.trekkingLoading = true;
    this.trekkingPage = 1;
    this.loadMoreTrekkings();
  }

  FormTour() {
    // this.showDateField = true;
    // this.showShadow = this.showDateField
    this.isSubmittingBikeTour = false;
    this.visibleFilteredTour = false;
    this.biketourForm = this.formBuilder.group({
      month: new FormControl(''),
      slot: new FormControl('', Validators.required),
      // variations: this.formBuilder.array([])
    });
    this.loadingMonths = true;
    this.http.get(environment.apiUrl + `/merchantactivities/packagetourslots?category=tour${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        let startDate = data.slots.map(x => x.split(' - ')[0]);
        data.slots = data.slots.map(x => this.formatDateRangeService.formatDateRange(x));
        data.slots.sort((a, b) => {
          const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          const partsA = a.split(' ');
          const dayA = parseInt(partsA[0].replace(/\D/g, ''), 10);
          const monthA = partsA[1];
          const partsB = b.split(' ');
          const dayB = parseInt(partsB[0].replace(/\D/g, ''), 10);
          const monthB = partsB[1];
          if (months.indexOf(monthA) === months.indexOf(monthB)) {
            return dayA - dayB;
          }
          return months.indexOf(monthA) - months.indexOf(monthB);
        });
        const months = data.slots.map(dateRange => {
          const month = dateRange.split(' ')[1];
          return month;
        });
        this.months = [...new Set(months)];
          this.months.sort((a, b) => {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return months.indexOf(a) - months.indexOf(b);
          });
        this.loadingMonths = false;
        data.slots.forEach(async(x:any, i)=>{
          try {
            this.http.get(environment.apiUrl + `/range/minpricebycategory?category=tour&date=${startDate[i]}${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe((res: any) => {
              if (res.status === "SUCCESS") {
                data.slots[i]= x+ ' ₹'+ res.min_price;
              }
            });
          } catch (error) {
            console.log(error)
          }
        })
        this.allSlots = data.slots;
        this.slots = data.slots;
        if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
          let userData = JSON.parse(sessionStorage.getItem('userData'));
          if (userData.category === 'tour') {
            setTimeout(() => {
              this.biketourForm.setValue({
                month: userData.value.month,
                slot: userData.value.slot
              });
              if (userData.isSubmittingBikeTour) {
                this.submitTour();
              }
            })
            this.showDateField = true;
            this.showShadow = true;
          } else {
            sessionStorage.setItem('userData', JSON.stringify({ category: 'tour', value: this.biketourForm.value }))
          }
        } else {
          sessionStorage.setItem('userData', JSON.stringify({ category: 'tour', value: this.biketourForm.value }))
        }
      }
    })
    this.bikeTourData = [];
    this.bikeTourLoading = true;
    this.bikeTourPage = 1;
    this.loadMoreBiketour();
    // this.http.get(environment.apiUrl + `/merchantactivities/activityVariationDetails?category=tour${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe(async (data: any) => {
    //   if (data.status === "SUCCESS") {
    //     data.activityVariationDetails.forEach((x: any) => {
    //       x.merchants.forEach((y: any) => {
    //         if (!this.variationid.includes(y.variationtypeid)) {
    //           this.variationid.push(y.variationtypeid);
    //         }
    //         let index = this.variationid.indexOf(y.variationtypeid);
    //         if (index != -1) {
    //           if (this.variationValueId[index] != undefined) {
    //             (!this.variationValueId[index].includes(y.variationvalueid)) ? this.variationValueId[index].push(y.variationvalueid) : ""
    //           } else {
    //             this.variationValueId[index] = [];
    //             this.variationValueId[index].push(y.variationvalueid)
    //           }
    //         }
    //       })
    //     })
    //     if (this.variationid.length > 0) {
    //       const variationsPromises = this.variationid.map(async (x: any) => {
    //         try {
    //           const data: any = await this.http.get(environment.apiUrl + `/variations/get?id=${x}`).toPromise();
    //           return data;
    //         } catch (error) {
    //           throw error;
    //         }
    //       });

    //       const variationValuePromises = this.variationValueId.map(async (x: any) => {
    //         const variationValuePromisesInner = x.map(async (y: any) => {
    //           try {
    //             const data: any = await this.http.get(environment.apiUrl + `/variationvalues/get?id=${y}`).toPromise();
    //             return data;
    //           } catch (error) {
    //             throw error;
    //           }
    //         });
    //         return await Promise.all(variationValuePromisesInner);
    //       });

    //       try {
    //         this.variations = await Promise.all(variationsPromises);
    //         this.variationValues = await Promise.all(variationValuePromises);
    //         const variationArray = this.biketourForm.get('variations') as FormArray;
    //         this.variations.forEach((x: any) => {
    //           variationArray.push(
    //             this.formBuilder.group({
    //               variationid: new FormControl(x.variations.id),
    //               type: new FormControl(x.variations.type),
    //               variationvalueid: new FormControl('', Validators.required),
    //             }))
    //         })
    //       } catch (error) {
    //         console.error("An error occurred:", error);
    //       }
    //     }

    //   }
    // })
  }

  FormActivities() {
    this.isSubmittingActivities = false;
    this.newTimings = [];
    this.newTimings.push(...this.timings);
    this.activitiesForm = this.formBuilder.group({
      activityId: new FormControl(''),
      activities: new FormControl('', Validators.required),
      date: new FormControl('',),
      time: new FormControl(''),
    });
    this.quickActivityPage = 1;
    this.currentDate = this.getCurrentDate();
    this.activitiesForm.get('date').setValue(this.currentDate);
    try {
      this.http.get(environment.apiUrl + `/range/minpricebycategory?category=activity&date=${this.currentDate}${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe((data: any) => {
        if (data.status === "SUCCESS") {
          if(Number(data.min_price) == 0){
            this.currentDate = "";
            this.activitiesForm.get('date').setValue(this.currentDate);
          }
        }
      });
    } catch (error) {
      console.log(error)
    }
    this.quickActivitiesData = [];
    this.quickActivityTypes = [];
    this.showFilteredActivities = false;
    this.http.get(environment.apiUrl + `/activities/quickactivitytypes?category=activity${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        this.quickActivityTypes = data.activity;
      }
    })
    this.loadMoreQuickActivities();
    if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
      let userData = JSON.parse(sessionStorage.getItem('userData'));
      if (userData.category === 'activity') {
        setTimeout(() => {
          this.activitiesForm.setValue({
            activityId: userData.value.activityId,
            activities: userData.value.activities,
            date: userData.value.date,
            time: userData.value.time,
          });
          if (userData.isSubmittingActivities) {
            this.submitquickActivities();
          }
        })
        this.showDateField = true;
        this.showShadow = true;
      } else {
        sessionStorage.setItem('userData', JSON.stringify({ category: 'activity', value: this.activitiesForm.value }))
      }
    } else {
      sessionStorage.setItem('userData', JSON.stringify({ category: 'activity', value: this.activitiesForm.value }))
    }
  }
  changeMonths() {
    if (this.active_category == "trekking") {
      this.trekkingForm.get('slot').setValue('');
      this.slots = this.allSlots.filter(slot => {
        const monthPart = slot.split(' ')[1];
        return monthPart == this.trekkingForm.value.month;
      });
    } else if (this.active_category == "tour") {
      this.biketourForm.get('slot').setValue('');
      this.slots = this.allSlots.filter(slot => {
        const monthPart = slot.split(' ')[1];
        return monthPart == this.biketourForm.value.month;
      });
    }
  }
  changeActivities(value: any) {
    this.activitytypeid = value
  }

  get r(): { [key: string]: AbstractControl } {
    return this.raftingForm.controls;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.trekkingForm.controls;
  }

  get a(): { [key: string]: AbstractControl } {
    return this.activitiesForm.controls;
  }

  get raftingVariationsArrayControls() {
    return (this.raftingForm.get('variations') as FormArray).controls;
  }

  async FormBikeRental() {
    this.showFilteredBikes = true;
    this.showFilteredRentalMerchants = false;
    this.isSubmittingBikerental = false;
    this.filteredBikeCard = undefined;
    this.rentalForm = this.formBuilder.group({
      activityId: new FormControl(''),
      date: new FormControl('', Validators.required),
      time: new FormControl('', Validators.required),
      dropdowndate: new FormControl('', Validators.required),
      dropdowntime: new FormControl('', Validators.required),
      guest: new FormControl('1', [Validators.required, Validators.pattern(/^[1-9]*$/)]),
      variations: this.formBuilder.array([]),
      inventoryvariations: this.formBuilder.array([])
    });
    this.currentDate = this.getCurrentDate();
    this.currentDate1 = this.getNextDate();
    try {
      this.http.get(environment.apiUrl + `/range/minpricebycategory?category=bikerental&date=${this.currentDate}${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe((data: any) => {
        if (data.status === "SUCCESS") {
          if(Number(data.min_price) == 0){
            this.currentDate = "";
            this.currentDate1 = "";
          }
        }
      });
    } catch (error) {
      console.log(error)
    }
    if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
      let userData = JSON.parse(sessionStorage.getItem('userData'));
      if (userData.category === 'bike-rental') {
        this.rentalForm.setValue({
          activityId: userData.value.activityId,
          date: userData.value.date,
          time: userData.value.time,
          dropdowndate: userData.value.dropdowndate,
          dropdowntime: userData.value.dropdowntime,
          guest: userData.value.guest,
          variations: [],
          inventoryvariations: []
        });
        this.currentDate = userData.value.date;
        this.dropDate = userData.value.dropdowndate;
      } else {
        sessionStorage.setItem('userData', JSON.stringify({ category: 'bike-rental', value: this.rentalForm.value }))
      }

    } else {
      sessionStorage.setItem('userData', JSON.stringify({ category: 'bike-rental', value: this.rentalForm.value }))
    }
    this.rentalForm.get('date').valueChanges.subscribe(newValue => {
      this.rentalDate();
    });
    this.rentalForm.get('dropdowndate').valueChanges.subscribe(newValue => {
      this.rentalDate();
    });
    let filter: any = { count: this.viewCount, location: this.location, page: 1, status: 1 };
    if (this.locationId != null) {
      filter.locationId = this.locationId;
    }
    let resRentalData: any = await this.http.post(environment.apiUrl + `/merchants/merchantcategory`, { ...filter, category: 'bikerental' }, { responseType: 'json' }).toPromise();
    if (resRentalData.status === "SUCCESS") {
      this.bikeRentalData = resRentalData.merchants;
      this.rentalLoading = false;
    }
    this.variationid = [];
    this.variationValueId = [];
    this.inventoryVariations = [];
    this.inventoryVariationValueIds = [];
    this.inventoryVariationValues = [];
    this.variationValues = [];
    this.http.get(environment.apiUrl + `/merchantactivities/activityVariationDetails?category=bikerental${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        data.activityVariationDetails.forEach((x: any) => {
          x.merchants.forEach((y: any) => {
            if (!this.variationid.includes(y.variationtypeid)) {
              this.variationid.push(y.variationtypeid);
            }
            //  let index= this.variationid.indexOf(y.variationtypeid);
            //  if(index != -1){
            //   if(this.variationValueId[index] != undefined){
            //     (!this.variationValueId[index].includes(y.variationvalueid))?this.variationValueId[index].push(y.variationvalueid):""
            //   }else{
            //     this.variationValueId[index] =[];
            //     this.variationValueId[index].push(y.variationvalueid)
            //   }
            //  }
          })
        })
        if (this.variationid.length > 0) {
          let defaultVariationsData = [], sortedDefaultVariationsData = [];
          for (let i = 0; i < this.variationid.length; i++) {
            const data: any = await this.http.get(environment.apiUrl + `/variations/get?id=${this.variationid[i]}`).toPromise();
            defaultVariationsData.push(data.variations)
          }
          const sortByParentVariation = (data) => {
            const sortedData = [...data];
            sortedData.sort((a, b) => {
              if (a.parentvariation == '0' || a.parentvariation == null) {
                return -1;
              } else if (b.parentvariation == '0' || b.parentvariation == null) {
                return 1;
              } else {
                return a.parentvariation.localeCompare(b.parentvariation);
              }
            });
            return sortedData;
          };
          sortedDefaultVariationsData = sortByParentVariation(defaultVariationsData);
          const customSort = (a, b) => {
            const aIdInAnyParentVariation = sortedDefaultVariationsData.some(item => item.parentvariation === a.id);
            const bIdInAnyParentVariation = sortedDefaultVariationsData.some(item => item.parentvariation === b.id);

            if ((a.parentvariation == "0" || a.parentvariation == null) && !aIdInAnyParentVariation) {
              if ((b.parentvariation == "0" || b.parentvariation == null) && !bIdInAnyParentVariation) {
                return 0; // Both have parentvariation as 0 or null, and id does not exist in any parentvariation, no change in order
              } else {
                return 1; // Only a has parentvariation as 0 or null, and id does not exist in any parentvariation, bring it to the end
              }
            } else if ((b.parentvariation == "0" || b.parentvariation == null) && !bIdInAnyParentVariation) {
              return -1; // Only b has parentvariation as 0 or null, and id does not exist in any parentvariation, bring it to the end
            } else {
              return a.parentvariation.localeCompare(b.parentvariation);
            }
          }

          sortedDefaultVariationsData = sortedDefaultVariationsData.sort(customSort);
          let sortedDefaultVariationsid = [];
          for (let i = 0; i < sortedDefaultVariationsData.length; i++) {
            sortedDefaultVariationsid.push(sortedDefaultVariationsData[i].id);
          }
          this.variationid = sortedDefaultVariationsid;
          data.activityVariationDetails.forEach((x: any) => {
            x.merchants.forEach((y: any) => {
              let index = this.variationid.indexOf(y.variationtypeid.toString());
              if (index != -1) {
                if (this.variationValueId[index] != undefined) {
                  (!this.variationValueId[index].includes(y.variationvalueid.toString())) ? this.variationValueId[index].push(y.variationvalueid.toString()) : ""
                } else {
                  this.variationValueId[index] = [];
                  this.variationValueId[index].push(y.variationvalueid.toString())
                }
              }
            })
          })
          const variationsPromises = this.variationid.map(async (x: any) => {
            try {
              const data: any = await this.http.get(environment.apiUrl + `/variations/get?id=${x}`).toPromise();
              return data;
            } catch (error) {
              throw error;
            }
          });

          try {
            this.variations = await Promise.all(variationsPromises);
            for (let i = 0; i < this.variations.length; i++) {
              if (this.variations[i].variations.parentvariation == 0 || this.variations[i].variations.parentvariation == null) {
                const variationValue = this.variationValueId[i].map(async (y: any) => {
                  try {
                    const data: any = await this.http.get(environment.apiUrl + `/variationvalues/get?id=${y}`).toPromise();
                    return data;
                  } catch (error) {
                    throw error;
                  }
                });
                let values = await Promise.all(variationValue)
                this.variationValues.push(values)
              } else {
                this.variationValues.push([]);
              }
            }
            const variationArray = this.rentalForm.get('variations') as FormArray;
            this.variations.forEach((x: any) => {
              variationArray.push(
                this.formBuilder.group({
                  variationid: new FormControl(x.variations.id),
                  type: new FormControl(x.variations.type),
                  variationvalueid: new FormControl('', Validators.required),
                  parentvariation: new FormControl(x.variations.parentvariation)
                }))
            })
            if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
              let userData = JSON.parse(sessionStorage.getItem('userData'));
              if (userData.category === 'bike-rental') {
                const variationArray = this.rentalForm.get('variations') as FormArray;
                if (userData.value.variations) {
                  userData.value.variations.forEach((x: any) => {
                    let indexToUpdate = variationArray.value.findIndex((y: any) => y.variationid == x.variationid);
                    const variationGroupToUpdate = variationArray.at(indexToUpdate) as FormGroup;
                    variationGroupToUpdate.controls['variationvalueid'].setValue(x.variationvalueid);
                    setTimeout(() => {
                      this.changeRentalDefaultVariation(indexToUpdate)
                    })
                  })
                }
              } else {
                sessionStorage.setItem('userData', JSON.stringify({ category: 'bike-rental', value: this.rentalForm.value }))
              }
            } else {
              sessionStorage.setItem('userData', JSON.stringify({ category: 'bike-rental', value: this.rentalForm.value }))
            }
          } catch (error) {
            console.error("An error occurred:", error);
          }
        }

      }
    })
    this.http.get(environment.apiUrl + `/merchantinventories/viewInventory?category=bikerental${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        if (data.merchantinventories.length > 0) {
          let inventoryVariationIds = [];
          data.merchantinventories.forEach((x: any) => {
            if (!inventoryVariationIds.includes(x.variationid)) inventoryVariationIds.push(x.variationid);
            this.inventoryVariationValueIds.push(x.variationvalueid);
          })
          const inventoryVariationRequests = inventoryVariationIds.map((x: any) => {
            return this.http.get(environment.apiUrl + `/variations/get?id=${x}`).toPromise(); // Convert the Observable to a Promise
          });

          const loadVariationRequests = await Promise.all(inventoryVariationRequests);
          loadVariationRequests.forEach((x: any) => {
            this.inventoryVariations.push(x.variations)
          })

          const sortByParentVariation = (data) => {
            const sortedData = [...data];
            sortedData.sort((a, b) => {
              if (a.parentvariation === '0') {
                return -1;
              } else if (b.parentvariation === '0') {
                return 1;
              } else {
                return a.parentvariation.localeCompare(b.parentvariation);
              }
            });
            return sortedData;
          };
          this.inventoryVariations = sortByParentVariation(this.inventoryVariations);
          const inventoryvariationsArray = this.rentalForm.get('inventoryvariations') as FormArray;
          this.inventoryVariations.forEach((x: any) => {
            inventoryvariationsArray.push(
              this.formBuilder.group({
                variationid: new FormControl(x.id),
                type: new FormControl(x.type),
                variationvalueid: new FormControl(''),
              }))
          })
          if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
            let userData = JSON.parse(sessionStorage.getItem('userData'));
            if (userData.category === 'bike-rental') {
              const inventoryvariationArray = this.rentalForm.get('inventoryvariations') as FormArray;
              if (userData.value.variations) {
                userData.value.variations.forEach((x: any) => {
                  let indexToUpdate = inventoryvariationArray.value.findIndex((y: any) => y.variationid == x.variationid);
                  const variationGroupToUpdate = inventoryvariationArray.at(indexToUpdate) as FormGroup;
                  variationGroupToUpdate.controls['variationvalueid'].setValue(x.variationvalueid);
                })
              }
            } else {
              sessionStorage.setItem('userData', JSON.stringify({ category: 'bike-rental', value: this.rentalForm.value }))
            }
          } else {
            sessionStorage.setItem('userData', JSON.stringify({ category: 'bike-rental', value: this.rentalForm.value }))
          }
          //   let valueRes:any= await this.http.post(environment.apiUrl + `/variationvalues/list`, { variationid: this.inventoryVariations[0].id, count: 500, status: 1 }).toPromise();
          //   if(valueRes.status === "SUCCESS"){
          //     let values=[];
          //     valueRes.variationvalues.forEach((x:any)=>{
          //       if(this.inventoryVariationValueIds.includes(x.id)) values.push(x);
          //     })
          //     this.inventoryVariationValues.push(values);
          //   }
          // this.inventoryVariations.forEach((x:any, index:any) => {
          //   if (index !== 0) {
          //     this.inventoryVariationValues.push([]);
          //   }
          // });
        }
        this.searchMerchantBikes(0);
      }
    })
  }

  async changeRentalDefaultVariation(column: any) {
    let variationid = this.rentalForm.value.variations[column]?.variationid;
    let parentVariation = this.rentalForm.value.variations[column + 1]?.parentvariation;
    let variationValueId = this.rentalForm.value.variations[column]?.variationvalueid;
    if (parentVariation == variationid) {
      const variationsArray = this.rentalForm.get('variations') as FormArray;
      const formGroup = variationsArray.at(column + 1) as FormGroup;
      if (formGroup) {
        formGroup.patchValue({
          variationvalueid: '',
        });
      }
      if (variationValueId != "") {
        let res: any = await this.http.post(environment.apiUrl + `/variationvalues/list`, { parentVariationvalueId: variationValueId, count: 500, status: 1 }).toPromise();
        if (res.status === "SUCCESS") {
          let values = [];
          res.variationvalues.forEach((x: any) => {
            if (this.variationValueId[column + 1]?.includes(x.id)) values.push({ status: "SUCCESS", variationvalues: x });
          })
          this.variationValues[column + 1] = values;
          this.onChangeRentalVariations();
        }
      } else {
        this.variationValues[column + 1] = [];
        this.onChangeRentalVariations();
      }
      if (formGroup) {
        if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
          let userData = JSON.parse(sessionStorage.getItem('userData'));
          if (userData.category === 'bike-rental') {
            const inventoryvariationArray = this.rentalForm.get('inventoryvariations') as FormArray;
            if (userData.value.variations) {
              userData.value.variations.forEach((x: any) => {
                let indexToUpdate = inventoryvariationArray.value.findIndex((y: any) => y.variationid == x.variationid);
                const variationGroupToUpdate = inventoryvariationArray.at(indexToUpdate) as FormGroup;
                variationGroupToUpdate.controls['variationvalueid'].setValue(x.variationvalueid);
              })
            }
          } else {
            sessionStorage.setItem('userData', JSON.stringify({ category: 'bike-rental', value: this.rentalForm.value }))
          }
        } else {
          sessionStorage.setItem('userData', JSON.stringify({ category: 'bike-rental', value: this.rentalForm.value }))
        }
      }
    } else {
      this.onChangeRentalVariations();
    }
    if (this.showFilteredRentalMerchants) {
      this.loadFilteredRentalMerchants();
    }
  }

  rentalDate() {
    if (this.showFilteredRentalMerchants) {
      this.loadFilteredRentalMerchants();
    }
  }
  // changeInventoryVariation(column: any) {
  //   let variationValueId = this.rentalForm.value.inventoryvariations[column]?.variationvalueid;
  //   const inventoryvariationsArray = this.rentalForm.get('inventoryvariations') as FormArray;
  //   const formGroup = inventoryvariationsArray.at(column +1) as FormGroup;
  //   if (formGroup) {
  //     formGroup.patchValue({
  //       variationvalueid: '',
  //     });
  //   }
  //   if (variationValueId != "") {
  //     this.http.post(environment.apiUrl + `/variationvalues/list`, { parentVariationvalueId: variationValueId, count: 500, status: 1 }).subscribe(async (res: any) => {
  //       {
  //         if (res.status === "SUCCESS") {
  //           let values=[];
  //           res.variationvalues.forEach((x:any)=>{
  //             if(this.inventoryVariationValueIds.includes(x.id)) values.push(x);
  //           })
  //           this.inventoryVariationValues[column + 1] = values;
  //           this.onChangeRentalVariations();
  //         }
  //       }
  //     })
  //   }else{
  //     this.inventoryVariationValues[column + 1] = [];
  //     this.onChangeRentalVariations();
  //   }
  // }


  get br(): { [key: string]: AbstractControl } {
    return this.rentalForm.controls;
  }

  get rentalVariationsArrayControls() {
    return (this.rentalForm.get('variations') as FormArray).controls;
  }

  get rentalInventoryVariationsArrayControls() {
    return (this.rentalForm.get('inventoryvariations') as FormArray).controls;
  }

  FormCamping() {
    this.isSubmittingCamping = false;
    this.viewSearchedCamping = false;
    this.campingForm = this.formBuilder.group({
      activityId: new FormControl(''),
      date: new FormControl('', Validators.required),
      adult: new FormControl('', Validators.required),
      children: new FormControl('', Validators.required),
      guest: new FormControl('', Validators.required),
      checkoutdate: new FormControl('1', Validators.required),
      variations: this.formBuilder.array([])
    });
    this.currentDate = this.getCurrentDate();
    this.currentDate1 = this.getNextDate();
    try {
      this.http.get(environment.apiUrl + `/range/minpricebycategory?category=camping&date=${this.currentDate}${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe((data: any) => {
        if (data.status === "SUCCESS") {
          if(Number(data.min_price) == 0){
            this.currentDate = "";
            this.currentDate1 = "";
          }
        }
      });
    } catch (error) {
      console.log(error)
    }
    if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
      let userData = JSON.parse(sessionStorage.getItem('userData'));
      if (userData.category === 'camping') {
        this.adultsCount = userData.value.adult;
        this.childrenCount = userData.value.children;
        this.totalGuest = userData.value.guest;
        this.currentDate1 = userData.value.checkoutdate;
        this.currentDate = userData.value.date;
        this.campingForm.setValue({
          activityId: userData.value.activityId,
          date: userData.value.date,
          adult: userData.value.adult,
          children: userData.value.children,
          guest: userData.value.guest,
          checkoutdate: userData.value.checkoutdate,
          variations: []
        });
        this.showDateField = true;
        this.showShadow = true;
      } else {
        sessionStorage.setItem('userData', JSON.stringify({ category: 'camping', value: this.campingForm.value }))
      }

    } else {
      sessionStorage.setItem('userData', JSON.stringify({ category: 'camping', value: this.campingForm.value }))
    }
    this.variationid = [];
    this.variationValueId = [];
    this.nonFiteredCampingData = [];
    this.campingPage = 1;
    this.campingLoading = true;
    this.campingData = [];
    this.loadMoreCampings();
    this.http.get(environment.apiUrl + `/merchantactivities/activityVariationDetails?category=camping${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        data.activityVariationDetails.forEach((x: any) => {
          x.merchants.forEach((y: any) => {
            if (!this.variationid.includes(y.variationtypeid)) {
              this.variationid.push(y.variationtypeid);
            }
            let index = this.variationid.indexOf(y.variationtypeid);
            if (index != -1) {
              if (this.variationValueId[index] != undefined) {
                (!this.variationValueId[index].includes(y.variationvalueid)) ? this.variationValueId[index].push(y.variationvalueid) : ""
              } else {
                this.variationValueId[index] = [];
                this.variationValueId[index].push(y.variationvalueid)
              }
            }
          })
        })
        if (this.variationid.length > 0) {
          const variationsPromises = this.variationid.map(async (x: any) => {
            try {
              const data: any = await this.http.get(environment.apiUrl + `/variations/get?id=${x}`).toPromise();
              return data;
            } catch (error) {
              throw error;
            }
          });

          const variationValuePromises = this.variationValueId.map(async (x: any) => {
            const variationValuePromisesInner = x.map(async (y: any) => {
              try {
                const data: any = await this.http.get(environment.apiUrl + `/variationvalues/get?id=${y}`).toPromise();
                return data;
              } catch (error) {
                throw error;
              }
            });
            return await Promise.all(variationValuePromisesInner);
          });

          try {
            this.variations = await Promise.all(variationsPromises);
            this.variationValues = await Promise.all(variationValuePromises);
            const variationArray = this.campingForm.get('variations') as FormArray;
            this.variations.forEach((x: any) => {
              variationArray.push(
                this.formBuilder.group({
                  variationid: new FormControl(x.variations.id),
                  type: new FormControl(x.variations.type),
                  variationvalueid: new FormControl('', Validators.required),
                }))
            })
            if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
              let userData = JSON.parse(sessionStorage.getItem('userData'));
              if (userData.category === 'camping') {
                const variationArray = this.campingForm.get('variations') as FormArray;
                if (userData.value.variations) {
                  userData.value.variations.forEach((x: any) => {
                    let indexToUpdate = variationArray.value.findIndex((y: any) => y.variationid == x.variationid);
                    const variationGroupToUpdate = variationArray.at(indexToUpdate) as FormGroup;
                    variationGroupToUpdate.controls['variationvalueid'].setValue(x.variationvalueid);
                  })
                }
                if (userData.isSubmittingCamping) {
                  this.searchCamping();
                }
              } else {
                sessionStorage.setItem('userData', JSON.stringify({ category: 'camping', value: this.campingForm.value }))
              }
            } else {
              sessionStorage.setItem('userData', JSON.stringify({ category: 'camping', value: this.campingForm.value }))
            }
          } catch (error) {
            console.error("An error occurred:", error);
          }
        } else {
          if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
            let userData = JSON.parse(sessionStorage.getItem('userData'));
            if (userData.category === 'camping') {
              if (userData.isSubmittingCamping) {
                this.searchCamping();
              }
            }
          }
        }

      }
    })
  }

  validCheckOut(control: AbstractControl): { [key: string]: boolean } | null {
    const checkoutDate = new Date(control.value);
    let date = new Date();
    if (this.campingForm && this.campingForm.get('date')?.value) {
      const dateFormControlValue = this.campingForm.get('date')?.value;
      date = new Date(dateFormControlValue);
    }

    if (isNaN(checkoutDate.getTime()) || checkoutDate < date) {
      return { 'invalidCheckout': true };
    }

    return null;
  }


  validCheckIn(control: AbstractControl): { [key: string]: boolean } | null {
    const checkIn = new Date(control.value);
    let checkout = new Date();
    if (this.campingForm && this.campingForm.get('checkoutdate')?.value) {
      const dateFormControlValue = this.campingForm.get('checkoutdate')?.value;
      checkout = new Date(dateFormControlValue);
    }

    if (isNaN(checkIn.getTime()) || checkIn > checkout) {
      return { 'invalidCheckout': true };
    }

    return null;
  }

  get ca(): { [key: string]: AbstractControl } {
    return this.campingForm.controls;
  }

  get campingVariationsArrayControls() {
    return (this.campingForm.get('variations') as FormArray).controls;
  }

  get bt(): { [key: string]: AbstractControl } {
    return this.biketourForm.controls;
  }

  // get bikeTourVariationsArrayControls() {
  //   return (this.biketourForm.get('variations') as FormArray).controls;
  // }

  location: string | undefined;
  onLocationChange() {
    if (this.location && this.location.trim() !== '') {
      this.http.post(environment.apiUrl + `/usersearch/create`, { userId: (this.userId) ? this.userId : 0, page: 'home', searchData: this.location }, { responseType: 'json' }).subscribe(async (data: any) => { })
      this.router.navigate(['/search'], { queryParams: { q: this.location, type: 'location' } });
    }
  }

  openLocation(ID: number, e: any) {
    this.cursorUp.x = e.pageX;
    this.cursorUp.y = e.pageY;
    if (JSON.stringify(this.cursorUp) === JSON.stringify(this.cursorDown))
      this.router.navigate(['/locationDetails'], { queryParams: { place: ID } })
  };



  openMerchantPage(locationid:any, id:any,city:any,name:any){
    city = city.trimEnd().replace(/\s+/g, '-').toLowerCase();
    name = name.trimEnd().replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['/merchant-profile','location',locationid,'merchantid',id,city,name])
  }
  saveLocation(data: any) {
    this.dataService.addSavedData(data);
  }

  loadImages(datafor: any, id: any): Promise<any> {
    let src: any = [];
    return new Promise<any>(async (resolve, reject) => {
      try {
        const data: any = await this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=${datafor}&dataforid=${id}`).toPromise();

        if (data.status === "SUCCESS") {
          for (const upload of data.uploads) {
            let res: any = await this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).toPromise();
            if (res.status === "SUCCESS") {
              src.push(res.upload.thumbnail)
            }
          }
          resolve(src);
        } else {
          reject(src);
        }
      } catch (error) {
        reject(src);
      }
    });
  }

  ifMobileApp() {
    if (this.userAgent.includes('AdvenzoneMobileApp')) {
      return true;
    } else {
      return false;
    }
  }

  openLoginDialog() {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '90vh',
      width: '50%',
      maxWidth: 'unset',
      panelClass: 'bg-login-class'
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
        panelClass: 'bg-login-class'
      }
    }
    this.dialog.open(LoginDialogComponent, {
      ...sizes,
      disableClose: true,
      data: {

      },
    });
  }

  loadRating(dataFor: string, dataForId: any) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const data: any = await this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor, dataForId }, { responseType: 'json' }).toPromise();
        if (data.status === "SUCCESS") {
          resolve(data.reviews);
        }
      } catch (error: any) {
        reject(error);
      }
    });
  }

  loadPriceStartsFrom(activityId: any) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const data: any = await this.http.post(environment.apiUrl + `/merchantactivities/pricestartsfrom`, { activityId }, { responseType: 'json' }).toPromise();
        if (data.status === "SUCCESS") {
          resolve(data.priceStartsFrom);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  openUrl(url: any) {
    const urlParams = new URLSearchParams(url.split('?')[1]);
    const urlcontent = url.split('?')[0];
    const id = urlParams.get('id');
    if (id) {
      this.router.navigate([urlcontent], { queryParams: { id: id } })
    } else {
      this.router.navigate([urlcontent])
    }
  }

  showPhase(event: any) {
    // this.showDateField = false;
    window.scroll(0, 0)
    this.active_category = event;
    this.currentDate = this.getCurrentDate();
    this.currentDate1 = this.getNextDate();
    // const element = this.elementRef.nativeElement.querySelector(`#${event}`);
    // if (element) {
    //   element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    // }
    // if(event === "landing-page"){
    //   this.active_category 
    // }

    const eventToDatafromMap = {
      'rafting': 'Rafting',
      'camping': 'Camping',
      'bike-rental': 'Bike Rental',
      'trekking': 'Trekking',
      'tour': 'Bike Tour',
      'quickactivities': 'Quick Activities',
    };
    let datafromValue = eventToDatafromMap[this.active_category] || this.active_category;
    if (event === 'landing-page' || event === 'topAdventurists') {
      datafromValue = '';
    }

    const datas = {
      datafrom: datafromValue,
      activityType: '',
    }

    this.dataService.addSavedData(datas);
    if (event === "landing-page") {
      if (this.locationId != null) {
        let location = JSON.parse(localStorage.getItem('location'));
        this.landingPageImages = [];
        this.http.get(`${environment.apiUrl}/locations/get?id=${location.id}`).subscribe((data: any) => {
          if (data.status === "SUCCESS") {
            this.locationname =data.location.locationname;
            // this.description =data.location.description;
           
            const description = typeof data.location.description === 'string' ? data.location.description.trim() : '';
          
            const strippedDescription = description.replace(new RegExp('(<p><br></p>|<br>)$'),'').trim();
            
            if (strippedDescription) {
              this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(data.location.description);
            } else {
              this.sanitizedDescription = '';
            }


            this.latitude =data.location.latitude;
            this.longitude =data.location.longitude;
            this.map = new mapboxgl.Map({
             container: 'map',
             style: this.style,
             zoom: 13,
             center: [this.longitude, this.latitude],
             accessToken: environment.mapbox.accessToken,
             interactive: false
           })
           const marker = new mapboxgl.Marker()
             .setLngLat([this.longitude, this.latitude])
             .addTo(this.map);
            let additionalinfo: any = "", sorted_images = [];
            if (data.location.additionalinfo && data.location.additionalinfo != null && data.location.additionalinfo != "") {
              additionalinfo = JSON.parse(data.location.additionalinfo);
              additionalinfo.forEach((x) => {
                if(x.visible == "true"){
                  sorted_images.push(x.imageid);
                }
              });
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=location&dataforid=${this.locationId}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  if (sorted_images.length != 0) {
                   data.uploads.sort((a, b) => {
                      const indexA = sorted_images.indexOf(a.id);
                      const indexB = sorted_images.indexOf(b.id);
                    
                      // Handle cases where id is not found
                      if (indexA === -1 && indexB === -1) return 0; // Keep original order
                      if (indexA === -1) return 1; // Place a after b
                      if (indexB === -1) return -1; // Place a before b
                    
                      return indexA - indexB;
                    });
                  }
                   const filteredActivities =   data.uploads.filter(activity => sorted_images.includes(activity.id));
                   filteredActivities.forEach((x: any, i) => {
                    this.landingPageImages.push({ ...x, url: null, src: null });
                    this.http.get(environment.apiUrl + `/uploads/get?id=${x.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        this.landingPageImages[i].src = res.upload.base64data;
                        if (additionalinfo && additionalinfo.length > 0) {
                          this.landingPageImages[i].url = additionalinfo[i].url;
                        }
                      }
                    });
                  })
                }
              })
            } catch (error) {
              console.log(error)
            }
          }
        })

      }
      this.landingPageData = [];
      this.nonFiteredLandingData = [];
      this.loadMoreLandingPage('showmore', this.selectedSortBy)
    }
    if (event === "rafting") {
      this.newTimings = [];
      this.showFilteredRafting = false;
      this.showDateField = false;
      this.FormRafting();
    }
    if (event === "bike-rental") {
      this.showFilteredRentalMerchants = false;
      this.showFilteredBikes = false;
      // this.showDateField = false;
      this.FormBikeRental();
    }
    if (event === "trekking") {
      this.newTimings = [];
      this.showDateField = false;
      // this.showFilteredRafting = false;
      this.FormTrekking();
    }
    if (event === "tour") {
      this.newTimings = [];
      this.showDateField = false;
      // this.showFilteredRafting = false;
      this.FormTour();
    }
    if (event === "quickactivities") {
      this.newTimings = [];
      this.showDateField = false;
      this.quickActivitiesLoading = true
      this.FormActivities();
    }

    if (event === "camping") {
      this.showDateField = false;
      this.FormCamping();
      setTimeout(() => {
        if (typeof $ !== 'undefined' && $.fn.datepicker) {
          // Use jQuery Datepicker
          $('.datepicker1').datepicker({
            minDate: new Date(this.startDate),
            dateFormat: 'yy-mm-dd',
            changeMonth: true,
            changeYear: true,
            onSelect: (dateText: any) => {
              this.currentDate1 = dateText;
            }
          });
          let content_this = this;
          $(".datepicker1").focus(function() {
            let month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
            let year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
            month = Number(month)+1;
            $(".ui-datepicker-calendar .ui-state-default").each(function() {
              if($(this).parent().hasClass('ui-state-disabled')){
                $(this).addClass('text-center');
                return
              }else if(!$(this).hasClass('data-taken')){
                $(this).addClass('data-taken');
                $(this).addClass('text-center');
                $(this).css('background','#FFFFFF');
                let day= $(this).attr('data-date');
                  let date = `${year}-${(month.length == 1)? '0'+month : month}-${(day.length == 1)? '0'+day : day}`;
                  if(content_this.active_category == "camping"){
                    try {
                      content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=camping&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                        if (data.status === "SUCCESS") {
                          if(Number(data.min_price) != 0){
                            $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                          }else{
                            $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                          }
                        }
                      });
                    } catch (error) {
                      console.log(error)
                    }
                  }
              }
            });
          });

        } else {
          console.error('jQuery or jQuery UI Datepicker not available');
        }
      }, 0)
    }
    if (event === "topAdventurists") {
      sessionStorage.setItem('userData', JSON.stringify({ category: 'topAdventurists' }))
      const element = this.elementRef.nativeElement.querySelector(`#${event}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
      this.topAdventuristsData = [];
      this.topAdventuristsPage = 1;
      this.adventuristsLoading = true;
      this.loadMoreAdventurists();
    }
    setTimeout(() => {
      if (typeof $ !== 'undefined' && $.fn.datepicker) {
        // Use jQuery Datepicker
        $('.datepicker').datepicker({
          minDate: new Date(this.startDate),
          dateFormat: 'yy-mm-dd',
          changeMonth: true,
          changeYear: true,
          onSelect: (dateText: any) => {
            this.currentDate = dateText;
          }
        });
        $('.datepicker1').datepicker({
          minDate: new Date(this.startDate),
          dateFormat: 'yy-mm-dd',
          changeMonth: true,
          changeYear: true,
          onSelect: (dateText: any) => {
            this.dropDate = dateText;
          }
        });
      } else {
        console.error('jQuery or jQuery UI Datepicker not available');
      }

       let content_this = this;
        $(".datepicker").focus(function() {
          let month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
          let year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
          month = Number(month)+1;
          $(".ui-datepicker-calendar .ui-state-default").each(function() {
            if($(this).parent().hasClass('ui-state-disabled')){
              $(this).addClass('text-center');
              $(this).css('background','#FFFFFF');
              return
            }else if(!$(this).hasClass('data-taken')){
              $(this).addClass('data-taken');
              $(this).addClass('text-center');
              $(this).css('background','#FFFFFF');
              let day= $(this).attr('data-date');
                let date = `${year}-${(month.length == 1)? '0'+month : month}-${(day.length == 1)? '0'+day : day}`;
                $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center;'><img src='/assets/loader.svg' width=25px alt='loader' /></span>");
                if(content_this.active_category == "rafting"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=rafting&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "bike-rental"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=bikerental&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "camping"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=camping&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "tour"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=tour&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "trekking"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=trekking&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "quickactivities"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=activity&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
            }

          });
        });
        $(".datepicker1").focus(function() {
          let month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
          let year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
          month = Number(month)+1;
          $(".ui-datepicker-calendar .ui-state-default").each(function() {
            if($(this).parent().hasClass('ui-state-disabled')){
              $(this).addClass('text-center');
              return
            }else if(!$(this).hasClass('data-taken')){
              $(this).addClass('data-taken');
              $(this).addClass('text-center');
              $(this).css('background','#FFFFFF');
              let day= $(this).attr('data-date');
                let date = `${year}-${(month.length == 1)? '0'+month : month}-${(day.length == 1)? '0'+day : day}`;
                if(content_this.active_category == "bike-rental"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=bikerental&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "camping"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=camping&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
            }
          });
        });
        }, 0)
  }

  back_to_top() {
    window.scrollTo(0, 0);
  }

  removeDuplicatesLandingPage(arr: any[], prop1: string, prop2: string, prevArr: any[]) {
    arr = arr.filter((obj, index, self) =>
      index === self.findIndex((o) =>
        o[prop1] === obj[prop1] && ((obj.activitiescategory === 'camping' || obj.activitiescategory === 'bikerental') ? o[prop2] === obj[prop2] : true)
      )
    );

    arr = arr.filter((obj, index, self) =>
      prevArr.findIndex((o) =>
        o[prop1] === obj[prop1] && ((obj.activitiescategory === 'camping' || obj.activitiescategory === 'bikerental') ? o[prop2] === obj[prop2] : true)
      ) == -1
    );
    return arr;
  }


  removeDuplicates(arr: any[], prop1: string, prop2: string, prevArr: any[]) {
    arr = arr.filter((obj, index, self) =>
      index === self.findIndex((o) =>
        o[prop1] === obj[prop1] && o[prop2] === obj[prop2]
      )
    );

    arr = arr.filter((obj, index, self) =>
      prevArr.findIndex((o) =>
        o[prop1] === obj[prop1] && o[prop2] === obj[prop2]
      ) == -1
    );
    return arr;
  }

  isPastDateTime(timing: string): boolean {
    // const currentTime = new Date().getTime();
    // const givenTime = new Date(`${this.currentDate.replaceAll("-", "/")} ${timing}`).getTime();
    // if(givenTime > currentTime){
    //   return false;
    // }
    // return true;
    const currentDateTime = moment();
    const selectedDateTime = moment(`${this.currentDate} ${timing}`, 'YYYY-MM-DD h:mm a');
    return selectedDateTime.isBefore(currentDateTime);
  }

  isPastDateTimeDrop(timing: string): boolean {
    const currentDateTime = moment();
    const selectedDateTime = moment(`${this.dropDate} ${timing}`, 'YYYY-MM-DD h:mm a');
    return selectedDateTime.isBefore(currentDateTime);
  }

  submitTrekking() {
    this.isSubmittingTrekking = true;
    if (this.trekkingForm.invalid) {
      return;
    }
    sessionStorage.setItem('userData', JSON.stringify({ category: 'trekking', value: this.trekkingForm.value, isSubmittingTrekking: true }))
    this.visibleFilteredTrekking = true;
    let filterData: any = {};
    const { startDate, endDate } = this.dateConversionService.convertToDateRange(this.trekkingForm.value.slot);
    filterData.slots = startDate + ' - ' + endDate;
    filterData.category = 'trekking';
    if (this.locationId != null) {
      filterData.locationId = this.locationId;
    }
    this.http.post(environment.apiUrl + `/merchantactivities/packagetourslist`, filterData, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        setTimeout(() => {
          const isMobile = window.innerWidth < 768;
          const element = this.elementRef.nativeElement.querySelector('.trekking-section');
          if (element) {
            window.scroll(0, element.offsetTop + 30);
            if (isMobile) {
              element.scrollIntoView({ top: 0, behavior: 'smooth', block: 'start', inline: 'nearest' });
            }
          }
        });
        if (data.status === "SUCCESS") {
          data.filteredActivities.forEach((activity: any) => {
            activity.src = [];
            let parsedfrom = JSON.parse(activity.additionalinfo);
            activity.totalaltitude = parsedfrom.totalaltitude;
            activity.numberofdays = parsedfrom.numberofdays;
            activity.difficulty = parsedfrom.difficulty;
            activity.slot_available = "";
            try{
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId:activity.merchantid,activityId:activity.activityid ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS" ) {
                  activity.hasCoupon = data.coupons;
                  }else{
                  activity.hasCoupon = false;
                  }
                  })
            }catch(error){
              console.log(error);
              activity.hasCoupon = false;
            }
            try {
              this.http.get(environment.apiUrl + `/merchantactivities/packagetourslots?category=trekking&activityId=${activity.activityid}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  if(data.slots.length > 0){
                    activity.slot_available = true;
                  }else{
                    activity.slot_available = false;
                  }
                }
              });
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'activity', dataForId: activity.id }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.avgReview = Math.round(data.reviews.average);
                  activity.total = data.reviews.total;
                }
              });
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=activity&dataforid=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        activity.src.push(res.upload.thumbnail);

                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.get(environment.apiUrl + `/range/minpricebyactivityid?activityId=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.min_price = data.min_price;
                }
              });
            } catch (error) {
              console.log(error)
            }
          });

          this.filterTrekkingData = data.filteredActivities;
        }
      }
    })

  }

  submitTour() {
    this.isSubmittingBikeTour = true;
    if (this.biketourForm.invalid) {
      return;
    }
    sessionStorage.setItem('userData', JSON.stringify({ category: 'tour', value: this.biketourForm.value, isSubmittingBikeTour: true }))
    this.visibleFilteredTour = true;
    let filterData: any = {};
    const { startDate, endDate } = this.dateConversionService.convertToDateRange(this.biketourForm.value.slot);
    filterData.slots = startDate + ' - ' + endDate;
    filterData.category = 'tour';
    if (this.locationId != null) {
      filterData.locationId = this.locationId;
    }
    // filterData.variations=this.biketourForm.value.variations || [];
    this.http.post(environment.apiUrl + `/merchantactivities/packagetourslist`, filterData, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        setTimeout(() => {
          const isMobile = window.innerWidth < 768;
          const element = this.elementRef.nativeElement.querySelector('.tour-section');
          if (element) {
            window.scroll(0, element.offsetTop + 30);
            if (isMobile) {
              element.scrollIntoView({ top: 0, behavior: 'smooth', block: 'start', inline: 'nearest' });
            }
          }
        });
        if (data.status === "SUCCESS") {
          data.filteredActivities.forEach((activity: any) => {
            activity.src = [];
            let parsedfrom = JSON.parse(activity.additionalinfo);
            activity.totaldistance = parsedfrom.totaldistance;
            activity.numberofdays = parsedfrom.numberofdays;
            activity.difficulty = parsedfrom.difficulty;
            activity.slot_available = "";
            try{
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId:activity.merchantid,activityId:activity.activityid ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS" ) {
                  activity.hasCoupon = data.coupons;
                  }else{
                  activity.hasCoupon = false;
                  }
                  })
            }catch(error){
              console.log(error);
              activity.hasCoupon = false;
            }
            try {
              this.http.get(environment.apiUrl + `/merchantactivities/packagetourslots?category=tour&activityId=${activity.activityid}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  if(data.slots.length > 0){
                    activity.slot_available = true;
                  }else{
                    activity.slot_available = false;
                  }
                }
              });
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'activity', dataForId: activity.id }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.avgReview = Math.round(data.reviews.average);
                  activity.total = data.reviews.total;
                }
              });
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=activity&dataforid=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        activity.src.push(res.upload.thumbnail);

                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }

            try {
              this.http.get(environment.apiUrl + `/range/minpricebyactivityid?activityId=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.min_price = data.min_price;
                }
              });
            } catch (error) {
              console.log(error)
            }
          });
          this.filteredTourData = data.filteredActivities;
        }
      }
    })
  }

  searchRaftingMerchants() {
    this.isSubmittingRafting = true;
    if (this.raftingForm.invalid) {
      return;
    }
    this.showFilteredRafting = true;
    this.loadingprovidedByRafting = true;
    sessionStorage.setItem('userData', JSON.stringify({ category: 'rafting', value: this.raftingForm.value, isSubmittingRafting: true }))
    // if (this.userId) {

    // const isMobile = window.innerWidth < 768;
    // let sizes = {
    //   height: '500px',
    //   width: '70%',
    //   maxWidth: 'unset',
    // };
    // if (isMobile) {
    //   sizes = {
    //     width: '100%',
    //     maxWidth: 'unset',
    //     height: '100%',
    //   }
    // }
    this.filteredRaftingData = [];
    let filterData: any = { category: 'rafting', count: 500, location: this.location, status: 1 };
    if (this.locationId != null) {
      filterData.locationId = this.locationId;
    }
    this.http.post(environment.apiUrl + `/activities/list`, filterData, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        setTimeout(() => {
          const isMobile = window.innerWidth < 768;
          const element = this.elementRef.nativeElement.querySelector('.rafting-section');
          if (element) {
            window.scroll(0, element.offsetTop + 30);
            if (isMobile) {
              element.scrollIntoView({ top: 0, behavior: 'smooth', block: 'start', inline: 'nearest' });
            }
          }
        });
        if (data.status === "SUCCESS") {
          data.activities.forEach((activity: any) => {
            activity.src = [];
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=activity&dataforid=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        activity.src.push(res.upload.thumbnail)
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            if (activity.additionalinfo != null && activity.additionalinfo != "") {
              activity.raftingkm = JSON.parse(activity.additionalinfo).raftingkm;
              if (activity.raftingkm === this.raftingForm.value.distance) {
                this.filteredRaftingData.push(activity);
              }
            }
          });
          if (this.filteredRaftingData.length > 0) {
            let filter: any = { activityId: this.filteredRaftingData[0].id, variations: [] };
            if (this.locationId != null) {
              filter.locationId = this.locationId;
            }
            // let start_time=[], end_time=[];
            this.http.post(environment.apiUrl + `/merchantactivities/filterMerchant`, filter, { responseType: 'json' }).subscribe(async (data: any) => {
              {
                if (data.status === "SUCCESS") {
                  data.merchantactivities.forEach((x: any, i: any) => {
                    // start_time.push(this.timings.indexOf(x.timefrom));
                    // end_time.push(this.timings.indexOf(x.timeto));
                    x.src = [];
                    try{
                      this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId:x.merchantid,activityId:x.activityid ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
                        if (data.status === "SUCCESS" ) {
                          x.hasCoupon = data.coupons;
                          }else{
                          x.hasCoupon = data.coupons;
                          }
                          })
                    }catch(error){
                      console.log(error);
                      x.hasCoupon = data.coupons;
                    }
                    try {
                      this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'merchant', dataForId: x.merchantid }, { responseType: 'json' }).subscribe((data: any) => {
                        if (data.status === "SUCCESS") {
                          x.rating = Math.round(data.reviews.average);
                          x.ratingOf = data.reviews.total;
                        }
                      });
                    } catch (error) {
                      console.log(error)
                    }
                    try {
                      this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantprofile&dataforid=${x.merchantid}`).subscribe((data: any) => {
                        if (data.status === "SUCCESS") {
                          for (const upload of data.uploads) {
                            this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                              if (res.status === "SUCCESS") {
                                x.src.push(res.upload.thumbnail)
                              }
                            });
                          }
                        }
                      })
                    } catch (error) {
                      console.log(error)
                    }
                    x.price="";
                   x.activityId =  this.filteredRaftingData[0].id;
                    x.date = this.raftingForm.value.date;
                    x.time = this.raftingForm.value.time;
                    x.guest = this.raftingForm.value.guest;
                   x.variations = [];
                    try {
                      this.http.get(environment.apiUrl + `/range/actualprice?merchantActivityId=${x.id}&date=${this.raftingForm.value.date} `).subscribe((data: any) => {
                        if (data.status === "SUCCESS") {
                            x.price=data.price;
                        }
                      })
                    } catch (error) {
                      console.log(error)
                    }
                    x.shopsrc = [];
                    try {
                      this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantshopprofile&dataforid=${x.merchantid}`).subscribe((data: any) => {
                        if (data.status === "SUCCESS") {
                          for (const upload of data.uploads) {
                            this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                              if (res.status === "SUCCESS") {
                                x.shopsrc.push(res.upload.thumbnail)
                              }
                            });
                          }
                        }
                      })
                    } catch (error) {
                      console.log(error)
                    }
                    x.name = x.merchantname;
                    x.city = x.merchantlocationname;
                    x.latitude = x.merchantlatitude;
                    x.longitude = x.merchantlongitude;
                    if (x.merchantlatitude != undefined && x.merchantlongitude != undefined && x.merchantlatitude != null && x.merchantlongitude != null && x.merchantlatitude != 0 && x.merchantlongitude) {
                      this.dataService.getActivitiesDistance(this.userLocation.latitude, this.userLocation.longitude, x.merchantlatitude, x.merchantlongitude).subscribe({
                        next: (res: any) => {
                          let km = Math.round(res.distance_in_km)
                          x.distanceAway = `${km}${(Number(km) > 1) ? " Kms away" : "Km away"}`;
                        }, error: (error) => {
                          console.log(error)
                        }
                      }
                      );
                    } else {
                      x.distanceAway = "0Km away";
                    }
                  })

                  // let minNumber =  Math.min(...start_time);
                  // let maxNumber =  Math.max(...end_time);
                  // this.newTimings=[];
                  //   for(let i=minNumber; i<=maxNumber; i++){
                  //     this.newTimings.push(this.timings[i]);
                  //   }
                  this.loadingprovidedByRafting = false;
                  this.providedByRafting = data.merchantactivities;
                  const element = this.elementRef.nativeElement.querySelector(`.rafting-section`);
                  if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                  }
                  setTimeout(() => {
                    this.map = new mapboxgl.Map({
                      container: 'activitymap',
                      style: this.style,
                      zoom: 13,
                      center: [this.filteredRaftingData[0].longitude, this.filteredRaftingData[0].latitude],
                      accessToken: environment.mapbox.accessToken,
                      interactive: false
                    })

                    const marker = new mapboxgl.Marker()
                      .setLngLat([this.filteredRaftingData[0].longitude, this.filteredRaftingData[0].latitude])
                      .addTo(this.map);
                  })
                }
              }
            })
          } else {
            this.snackBar.open('Some error occurs.', 'close', { duration: 2000, verticalPosition: 'top' },);
          }
        }
      }
    })
    // } else {
    //   if(!this.ifMobileApp()){
    //     let queryParams = this.route.snapshot.queryParams;
    //     const currentUrl = this.router.url.split('?')[0];
    //     let q = Object.keys(queryParams).map(k => k !== 'dialogue' ? `${k}=${queryParams[k]}` : '').filter(k => k !== '').join('&');
    //     q = q !== '' ? q + '&dialogue=user' : q + `dialogue=user`
    //     window.location.href = `${currentUrl}?${q}`;
    //   }else{
    //     this.openLoginDialog();
    //   }
    // }

  }


  submitquickActivities() {
    this.isSubmittingActivities = true;
    if (this.activitiesForm.invalid) {
      return;
    }
    this.showFilteredActivities = true;
    sessionStorage.setItem('userData', JSON.stringify({ category: 'activity', value: this.activitiesForm.value, isSubmittingActivities: true }))
    this.filteredActivitiesData = [];
    this.filteredQuickActivityPage = 1;
    this.loadMoreFilteredQuickActivities();

  }
  async searchRentalMerchants() {
    this.isSubmittingBikerental = true;
    if (this.rentalForm.invalid) {
      return;
    }
    if (!this.rentalForm.invalid) {
      const dropdownDate = new Date(this.rentalForm.value.dropdowndate);
      let date = new Date();
      if (this.rentalForm && this.rentalForm.get('date')?.value) {
        const dateFormControlValue = this.rentalForm.get('date')?.value;
        date = new Date(dateFormControlValue);
      }
      if (isNaN(dropdownDate.getTime()) || dropdownDate < date) {
        this.snackBar.open('Pickup Date should be lesser than Dropdown Date.', 'close', { duration: 2000, verticalPosition: 'top' },);
        return;
      }

      if (new Date(dropdownDate).getTime() === new Date(date).getTime()) {
        const combinedPickupDateTimeString = this.rentalForm.value.date + ' ' + this.rentalForm.value.time;
        const combinedDropDownDateTimeString = this.rentalForm.value.dropdowndate + ' ' + this.rentalForm.value.dropdowntime;
        if (new Date(combinedDropDownDateTimeString) < new Date(combinedPickupDateTimeString)) {
          this.snackBar.open('Pickup Time should be lesser than Dropdown Time.', 'close', { duration: 2000, verticalPosition: 'top' },);
          return;
        }
      }
      if ((this.rentalForm.value.date == this.rentalForm.value.dropdowndate) && (this.rentalForm.value.time == this.rentalForm.value.dropdowntime)) {
        this.snackBar.open('Pickup Time should be lesser than Dropdown Time.', 'close', { duration: 2000, verticalPosition: 'top' },);
        return;
      }
    }
    if (this.rentalForm.value.inventoryvariations.length > 0) {
      this.rentalForm.value.inventoryvariations.forEach((x: any) => {
        x.variationvalueid = this.rentalForm.value.variations.find((y: any) => y.variationid == x.variationid).variationvalueid
      })
    }

    let data: any = {};
    data.activityId = this.rentalForm.value.activityId;
    data.variations = [];
    this.rentalForm.value.variations.forEach((x: any) => {
      data.variations.push({ variationtypeid: x.variationid, variationvalueid: x.variationvalueid })
    })
    if (this.locationId != null) {
      data.locationId = this.locationId;
    }
    if (!this.showFilteredBikes) {
      this.filterRentalMerchantsloading = true;
      this.showFilteredRentalMerchants = true;
      let bookingsDates: any = [];
      let dates = this.getDates(new Date(this.rentalForm.value.date), new Date(this.rentalForm.value.dropdowndate));
      bookingsDates = dates;
      this.rentalForm.get('guest').setValue(bookingsDates.length);
      this.filteredBikeCard = this.nonFilteredBikeCard.find((bike: any) => {
        return data.variations.some((variation: any) =>
          variation.variationvalueid === bike.id &&
          variation.variationtypeid === bike.variationtypeid
        );
      });
      if (this.rentalForm.value.inventoryvariations.length > 0) {
        let inventoryMerchants: any = [];
        let inventoryInputData: any = {};
        inventoryInputData.activityId = this.rentalForm.value.activityId;
        inventoryInputData.variations = [];
        this.rentalForm.value.inventoryvariations.forEach((x: any) => {
          inventoryInputData.variations.push({ variationid: x.variationid, variationvalueid: x.variationvalueid })
        })
        if (this.locationId != null) {
          inventoryInputData.locationId = this.locationId;
        }
        let inventoryRes: any = await this.http.post(environment.apiUrl + `/merchantinventories/filterMerchant`, inventoryInputData).toPromise();
        if (inventoryRes.status === "SUCCESS") {
          inventoryMerchants = inventoryRes.merchantinventories;
        }

        if (inventoryMerchants.length == 0) {
          this.snackBar.open('Please fill all the fields.', 'close', { duration: 2000, verticalPosition: 'top' });
        } else {
          this.filteredInventoryMerchants = [];
          for (let i = 0; i < inventoryMerchants.length; i++) {
            let inventoryRes: any = await this.http.post(environment.apiUrl + `/merchantinventories/checkavailabilitydates`, { bookingDate: bookingsDates, merchantInventoryId: inventoryMerchants[i].id }).toPromise();
            if (inventoryRes.status === "SUCCESS") {
              if (inventoryRes.availability) {
                this.filteredInventoryMerchants.push(inventoryMerchants[i]);
                this.filteredBikeCard.availability = true;
              }
            }
          }
        }
      }
      this.http.post(environment.apiUrl + `/merchantactivities/filterMerchant`, data).subscribe(async (data: any) => {
        {
          if (data.status === "SUCCESS") {
            this.filterRentalMerchantsloading = false;
            this.showFilteredRentalMerchants = true;
            this.filteredRentalMerchants = [];
            for (let i=0; i<data.merchantactivities.length; i++){
              if (this.filteredInventoryMerchants.find((y: any) => y.merchantid == data.merchantactivities[i].merchantid)) {
                data.merchantactivities[i].rating = Math.round(data.merchantactivities[i].average_star);
                try {
                  let price_res:any = await this.http.get(environment.apiUrl + `/range/actualprice?merchantActivityId=${data.merchantactivities[i].id}&date=${this.rentalForm.value.date} `).toPromise();
                    if (price_res.status === "SUCCESS") {
                      data.merchantactivities[i].price= price_res.price;
                      if(price_res.price != 0){
                        this.showBikeFilter = true;
                      }
                    }
                } catch (error) {
                  console.log(error)
                }
                if(!this.filteredRentalMerchants.find((x:any)=> x.id === data.merchantactivities[i].id)?.id){
                  this.filteredRentalMerchants.push(data.merchantactivities[i]);
                }
              }
            }
            this.filteredRentalMerchants.forEach((merchant: any) => {
              try{
                this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId: merchant.merchantid,activityId: merchant.activityid ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
                  if (data.status === "SUCCESS" ) {
                    merchant.hasCoupon = data.coupons;
                    }else{
                      merchant.hasCoupon = false;
                    }
                    })
              }catch(error){
                console.log(error);
                merchant.hasCoupon = false;
              }
              merchant.src = [];
              merchant.shopsrc = [];
              if (merchant.merchantlatitude != undefined && merchant.merchantlongitude != undefined && merchant.merchantlatitude != null && merchant.merchantlongitude != null && merchant.merchantlatitude != 0 && merchant.merchantlongitude) {
                this.dataService.getActivitiesDistance(this.userLocation.latitude, this.userLocation.longitude, merchant.merchantlatitude, merchant.merchantlongitude).subscribe({
                  next: (res: any) => {
                    let km = Math.round(res.distance_in_km)
                    merchant.distanceAway = `${km}${(Number(km) > 1) ? " Kms away" : "Km away"}`;
                  }, error: (error) => {
                    console.log(error)
                  }
                }
                );
              } else {
                merchant.distanceAway = "0Km away";
              }
              try {
                this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantprofile&dataforid=${merchant.merchantid}`).subscribe((data: any) => {
                  if (data.status === "SUCCESS") {
                    for (const upload of data.uploads) {
                      this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                        if (res.status === "SUCCESS") {
                          merchant.src.push(res.upload.thumbnail)
                        }
                      });
                    }
                  }
                })
              } catch (error) {
                console.log(error)
              }
              try {
                this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantshopprofile&dataforid=${merchant.merchantid}`).subscribe((data: any) => {
                  if (data.status === "SUCCESS") {
                    for (const upload of data.uploads) {
                      this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                        if (res.status === "SUCCESS") {
                          merchant.shopsrc.push(res.upload.thumbnail)
                        }
                      });
                    }
                  }
                })
              } catch (error) {
                console.log(error)
              }
            });
            this.filteredRentalMerchants.sort((a, b) => Number(a.price) - Number(b.price));
          }
        }
      })
    } else {
      // let offsetTop = this.filteredBikeDiv.nativeElement.offsetTop;
      // window.scrollTo({ top: offsetTop, behavior: 'smooth' });
      setTimeout(() => {
        const isMobile = window.innerWidth < 768;
        const element = this.elementRef.nativeElement.querySelector('.rental-section');
        if (element) {
          window.scroll(0, element.offsetTop + 30);
          if (isMobile) {
            element.scrollIntoView({ top: 0, behavior: 'smooth', block: 'start', inline: 'nearest' });
          }
        }
      });
      let bookingsDates: any = [];
      let dates = this.getDates(new Date(this.rentalForm.value.date), new Date(this.rentalForm.value.dropdowndate));
      bookingsDates = dates;
      this.rentalForm.get('guest').setValue(bookingsDates.length);
      this.filteredBikeCard = this.nonFilteredBikeCard.find((bike: any) => {
        return data.variations.some((variation: any) =>
          variation.variationvalueid === bike.id &&
          variation.variationtypeid === bike.variationtypeid
        );
      });
      if (this.rentalForm.value.inventoryvariations.length > 0) {
        let inventoryMerchants: any = [];
        let inventoryInputData: any = {};
        inventoryInputData.activityId = this.rentalForm.value.activityId;
        inventoryInputData.variations = [];
        this.rentalForm.value.inventoryvariations.forEach((x: any) => {
          inventoryInputData.variations.push({ variationid: x.variationid, variationvalueid: x.variationvalueid })
        })
        if (this.locationId != null) {
          inventoryInputData.locationId = this.locationId;
        }
        let inventoryRes: any = await this.http.post(environment.apiUrl + `/merchantinventories/filterMerchant`, inventoryInputData).toPromise();
        if (inventoryRes.status === "SUCCESS") {
          inventoryMerchants = inventoryRes.merchantinventories;
        }

        if (inventoryMerchants.length == 0) {
          this.snackBar.open('Please fill all the fieldss.', 'close', { duration: 2000, verticalPosition: 'top' });
        } else {
          this.filteredInventoryMerchants = [];
          for (let i = 0; i < inventoryMerchants.length; i++) {
            let inventoryRes: any = await this.http.post(environment.apiUrl + `/merchantinventories/checkavailabilitydates`, { bookingDate: bookingsDates, merchantInventoryId: inventoryMerchants[i].id }).toPromise();
            if (inventoryRes.status === "SUCCESS") {
              if (inventoryRes.availability) {
                this.filteredInventoryMerchants.push(inventoryMerchants[i]);
                this.filteredBikeCard.availability = true;
              }
            }
          }
        }
      }
    }
    if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
      let userData = JSON.parse(sessionStorage.getItem('userData'));
      if (userData.category === 'bike-rental' && userData.availability) {
        this.navigateRentalMerchants(userData.id, userData.availability, userData.datafrom, false)
      }
    } else {
      sessionStorage.setItem('userData', JSON.stringify({ category: 'bike-rental', value: this.rentalForm.value, isSubmittingBikerental: true }))
    }
  }

  async navigateRentalMerchants(id: any, availability: any, datafrom: any, enquireFrom:boolean) {
    this.showBikeFilter = false;
    if(!enquireFrom){
    this.showDateField = true;
    this.showShadow = true;

    // window.scroll(0, 0);
    if ((this.rentalForm.invalid || this.filteredBikeCard?.id != id) && datafrom == "allCard") {
      this.filteredBikeCard = this.bikeCard.find((x: any) => x.id == id);
      const variationsArray = this.rentalForm.get('variations') as FormArray;
      const indexToUpdateVariationTypeid = variationsArray.value.findIndex((obj: any) => obj.variationid == this.filteredBikeCard.parentvariationtypeid);
      if (indexToUpdateVariationTypeid !== -1) {
        const formGroupToUpdate = variationsArray.at(indexToUpdateVariationTypeid) as FormGroup;
        if (formGroupToUpdate.value.variationvalueid != this.filteredBikeCard.parentvariationvalueid) {
          formGroupToUpdate.get('variationvalueid').setValue(this.filteredBikeCard.parentvariationvalueid);
          this.changeRentalDefaultVariation(indexToUpdateVariationTypeid)
        }
      }
      const indexToUpdateVariationValueid = variationsArray.value.findIndex((obj: any) => obj.variationid == this.filteredBikeCard.variationtypeid);
      if (indexToUpdateVariationValueid !== -1) {
        const formGroupToUpdate = variationsArray.at(indexToUpdateVariationValueid) as FormGroup;
        formGroupToUpdate.get('variationvalueid').setValue(this.filteredBikeCard.id);
      }
    }
    this.isSubmittingBikerental = true;
    if (this.rentalForm.invalid) {
      window.scroll(0, 0)
      return;
    }
    if (!availability) {
      this.snackBar.open('The selected bike is unavailable on this date. Please consider choosing a different date, bike.', 'close', { duration: 2000, verticalPosition: 'top' });
      return;
    }
    if (!this.rentalForm.invalid) {
      const dropdownDate = new Date(this.rentalForm.value.dropdowndate);
      let date = new Date();
      if (this.rentalForm && this.rentalForm.get('date')?.value) {
        const dateFormControlValue = this.rentalForm.get('date')?.value;
        date = new Date(dateFormControlValue);
      }
      if (isNaN(dropdownDate.getTime()) || dropdownDate < date) {
        this.snackBar.open('Pickup Date should be lesser than Dropdown Date.', 'close', { duration: 2000, verticalPosition: 'top' },);
        return;
      }

      if (new Date(dropdownDate).getTime() === new Date(date).getTime()) {
        const combinedPickupDateTimeString = this.rentalForm.value.date + ' ' + this.rentalForm.value.time;
        const combinedDropDownDateTimeString = this.rentalForm.value.dropdowndate + ' ' + this.rentalForm.value.dropdowntime;
        if (new Date(combinedDropDownDateTimeString) < new Date(combinedPickupDateTimeString)) {
          this.snackBar.open('Pickup Time should be lesser than Dropdown Time.', 'close', { duration: 2000, verticalPosition: 'top' },);
          return;
        }
      }
    }

    if (this.rentalForm.value.inventoryvariations.length > 0) {
      this.rentalForm.value.inventoryvariations.forEach((x: any) => {
        x.variationvalueid = this.rentalForm.value.variations.find((y: any) => y.variationid == x.variationid).variationvalueid
      })
    }
    let bookingsDates: any = [];
    let dates = this.getDates(new Date(this.rentalForm.value.date), new Date(this.rentalForm.value.dropdowndate));
    bookingsDates = dates;
    this.rentalForm.get('guest').setValue(bookingsDates.length);
    if (this.rentalForm.value.inventoryvariations.length > 0) {
      let inventoryMerchants: any = [];
      let inventoryInputData: any = {};
      inventoryInputData.activityId = this.rentalForm.value.activityId;
      inventoryInputData.variations = [];
      this.rentalForm.value.inventoryvariations.forEach((x: any) => {
        inventoryInputData.variations.push({ variationid: x.variationid, variationvalueid: x.variationvalueid })
      })
      if (this.locationId != null) {
        inventoryInputData.locationId = this.locationId;
      }
      let inventoryRes: any = await this.http.post(environment.apiUrl + `/merchantinventories/filterMerchant`, inventoryInputData).toPromise();
      if (inventoryRes.status === "SUCCESS") {
        inventoryMerchants = inventoryRes.merchantinventories;
      }

      if (inventoryMerchants.length == 0) {
        this.snackBar.open('Please fill all the fields.', 'close', { duration: 2000, verticalPosition: 'top' });
      } else {
        this.filteredInventoryMerchants = [];
        for (let i = 0; i < inventoryMerchants.length; i++) {
          let inventoryRes: any = await this.http.post(environment.apiUrl + `/merchantinventories/checkavailabilitydates`, { bookingDate: bookingsDates, merchantInventoryId: inventoryMerchants[i].id }).toPromise();
          if (inventoryRes.status === "SUCCESS") {
            if (inventoryRes.availability) {
              this.filteredInventoryMerchants.push(inventoryMerchants[i]);
              this.filteredBikeCard.availability = true;
            }
          }
        }
      }
    }

    this.showFilteredRentalMerchants = true;
    this.showFilteredBikes = false;
    this.filterRentalMerchantsloading = true;
    sessionStorage.setItem('userData', JSON.stringify({ category: 'bike-rental', value: this.rentalForm.value, showFilteredRentalMerchants: true, id: id, availability: availability, datafrom: datafrom }));
    let data: any = this.rentalForm.value;
    data.activityId = this.rentalForm.value.activityId;
    data.variations.forEach((x: any, index: any) => {
      x.variationvalue = this.variationValues[index].find((y: any) => y.variationvalues.id == x.variationvalueid).variationvalues.value
    })
    // data.inventoryvariations.forEach((x: any, index: any) => {
    //   x.variationvalueid = data.variations.find((y: any) => y.variationid == x.variationid)?.variationvalueid
    //   x.variationvalue = this.variationValues[index].find((y: any) => y.variationvalues.id == x.variationvalueid).variationvalues.value
    // })

    let filterData: any = {};
    filterData.activityId = this.rentalForm.value.activityId;
    filterData.variations = [];
    this.rentalForm.value.variations.forEach((x: any) => {
      filterData.variations.push({ variationtypeid: x.variationid, variationvalueid: x.variationvalueid })
    })
    if (this.locationId != null) {
      filterData.locationId = this.locationId;
    }
    this.filteredRentalMerchants = [];
    let merchantData: any = await this.http.post(environment.apiUrl + `/merchantactivities/filterMerchant`, filterData).toPromise();
    // merchantData = merchantData.merchantactivities.filter((x: any) => x.merchantid == this.selectedRentalMerchant.id);
    // merchantData = merchantData.filter(merchant => {
    //   return this.rentalForm.value.variations.every((variationItem: any) => {
    //     // Check if the variation exists in the merchant.variations array
    //     return merchant.variations.some((merchantVariation: any) => {
    //       return (
    //         merchantVariation.variationtypeid == parseInt(variationItem.variationid) &&
    //         merchantVariation.variationvalueid == variationItem.variationvalueid
    //       );
    //     });
    //   });
    // });
    if (merchantData.length == 0) {
      this.snackBar.open('Please fill all the fields.', 'close', { duration: 2000, verticalPosition: 'top' },);
      return;
    } else {
      // this.showDateField = false;
      // this.showShadow = false;
      this.filteredRentalMerchants = [];
      // merchantData.merchantactivities.forEach((x: any) => {
      //   if (this.filteredInventoryMerchants.find((y: any) => y.merchantid == x.merchantid)) {
      //     this.filteredRentalMerchants.push(x)
      //   }
      // })
     
      for (let i=0; i<merchantData.merchantactivities.length; i++){
        if (this.filteredInventoryMerchants.find((y: any) => y.merchantid == merchantData.merchantactivities[i].merchantid)) {
          try {
            let price_res:any = await this.http.get(environment.apiUrl + `/range/actualprice?merchantActivityId=${merchantData.merchantactivities[i].id}&date=${this.rentalForm.value.date} `).toPromise();
              if (price_res.status === "SUCCESS") {
                merchantData.merchantactivities[i].price= price_res.price;
                if(price_res.price != 0){
                  this.showBikeFilter = true;
                }
              }
          } catch (error) {
            console.log(error)
          }
          if(!this.filteredRentalMerchants.find((x:any)=> x.id === merchantData.merchantactivities[i].id)?.id){
            this.filteredRentalMerchants.push(merchantData.merchantactivities[i]);
          }
          // this.filteredRentalMerchants.push(merchantData.merchantactivities[i]);
        }
      }
      setTimeout(() => {
        const isMobile = window.innerWidth < 768;
        const element = this.elementRef.nativeElement.querySelector('.rental-section');
        if (element) {
          window.scroll(0, element.offsetTop + 30);
          if (isMobile) {
            element.scrollIntoView({ top: 0, behavior: 'smooth', block: 'start', inline: 'nearest' });
          }
        }
      });
      this.filteredRentalMerchants.sort((a, b) => Number(a.price) - Number(b.price));
      this.filterRentalMerchantsloading = false;
      this.filteredRentalMerchants.forEach((merchant: any) => {
        merchant.src = [];
        merchant.shopsrc = [];
        try{
          this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId: merchant.merchantid,activityId: merchant.activityid ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
            if (data.status === "SUCCESS" ) {
              merchant.hasCoupon = data.coupons;
              }else{
                merchant.hasCoupon = false;
              }
              })
        }catch(error){
          console.log(error);
          merchant.hasCoupon = false;
        }
        if (merchant.merchantlatitude != undefined && merchant.merchantlongitude != undefined && merchant.merchantlatitude != null && merchant.merchantlongitude != null && merchant.merchantlatitude != 0 && merchant.merchantlongitude) {
          this.dataService.getActivitiesDistance(this.userLocation.latitude, this.userLocation.longitude, merchant.merchantlatitude, merchant.merchantlongitude).subscribe({
            next: (res: any) => {
              let km = Math.round(res.distance_in_km)
              merchant.distanceAway = `${km}${(Number(km) > 1) ? " Kms away" : "Km away"}`;
            }, error: (error) => {
              console.log(error)
            }
          }
          );
        } else {
          merchant.distanceAway = "0Km away";
        }
        try {
          this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantprofile&dataforid=${merchant.merchantid}`).subscribe((data: any) => {
            if (data.status === "SUCCESS") {
              for (const upload of data.uploads) {
                this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                  if (res.status === "SUCCESS") {
                    merchant.src.push(res.upload.thumbnail)
                  }
                });
              }
            }
          })
        } catch (error) {
          console.log(error)
        }
        try {
          this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantshopprofile&dataforid=${merchant.merchantid}`).subscribe((data: any) => {
            if (data.status === "SUCCESS") {
              for (const upload of data.uploads) {
                this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                  if (res.status === "SUCCESS") {
                    merchant.shopsrc.push(res.upload.thumbnail)
                  }
                });
              }
            }
          })
        } catch (error) {
          console.log(error)
        }
        try {
          this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'merchant', dataForId: merchant.merchantid }, { responseType: 'json' }).subscribe((data: any) => {
            if (data.status === "SUCCESS") {
              merchant.avgReview = Math.round(data.reviews.average);
              merchant.rating = data.reviews.total;
            }
          });
        } catch (error) {
          console.log(error)
        }
        // try {
        //   this.http.post(environment.apiUrl + `/merchantactivities/pricestartsfrom`, { activityId: merchant.activities[0].activityid, merchantId: merchant.id }, { responseType: 'json' }).subscribe((data: any) => {
        //     if (data.status === "SUCCESS") {
        //       merchant.price = data.priceStartsFrom;
        //     }
        //   });
        // } catch (error) {
        //   console.log(error)
        // }
      })
    }
    window.scroll(0, 0)
    // this.router.navigate(['/book-merchant/scooty-rental'], { queryParams: { merchantId: this.selectedRentalMerchant.id, merchantactivityid: merchantData[0].id, activityId: data.activityId, type: "User", data: JSON.stringify(data) } })
    }else{

      this.showDateField = true;
      this.showShadow = true;
      this.showFilteredRentalMerchants = true;
      this.showFilteredBikes = false;
      this.filterRentalMerchantsloading = true;

        let selectedCard:any = this.bikeCard.find((x: any) => x.id == id);
        let filter: any = {};
        if (this.locationId != null) {
          filter.locationId = this.locationId;
        }
        let values: any = await this.http.post(environment.apiUrl + `/merchants/merchantcategory`, { ...filter, category: 'bikerental' }, { responseType: 'json' }).toPromise();
        if (values.status === "SUCCESS") {
          if (values.merchants.length > 0) {
            let activityId = values.merchants[0].activities.find((x: any) => x.activitystatus == 1 && x.activitiecategory == "bikerental").activityid;
            this.rentalForm.get('activityId')?.setValue(activityId);
          }
        }

        let filterData: any = {};
        filterData.activityId = this.rentalForm.value.activityId;
        filterData.variations = [];
        this.rentalForm.value.inventoryvariations.forEach((x: any) => {
          let variationValueId ="";
            if(selectedCard.parentvariationtypeid == x.variationid){
              variationValueId = selectedCard.parentvariationvalueid
            }else{
              variationValueId = selectedCard.id
            }
            filterData.variations.push({ variationtypeid: x.variationid, variationvalueid: variationValueId })
        })
        if (this.locationId != null) {
          filterData.locationId = this.locationId;
        }
        this.filteredRentalMerchants = [];
        let merchantData: any = await this.http.post(environment.apiUrl + `/merchantactivities/filterMerchant`, filterData).toPromise();
          if (merchantData.length == 0) {
          this.snackBar.open('Please fill all the fields.', 'close', { duration: 2000, verticalPosition: 'top' },);
          return;
        } else {
            this.filteredRentalMerchants = [];
            for(let i=0; i<merchantData.merchantactivities.length; i++){
              if(this.rentalForm.value.date != ''){
            try {
              let price_res:any = await this.http.get(environment.apiUrl + `/range/actualprice?merchantActivityId=${merchantData.merchantactivities[i].id}&date=${this.rentalForm.value.date} `).toPromise();
                if (price_res.status === "SUCCESS") {
                  merchantData.merchantactivities[i].price= price_res.price;
                  if(price_res.price != 0){
                    this.showBikeFilter = true;
                  }
                }
            } catch (error) {
              console.log(error)
            }
          }else{
            merchantData.merchantactivities[i].price= 0;
          }
          }
            // merchantData.merchantactivities.forEach((x:any)=>{
            //     x.price = 0;
            // })
            this.filteredRentalMerchants = merchantData.merchantactivities;
            this.filterRentalMerchantsloading = false;
            this.filteredRentalMerchants.forEach((merchant: any) => {
              merchant.src = [];
              merchant.shopsrc = [];
              try{
                this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId: merchant.merchantid,activityId: merchant.activityid ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
                  if (data.status === "SUCCESS" ) {
                    merchant.hasCoupon = data.coupons;
                    }else{
                      merchant.hasCoupon = false;
                    }
                    })
              }catch(error){
                console.log(error);
                merchant.hasCoupon = false;
              }
              if (merchant.merchantlatitude != undefined && merchant.merchantlongitude != undefined && merchant.merchantlatitude != null && merchant.merchantlongitude != null && merchant.merchantlatitude != 0 && merchant.merchantlongitude) {
                this.dataService.getActivitiesDistance(this.userLocation.latitude, this.userLocation.longitude, merchant.merchantlatitude, merchant.merchantlongitude).subscribe({
                  next: (res: any) => {
                    let km = Math.round(res.distance_in_km)
                    merchant.distanceAway = `${km}${(Number(km) > 1) ? " Kms away" : "Km away"}`;
                  }, error: (error) => {
                    console.log(error)
                  }
                }
                );
              } else {
                merchant.distanceAway = "0Km away";
              }
              try {
                this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantprofile&dataforid=${merchant.merchantid}`).subscribe((data: any) => {
                  if (data.status === "SUCCESS") {
                    for (const upload of data.uploads) {
                      this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                        if (res.status === "SUCCESS") {
                          merchant.src.push(res.upload.thumbnail)
                        }
                      });
                    }
                  }
                })
              } catch (error) {
                console.log(error)
              }
              try {
                this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantshopprofile&dataforid=${merchant.merchantid}`).subscribe((data: any) => {
                  if (data.status === "SUCCESS") {
                    for (const upload of data.uploads) {
                      this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                        if (res.status === "SUCCESS") {
                          merchant.shopsrc.push(res.upload.thumbnail)
                        }
                      });
                    }
                  }
                })
              } catch (error) {
                console.log(error)
              }
              try {
                this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'merchant', dataForId: merchant.merchantid }, { responseType: 'json' }).subscribe((data: any) => {
                  if (data.status === "SUCCESS") {
                    merchant.avgReview = Math.round(data.reviews.average);
                    merchant.rating = data.reviews.total;
                  }
                });
              } catch (error) {
                console.log(error)
              }
            })
        }
        
    } 
  }

  selectBike(activityid: any, merchantid: any, locationid: any) {
    this.isSubmittingBikerental = true;
    if (this.rentalForm.invalid) {
      setTimeout(() => {
        const isMobile = window.innerWidth < 768;
        const element = this.elementRef.nativeElement.querySelector('.rental-section');
        if (element) {
          window.scroll(0, element.offsetTop + 30);
          if (isMobile) {
            element.scrollIntoView({ top: 0, behavior: 'smooth', block: 'start', inline: 'nearest' });
          }
        }
      });
      // window.scroll(0, 0)
      return;
    }
    this.router.navigate(['/selectbike'], {
      state: { dataToPass: { activityId: activityid, merchantId: merchantid, locationId: locationid } },
    });
  }

  formatDate(date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  getDates(startDate: any, endDate: any) {
    const dateArray = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dateArray.push(this.formatDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
  }

  async bookMerchantRental(merchantactivityid: any, activityid: any, merchantid: any) {
    this.isSubmittingBikerental = true;
    if (this.rentalForm.invalid) {
      // window.scrollTo(0, 0)
      setTimeout(() => {
        const isMobile = window.innerWidth < 768;
        const element = this.elementRef.nativeElement.querySelector('.rental-section');
        if (element) {
          window.scroll(0, element.offsetTop + 30);
          if (isMobile) {
            element.scrollIntoView({ top: 0, behavior: 'smooth', block: 'start', inline: 'nearest' });
          }
        }
      });
      return;
    }

    if (!this.rentalForm.invalid) {
      const dropdownDate = new Date(this.rentalForm.value.dropdowndate);
      let date = new Date();
      if (this.rentalForm && this.rentalForm.get('date')?.value) {
        const dateFormControlValue = this.rentalForm.get('date')?.value;
        date = new Date(dateFormControlValue);
      }
      if (isNaN(dropdownDate.getTime()) || dropdownDate < date) {
        this.snackBar.open('Pickup Date should be lesser than Dropdown Date.', 'close', { duration: 2000, verticalPosition: 'top' },);
        return;
      }

      if (new Date(dropdownDate).getTime() === new Date(date).getTime()) {
        const combinedPickupDateTimeString = this.rentalForm.value.date + ' ' + this.rentalForm.value.time;
        const combinedDropDownDateTimeString = this.rentalForm.value.dropdowndate + ' ' + this.rentalForm.value.dropdowntime;
        if (new Date(combinedDropDownDateTimeString) < new Date(combinedPickupDateTimeString)) {
          this.snackBar.open('Pickup Time should be lesser than Dropdown Time.', 'close', { duration: 2000, verticalPosition: 'top' },);
          return;
        }
      }
    }


    let bookingsDates: any = [];
    let dates = this.getDates(new Date(this.rentalForm.value.date), new Date(this.rentalForm.value.dropdowndate));
    bookingsDates = dates;
    this.rentalForm.get('guest').setValue(bookingsDates.length);

    let data: any = this.rentalForm.value;
    data.activityId = this.rentalForm.value.activityId;
    data.variations.forEach((x: any, index: any) => {
      x.variationvalue = this.variationValues[index].find((y: any) => y.variationvalues.id == x.variationvalueid).variationvalues.value
    })
    data.inventoryvariations.forEach((x: any, index: any) => {
      x.variationvalueid = data.variations.find((y: any) => y.variationid == x.variationid)?.variationvalueid
      x.variationvalue = this.variationValues[index].find((y: any) => y.variationvalues.id == x.variationvalueid).variationvalues.value
    })

    if (this.rentalForm.value.inventoryvariations.length > 0) {
      let inventoryMerchants: any = [];
      let inventoryInputData: any = {};
      inventoryInputData.activityId = this.rentalForm.value.activityId;
      inventoryInputData.variations = [];
      this.rentalForm.value.inventoryvariations.forEach((x: any) => {
        inventoryInputData.variations.push({ variationid: x.variationid, variationvalueid: x.variationvalueid })
      })
      if (this.locationId != null) {
        inventoryInputData.locationId = this.locationId;
      }
      let inventoryRes: any = await this.http.post(environment.apiUrl + `/merchantinventories/filterMerchant`, inventoryInputData).toPromise();
      if (inventoryRes.status === "SUCCESS") {
        inventoryMerchants = inventoryRes.merchantinventories;
        inventoryMerchants = inventoryMerchants.filter((x: any) => x.merchantid == merchantid);
        inventoryMerchants = inventoryMerchants.filter(merchant => {
          return merchant.inventoryvariations.every((variationItem: any) => {
            return this.rentalForm.value.inventoryvariations.some((merchantVariation: any) => {
              return (
                merchantVariation.variationid == variationItem.variationid &&
                merchantVariation.variationvalueid == variationItem.variationvalueid
              );
            });
          });
        });
      }
      if (inventoryMerchants.length > 0) {
        data.merchantinventoryid = inventoryMerchants[0].id;
      }
    }
    this.router.navigate(['/book-merchant/scooty-rental'], { queryParams: { merchantId: merchantid, merchantactivityid: merchantactivityid, activityId: activityid, type: "User", data: JSON.stringify(data) } })
  }

  async searchRentalBikes(id: any) {
    let merchant = this.filteredRentalMerchants.find((x: any) => x.id == id);
    let data: any = this.rentalForm.value;
    data.variations.forEach((x: any, index: any) => {
      x.variationvalue = this.variationValues[index].find((y: any) => y.variationvalues.id == x.variationvalueid).variationvalues.value
    })
    data.inventoryvariations.forEach((x: any, index: any) => {
      x.variationvalueid = data.variations.find((y: any) => y.variationid == x.variationid)?.variationvalueid
      x.variationvalue = this.variationValues[index].find((y: any) => y.variationvalues.id == x.variationvalueid).variationvalues.value
    })

    if (this.rentalForm.value.inventoryvariations.length > 0) {
      let inventoryMerchants = [];
      let inventoryInputData: any = {};
      inventoryInputData.activityId = this.rentalForm.value.activityId;
      inventoryInputData.variations = [];
      this.rentalForm.value.inventoryvariations.forEach((x: any) => {
        inventoryInputData.variations.push({ variationid: x.variationid, variationvalueid: x.variationvalueid })
      })
      if (this.locationId != null) {
        inventoryInputData.locationId = this.locationId;
      }
      let inventoryRes: any = await this.http.post(environment.apiUrl + `/merchantinventories/filterMerchant`, inventoryInputData).toPromise();
      if (inventoryRes.status === "SUCCESS") {
        inventoryMerchants = inventoryRes.merchantinventories;
      }

      this.filteredRentalMerchants = this.filteredRentalMerchants.filter((merchant: any) => inventoryMerchants.some(obj => obj.merchantid == merchant.merchantid));
      this.filteredRentalMerchants = this.filteredRentalMerchants.map((item: any) => {
        let inventory = inventoryMerchants.find(obj => obj.merchantid == item.merchantid);
        return { ...item, merchantinventoryid: inventory.id ? inventory.id : '', merchantinventorycount: inventory.count ? inventory.count : '' };
      });
    }

    let merchantinventoryid = this.filteredRentalMerchants[0].merchantinventoryid;
    if (merchantinventoryid && merchantinventoryid != "") {
      let inventoryRes: any = await this.http.post(environment.apiUrl + `/merchantinventories/checkAvailability`, { bookingDate: this.rentalForm.value.date, merchantInventoryId: merchantinventoryid }).toPromise();
      if (inventoryRes.status === "SUCCESS") {
        if (inventoryRes.availability <= 0) {
          this.snackBar.open('The selected bike is unavailable on this date. Please consider choosing a different date, bike, or merchant.', 'close', { duration: 2000, verticalPosition: 'top' });
          return;
        }
      }
      data.merchantinventoryid = merchantinventoryid;
    }
    this.router.navigate(['/book-merchant/scooty-rental'], { queryParams: { merchantId: merchant.merchantid, merchantactivityid: id, activityId: merchant.activityid, type: "User", data: JSON.stringify(data) } })
  }

  searchMerchantBikes(id: any) {
    this.showFilteredBikes = true;
    this.loadingFilteredBikes = true;
    this.showFilteredRentalMerchants = false;
    this.loadmerchantBikes(id);
  }

  async loadmerchantBikes(merchantid: any) {
    this.loadingFilteredBikes = true;
    this.rentalOfferAvailable = false;
    // const variationArray: any = this.rentalForm.get('variations') as FormArray;
    // variationArray.clear();
    const inventoryvariationArray: any = this.rentalForm.get('inventoryvariations') as FormArray;
    inventoryvariationArray.clear();
    // this.variationid = [];
    // this.variationValueId = [];
    this.inventoryVariations = [];
    this.inventoryVariationValueIds = [];
    // this.inventoryVariationValues = [];
    // this.variationValues = [];
    // this.http.get(environment.apiUrl + `/merchantactivities/activityVariationDetails?category=bikerental${(this.locationId != null) ? '&locationId=' + this.locationId : ''}${(merchantid != 0) ? '&merchantid=' + merchantid : ''}`).subscribe(async (data: any) => {
    //   if (data.status === "SUCCESS") {
    //     data.activityVariationDetails.forEach((x: any) => {
    //       x.merchants.forEach((y: any) => {
    //         if (!this.variationid.includes(y.variationtypeid)) {
    //           this.variationid.push(y.variationtypeid);
    //         }
    //       })
    //     })
    //     if (this.variationid.length > 0) {
    //       let defaultVariationsData = [], sortedDefaultVariationsData = [];
    //       for (let i = 0; i < this.variationid.length; i++) {
    //         const data: any = await this.http.get(environment.apiUrl + `/variations/get?id=${this.variationid[i]}`).toPromise();
    //         defaultVariationsData.push(data.variations)
    //       }
    //       const sortByParentVariation = (data) => {
    //         const sortedData = [...data];
    //         sortedData.sort((a, b) => {
    //           if (a.parentvariation == '0' || a.parentvariation == null) {
    //             return -1;
    //           } else if (b.parentvariation == '0' || b.parentvariation == null) {
    //             return 1;
    //           } else {
    //             return a.parentvariation.localeCompare(b.parentvariation);
    //           }
    //         });
    //         return sortedData;
    //       };
    //       sortedDefaultVariationsData = sortByParentVariation(defaultVariationsData);
    //       const customSort = (a, b) => {
    //         const aIdInAnyParentVariation = sortedDefaultVariationsData.some(item => item.parentvariation === a.id);
    //         const bIdInAnyParentVariation = sortedDefaultVariationsData.some(item => item.parentvariation === b.id);

    //         if ((a.parentvariation == "0" || a.parentvariation == null) && !aIdInAnyParentVariation) {
    //           if ((b.parentvariation == "0" || b.parentvariation == null) && !bIdInAnyParentVariation) {
    //             return 0; // Both have parentvariation as 0 or null, and id does not exist in any parentvariation, no change in order
    //           } else {
    //             return 1; // Only a has parentvariation as 0 or null, and id does not exist in any parentvariation, bring it to the end
    //           }
    //         } else if ((b.parentvariation == "0" || b.parentvariation == null) && !bIdInAnyParentVariation) {
    //           return -1; // Only b has parentvariation as 0 or null, and id does not exist in any parentvariation, bring it to the end
    //         } else {
    //           return a.parentvariation.localeCompare(b.parentvariation);
    //         }
    //       }

    //       sortedDefaultVariationsData = sortedDefaultVariationsData.sort(customSort);
    //       let sortedDefaultVariationsid = [];
    //       for (let i = 0; i < sortedDefaultVariationsData.length; i++) {
    //         sortedDefaultVariationsid.push(sortedDefaultVariationsData[i].id);
    //       }
    //       this.variationid = sortedDefaultVariationsid;
    //       data.activityVariationDetails.forEach((x: any) => {
    //         x.merchants.forEach((y: any) => {
    //           let index = this.variationid.indexOf(y.variationtypeid.toString());
    //           if (index != -1) {
    //             if (this.variationValueId[index] != undefined) {
    //               (!this.variationValueId[index].includes(y.variationvalueid.toString())) ? this.variationValueId[index].push(y.variationvalueid.toString()) : ""
    //             } else {
    //               this.variationValueId[index] = [];
    //               this.variationValueId[index].push(y.variationvalueid.toString())
    //             }
    //           }
    //         })
    //       })
    //       console.log(this.variationValueId, "test")
    //       const variationsPromises = this.variationid.map(async (x: any) => {
    //         try {
    //           const data: any = await this.http.get(environment.apiUrl + `/variations/get?id=${x}`).toPromise();
    //           return data;
    //         } catch (error) {
    //           throw error;
    //         }
    //       });

    //       try {
    //         this.variations = await Promise.all(variationsPromises);
    //         console.log(this.variations, "test1")
    //         for (let i = 0; i < this.variations.length; i++) {
    //           if (this.variations[i].variations.parentvariation == 0 || this.variations[i].variations.parentvariation == null) {
    //             const variationValue = this.variationValueId[i].map(async (y: any) => {
    //               try {
    //                 const data: any = await this.http.get(environment.apiUrl + `/variationvalues/get?id=${y}`).toPromise();
    //                 return data;
    //               } catch (error) {
    //                 throw error;
    //               }
    //             });
    //             let values = await Promise.all(variationValue)
    //             this.variationValues.push(values)
    //           } else {
    //             this.variationValues.push([]);
    //           }
    //         }

    //         const variationArray: any = this.rentalForm.get('variations') as FormArray;
    //         this.variations.forEach((x: any) => {
    //           variationArray.push(
    //             this.formBuilder.group({
    //               variationid: new FormControl(x.variations.id),
    //               type: new FormControl(x.variations.type),
    //               variationvalueid: new FormControl('', Validators.required),
    //               parentvariation: new FormControl(x.variations.parentvariation)
    //             }))
    //         })
    //       } catch (error) {
    //         console.error("An error occurred:", error);
    //       }
    //     }

    //   }
    // })

    let activityVariation: any = await this.http.get(environment.apiUrl + `/merchantactivities/activityVariationDetails?category=bikerental${(this.locationId != null) ? '&locationId=' + this.locationId : ''}${(merchantid != 0) ? '&merchantid=' + merchantid : ''}`).toPromise();

    if (activityVariation.status === "SUCCESS" && activityVariation.activityVariationDetails.length > 0) {
      // setTimeout(() => {
      //   const isMobile = window.innerWidth < 768;
      //   const element = this.elementRef.nativeElement.querySelector('.rental-section');
      //   if (element) {
      //     window.scroll(0, element.offsetTop + 30);
      //     if (isMobile) {
      //       element.scrollIntoView({ top: 0, behavior: 'smooth', block: 'start', inline: 'nearest' });
      //     }
      //   }
      // });
      try {
        this.http.post(environment.apiUrl + '/coupons/is-coupon-available', { status: 1, locationId: this.locationId, merchantId: 0, activityId: activityVariation.activityVariationDetails[0].activityid, currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp() }, { responseType: 'json' }).subscribe((data: any) => {
          if (data.status === "SUCCESS") {
            this.rentalOfferAvailable = data.coupons;
          } else {
            this.rentalOfferAvailable = false;
          }
        })
      } catch (error) {
        this.rentalOfferAvailable = false;
        console.log(error);
      }
      let start_time = [], end_time = [];
      start_time.push(this.timings.indexOf(activityVariation.activityVariationDetails[0].timefrom));
      end_time.push(this.timings.indexOf(activityVariation.activityVariationDetails[0].timeto));
      let selected_start_time = this.rentalForm.value.time;
      let selected_end_time = this.rentalForm.value.dropdowntime;

      let minNumber = Math.min(...start_time);
      let maxNumber = Math.max(...end_time);
      this.newTimings = [];
      for (let i = minNumber; i <= maxNumber; i++) {
        this.newTimings.push(this.timings[i]);
      }
      if(!this.newTimings.includes(selected_start_time)){
        this.rentalForm.get('time')?.setValue('');
      }
      if(!this.newTimings.includes(selected_end_time)){
        this.rentalForm.get('dropdowntime')?.setValue('');
      }
      }

    this.http.get(environment.apiUrl + `/merchantinventories/viewInventory?category=bikerental${(this.locationId != null) ? '&locationId=' + this.locationId : ''}${(merchantid != 0) ? '&merchantid=' + merchantid : ''}`).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        if (data.merchantinventories.length > 0) {
          let inventoryVariationIds = [];
          data.merchantinventories.forEach((x: any) => {
            if (!inventoryVariationIds.includes(x.variationid)) inventoryVariationIds.push(x.variationid);
            this.inventoryVariationValueIds.push(x.variationvalueid);
          })
          const inventoryVariationRequests = inventoryVariationIds.map((x: any) => {
            return this.http.get(environment.apiUrl + `/variations/get?id=${x}`).toPromise(); // Convert the Observable to a Promise
          });

          const loadVariationRequests = await Promise.all(inventoryVariationRequests);
          loadVariationRequests.forEach((x: any) => {
            this.inventoryVariations.push(x.variations)
          })

          const sortByParentVariation = (data) => {
            const sortedData = [...data];
            sortedData.sort((a, b) => {
              if (a.parentvariation === '0') {
                return -1;
              } else if (b.parentvariation === '0') {
                return 1;
              } else {
                return a.parentvariation.localeCompare(b.parentvariation);
              }
            });
            return sortedData;
          };
          this.inventoryVariations = sortByParentVariation(this.inventoryVariations);
          const inventoryvariationsArray = this.rentalForm.get('inventoryvariations') as FormArray;
          this.inventoryVariations.forEach((x: any) => {
            inventoryvariationsArray.push(
              this.formBuilder.group({
                variationid: new FormControl(x.id),
                type: new FormControl(x.type),
                variationvalueid: new FormControl(''),
              }))
          })
          const inventoryVariationValueRequests = this.inventoryVariationValueIds.map((x: any) => {
            return this.http.get(environment.apiUrl + `/variationvalues/get?id=${x}`).toPromise(); // Convert the Observable to a Promise
          });
          this.nonFilteredBikeCard = [];
          const loadVariationValueRequests = await Promise.all(inventoryVariationValueRequests);
          loadVariationValueRequests.forEach((x: any) => {
            if (x.variationvalues.parentvariationvalueid != null && x.variationvalues.parentvariationvalueid != 0) {
              this.nonFilteredBikeCard.push(x.variationvalues)
            }
          });

          this.nonFilteredBikeCard.forEach((x: any) => {
            let variationTime = activityVariation.activityVariationDetails.filter((activity: any) => {
              return activity.merchants.some((y: any) =>
                y.variationtypeid == x.variationtypeid &&
                y.variationvalueid == x.id
              );
            });
            x.price = "";
            if (variationTime.length > 0){
              let merchantActivityId:any =[];
              variationTime.forEach((x)=>{
                merchantActivityId.push(x.id)
              })
              try {
                this.http.get(environment.apiUrl + `/range/minpricebymerchantactivityid?merchantActivityId=${merchantActivityId.map((id:any) => `${id.trim()}`).join(',')}`).subscribe((data: any) => {
                  if (data.status === "SUCCESS") {
                    x.price = data.min_price;
                  }
                });
              } catch (error) {
                console.log(error)
              }
            }else{
              x.price =0;
            } 
            x.src = [];
            x.availability = true;
            this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=variationvalueimage&dataforid=${x.id}`).subscribe((data: any) => {
              if (data.status == "SUCCESS") {
                for (const upload of data.uploads) {
                  this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      x.src.push(res.upload.thumbnail)
                    }
                  });
                }
              }
            })
          })
          if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
            let userData = JSON.parse(sessionStorage.getItem('userData'));
            if (userData.category === 'bike-rental') {
              const variationArray = this.rentalForm.get('variations') as FormArray;
              if (userData.value.variations) {
                userData.value.variations.forEach((x: any) => {
                  let indexToUpdate = variationArray.value.findIndex((y: any) => y.variationid == x.variationid);
                  const variationGroupToUpdate = variationArray.at(indexToUpdate) as FormGroup;
                  variationGroupToUpdate.controls['variationvalueid'].setValue(x.variationvalueid);
                })
              }
            } else {
              sessionStorage.setItem('userData', JSON.stringify({ category: 'bike-rental', value: this.rentalForm.value }))
            }
          } else {
            sessionStorage.setItem('userData', JSON.stringify({ category: 'bike-rental', value: this.rentalForm.value }))
          }
          this.nonFilteredBikeCard.sort((a, b) => Number(a.price) - Number(b.price));
          this.bikeCard = this.nonFilteredBikeCard;
          this.loadingFilteredBikes = false;
        }
      }
    })

    if (merchantid != 0) {
      this.selectedRentalMerchant = this.bikeRentalData.find((x: any) => x.id == merchantid);
      this.rentalForm.get('activityId')?.setValue(this.selectedRentalMerchant.activities[0].activityid);
    }
  }


  bookActivityMerchant(id: any, activityid: any, merchantid: any) {
    let data: any = {};
    data.activityId = activityid;
    data.date = this.activitiesForm.value.date;
    data.time = this.activitiesForm.value.time;
    data.activities = this.activitiesForm.value.activities;
    data.variations = [];

    this.router.navigate(['/book-merchant/activity'], { queryParams: { merchantId: merchantid, merchantactivityid: id, activityId: activityid, type: "User", data: JSON.stringify(data) } })
  }

  searchCamping() {
    this.isSubmittingCamping = true;
    if (this.campingForm.invalid) {
      return;
    }
    if (!this.campingForm.invalid) {
      const checkoutDate = new Date(this.campingForm.value.checkoutdate);
      let date = new Date();
      if (this.campingForm && this.campingForm.get('date')?.value) {
        const dateFormControlValue = this.campingForm.get('date')?.value;
        date = new Date(dateFormControlValue);
      }

      if (isNaN(checkoutDate.getTime()) || checkoutDate < date) {
        this.snackBar.open('Check-In date should be lesser than Check-Out date.', 'close', { duration: 2000, verticalPosition: 'top' },);
        return;
      }
    }
    sessionStorage.setItem('userData', JSON.stringify({ category: 'camping', value: this.campingForm.value, isSubmittingCamping: true }))
    this.viewSearchedCamping = true;
    this.loadSearchCampingData = true;
    let data: any = {};
    data.variations = [];
    this.campingForm.value.variations.forEach((x: any) => {
      data.variations.push({ variationtypeid: x.variationid, variationvalueid: x.variationvalueid })
    })
    if (this.locationId != null) {
      data.locationId = this.locationId;
    }
    this.http.post(environment.apiUrl + `/merchantactivities/filterMerchant`, data).subscribe(async (data: any) => {
      {
        setTimeout(() => {
          const isMobile = window.innerWidth < 768;
          const element = this.elementRef.nativeElement.querySelector('.camping-section');
          if (element) {
            window.scroll(0, element.offsetTop + 30);
            if (isMobile) {
              element.scrollIntoView({ top: 0, behavior: 'smooth', block: 'start', inline: 'nearest' });
            }
          }
        });
        if (data.status === "SUCCESS") {
          data.merchantactivities.forEach((x: any, i: any) => {
            try{
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId:x.merchantid,activityId: x.activityid ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS" ) {
                  x.hasCoupon = data.coupons;
                  }else{
                    x.hasCoupon = false;
                  }
                  })
            }catch(error){
              console.log(error);
              x.hasCoupon = false;
            }
            try {
              this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'activity', dataForId: x.activityid }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  x.rating = Math.round(data.reviews.average);
                  x.ratingOf = data.reviews.total;
                }
              });
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.get(environment.apiUrl + `/range/minpricebymerchantactivityid?merchantActivityId=${x.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  x.min_price = data.min_price;
                }
              });
            } catch (error) {
              console.log(error)
            }

            x.campingName = "";
            if (x.additionalinfo && x.additionalinfo != null && x.additionalinfo != "") {
              if (JSON.parse(x.additionalinfo).campingname) {
                x.campingName = JSON.parse(x.additionalinfo).campingname;
              }
              if (JSON.parse(x.additionalinfo).latitude != undefined && JSON.parse(x.additionalinfo).longitude != undefined && JSON.parse(x.additionalinfo).latitude != null && JSON.parse(x.additionalinfo).longitude != null && JSON.parse(x.additionalinfo).latitude != 0 && JSON.parse(x.additionalinfo).longitude) {
                x.campingLatitude = JSON.parse(x.additionalinfo).latitude;
                x.campingLongitude = JSON.parse(x.additionalinfo).longitude;
                this.dataService.getActivitiesDistance(this.userLocation.latitude, this.userLocation.longitude, JSON.parse(x.additionalinfo).latitude, JSON.parse(x.additionalinfo).longitude).subscribe({
                  next: (res: any) => {
                    let km = Math.round(res.distance_in_km)
                    x.distanceAway = `${km}${(Number(km) > 1) ? " Kms away" : "Km away"}`;
                  }, error: (error) => {
                    console.log(error)
                  }
                }
                );
              } else {
                x.campingLatitude = 0;
                x.campingLongitude = 0;
                x.distanceAway = "0Km away";
              }
            } else {
              x.distanceAway = "0Km away";
            }
            x.src = [];
            if (x.mapimages && x.mapimages != null && x.mapimages != "") {
              let Images = JSON.parse(x.mapimages)
              Images.forEach((y: any) => {
                this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${y}`).subscribe((value: any) => {
                  if (value.status === "SUCCESS") {
                    x.src.push({ id: value.upload.id, img: value.upload.thumbnail })
                  }
                })
              })
            }
            x.name = x.merchantname;
            x.city = x.merchantlocationname;
          })
          this.searchCampingData = data.merchantactivities;
          this.loadSearchCampingData = false;
        }
      }
    })
  }

  bookCamping(activitieslocationid:number,id: any, activityid: any, merchantid: any, e: any) {
    if (e.target instanceof HTMLElement && (e.target.classList.contains('carousel-control-next-icon') || e.target.classList.contains('carousel-control-prev-icon'))) {
      e.stopPropagation();
      return;
    }

    let data: any = this.campingForm.value;
    data.days = 1;
    const startDate: any = new Date(data.date);
    const endDate: any = new Date(data.checkoutdate);
    const timeDiff = endDate - startDate;
    const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
    (Number(daysDiff) != 0) ? data.days = daysDiff : "";
    data.variations.forEach((x: any, index: any) => {
      x.variationvalue = this.variationValues[index].find((y: any) => y.variationvalues.id == x.variationvalueid).variationvalues.value
    })

  
    this.router.navigate(['/location',activitieslocationid,'camping-details',id], {state: { data: { merchantId: merchantid, merchantactivityid: id, activityId: activityid, type: "User", data: JSON.stringify(data) } } });
    // this.router.navigate(['/book-merchant/camping'], { queryParams: { merchantId: merchantid, merchantactivityid: id, activityId: activityid, type: "User", data: JSON.stringify(data) } })
    
  }

  increaseValue(type: any) {
    if (type == "adult" && this.adultsCount < 10) {
      this.adultsCount++;
      this.totalGuest++;
    }
    if (type == "children" && this.childrenCount < 10) {
      this.childrenCount++;
      this.totalGuest++;
    }
  }

  decreaseValue(type: any) {
    if (type == "adult" && this.adultsCount > 1) {
      this.adultsCount--;
      this.totalGuest--;
    }
    if (type == "children" && this.childrenCount > 0) {
      this.childrenCount--;
      this.totalGuest--;
    }
  }

  loadMoreCampings() {
    let filter: any = { count: this.viewCount, location: this.location, page: this.campingPage, status: 1 };
    if (this.locationId != null) {
      filter.locationId = this.locationId;
    }
    this.campingLoadMore = false;
    this.http.post(environment.apiUrl + `/merchantactivities/list`, { ...filter, category: 'camping' }, { responseType: 'json' }).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        this.nonFiteredCampingData.push(...data.merchantactivities);
        data.merchantactivities = this.removeDuplicates(data.merchantactivities, "activityid", "merchantid", this.campingData);
        data.merchantactivities.forEach((activity: any) => {
          activity.campingName = "";
          try{
            this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId:activity.merchantid,activityId:activity.activityid ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
              if (data.status === "SUCCESS" ) {
                activity.hasCoupon = data.coupons;
                }else{
                  activity.hasCoupon = false;
                }
                })
          }catch(error){
            console.log(error);
            activity.hasCoupon = false;
          }
          if (activity.additionalinfo && activity.additionalinfo != null && activity.additionalinfo != "") {
            if (JSON.parse(activity.additionalinfo).campingname) {
              activity.campingName = JSON.parse(activity.additionalinfo).campingname;
            }
            if (JSON.parse(activity.additionalinfo).latitude != undefined && JSON.parse(activity.additionalinfo).longitude != undefined && JSON.parse(activity.additionalinfo).latitude != null && JSON.parse(activity.additionalinfo).longitude != null && JSON.parse(activity.additionalinfo).latitude != 0 && JSON.parse(activity.additionalinfo).longitude) {
              activity.campingLatitude = JSON.parse(activity.additionalinfo).latitude;
              activity.campingLongitude = JSON.parse(activity.additionalinfo).longitude;
              this.dataService.getActivitiesDistance(this.userLocation.latitude, this.userLocation.longitude, JSON.parse(activity.additionalinfo).latitude, JSON.parse(activity.additionalinfo).longitude).subscribe({
                next: (res: any) => {
                  let km = Math.round(res.distance_in_km)
                  activity.distanceAway = `${km}${(Number(km) > 1) ? " Kms away" : "Km away"}`;
                }, error: (error) => {
                  console.log(error)
                }
              }
              );
            } else {
              activity.campingLatitude = 0;
              activity.campingLongitude = 0;
              activity.distanceAway = "0Km away";
            }
          }

          activity.src = [];
          if (activity.mapimages && activity.mapimages != null && activity.mapimages != "") {
            let Images = JSON.parse(activity.mapimages)
            Images.forEach((x: any) => {
              this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${x}`).subscribe((value: any) => {
                if (value.status === "SUCCESS") {
                  activity.src.push({ id: value.upload.id, img: value.upload.thumbnail })
                }
              })
            })
          }
          try {
            this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'activity', dataForId: activity.activityid }, { responseType: 'json' }).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                activity.avgReview = Math.round(data.reviews.average);
                activity.total = data.reviews.total;
              }
            });
          } catch (error) {
            console.log(error)
          }
          try {
            this.http.get(environment.apiUrl + `/range/minpricebymerchantactivityid?merchantActivityId=${activity.id}`).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                activity.min_price = data.min_price;
              }
            });
          } catch (error) {
            console.log(error)
          }
        });
        this.campingData.push(...data.merchantactivities);
        // if(this.campingData && this.campingData.length < 4 ){
        //   this.loadMoreCampings();
        //   this.campingLoadMore = false;
        // }
        this.campingLoading = false;
        if (Number(data.count) > this.nonFiteredCampingData.length) {
          this.campingLoadMore = true;
          this.campingPage += 1;
        } else {
          this.campingLoadMore = false;
        }
      }
    });
  }
  loadMoreBiketour() {
    let filter: any = { count: this.viewCount, location: this.location, page: this.bikeTourPage, status: 1 };
    if (this.locationId != null) {
      filter.locationId = this.locationId;
    }
    this.bikeTourLoading = true;
    this.http.post(environment.apiUrl + `/activities/list`, { ...filter, category: 'tour' }, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          data.activities.forEach((activity: any, index: number) => {
            activity.src = [];
            try{
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId:0,activityId:activity.id ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS" ) {
                  activity.hasCoupon = data.coupons;
                  }else{
                    activity.hasCoupon = false;
                  }
                  })
            }catch(error){
              console.log(error);
              activity.hasCoupon = false;
            }
            if (activity.additionalinfo && activity.additionalinfo != null && activity.additionalinfo != "") {
              let parsedfrom = JSON.parse(activity.additionalinfo);
              activity.totaldistance = parsedfrom.totaldistance;
              activity.numberofdays = parsedfrom.numberofdays;
              activity.difficulty = parsedfrom.difficulty;
            }
            activity.slot_available = "";
            try {
              this.http.get(environment.apiUrl + `/merchantactivities/packagetourslots?category=tour&activityId=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  if(data.slots.length > 0){
                    activity.slot_available = true;
                  }else{
                    activity.slot_available = false;
                  }
                }
              });
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'activity', dataForId: activity.id }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.avgReview = Math.round(data.reviews.average);
                  activity.total = data.reviews.total;
                }
              });
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=activity&dataforid=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        activity.src.push(res.upload.thumbnail)
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            activity.min_price= "";
            try {
              this.http.get(environment.apiUrl + `/range/minpricebyactivityid?activityId=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.min_price = data.min_price;
                }
              });
            } catch (error) {
              console.log(error)
            }
          });
          this.bikeTourData.push(...data.activities);
          this.bikeTourLoading = false;
          if (Number(data.count) > this.bikeTourData.length) {
            this.bikeTourLoadMore = true;
            this.bikeTourPage += 1;
          } else {
            this.bikeTourLoadMore = false;
          }
        }
      }
    });
  }
  loadMoreTrekkings() {
    let filter: any = { count: this.viewCount, location: this.location, page: this.trekkingPage, status: 1 };
    if (this.locationId != null) {
      filter.locationId = this.locationId;
    }
    this.trekkingLoadMore = false;
    this.http.post(environment.apiUrl + `/activities/list`, { ...filter, category: 'trekking' }, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          data.activities.forEach((activity: any, index: number) => {
            activity.src = [];
            if (activity.additionalinfo && activity.additionalinfo != null && activity.additionalinfo != "") {
              let parsedfrom = JSON.parse(activity.additionalinfo);
              activity.difficulty = parsedfrom.difficulty;
              activity.totalaltitude = parsedfrom.totalaltitude;
              activity.numberofdays = parsedfrom.numberofdays;
              activity.difficulty = parsedfrom.difficulty;
            }
            activity.slot_available = "";
            try{
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId:0,activityId:activity.id ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS" ) {
                  activity.hasCoupon = data.coupons;
                  }else{
              activity.hasCoupon = false;
                  }
                  })
            }catch(error){
              console.log(error);
              activity.hasCoupon = false;
            }
            try {
              this.http.get(environment.apiUrl + `/merchantactivities/packagetourslots?category=trekking&activityId=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  if(data.slots.length > 0){
                    activity.slot_available = true;
                  }else{
                    activity.slot_available = false;
                  }
                }
              });
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'activity', dataForId: activity.id }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.avgReview = Math.round(data.reviews.average);
                  activity.total = data.reviews.total;
                }
              });
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=activity&dataforid=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        activity.src.push(res.upload.thumbnail)
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            activity.min_price= "";
            try {
              this.http.get(environment.apiUrl + `/range/minpricebyactivityid?activityId=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.min_price = data.min_price;
                }
              });
            } catch (error) {
              console.log(error)
            }
          });
          this.trekkingData.push(...data.activities);
          this.trekkingLoading = false;
          if (Number(data.count) > this.trekkingData.length) {
            this.trekkingLoadMore = true;
            this.trekkingPage += 1;
          } else {
            this.trekkingLoadMore = false;
          }
        }
      }
    });
  }
  loadMoreRafting() {
    let filter: any = { count: this.viewCount, location: this.location, page: this.raftingPage, status: 1 };
    if (this.locationId != null) {
      filter.locationId = this.locationId;
    }
    this.raftingLoading = true;
    this.http.post(environment.apiUrl + `/activities/list`, { ...filter, category: 'rafting' }, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          data.activities.forEach((activity: any, index: number) => {
            activity.src = [];
            if (activity.additionalinfo != null && activity.additionalinfo != "") {
              activity.raftingkm = JSON.parse(activity.additionalinfo).raftingkm;
            }
            try{
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId: 0,activityId:activity.id ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS" ) {
                  activity.hasCoupon = data.coupons;
                  }else{
              activity.hasCoupon = false;
                  }
                  })
            }catch(error){
              console.log(error);
              activity.hasCoupon = false;
            }
            try {
              this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'activity', dataForId: activity.id }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.avgReview = Math.round(data.reviews.average);
                  activity.total = data.reviews.total;
                }
              });
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=activity&dataforid=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        activity.src.push(res.upload.thumbnail)
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            activity.min_price= "";
            try {
              this.http.get(environment.apiUrl + `/range/minpricebyactivityid?activityId=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.min_price = data.min_price;
                }
              });
            } catch (error) {
              console.log(error)
            }
          });
          this.raftingData.push(...data.activities);
          this.raftingLoading = false;
          if (Number(data.count) > this.raftingData.length) {
            this.raftingLoadMore = true;
            this.raftingPage += 1;
          } else {
            this.raftingLoadMore = false;
          }
        }
      }
    });
  }

  click_sort_by(){
    $("#sort_by_menu").css('top', $('.sort-dropdown-button')[0].clientTop + $('.sort-dropdown-button').parent().height());
    $("#sort_by_menu").css('right', 0);
    $("#sort_by_menu").css('transform', 'none');
  }

  loadMoreQuickActivities() {
    let filter: any = { count: this.viewCount, location: this.location, page: this.quickActivityPage, status: 1 };
    if (this.locationId != null) {
      filter.locationId = this.locationId;
    }
    this.quickActivitiesLoading = true;
    this.http.post(environment.apiUrl + `/activities/list`, { ...filter, category: 'activity' }, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          data.activities.forEach((activity: any, index: number) => {
            activity.src = [];
            try{
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId:0,activityId:activity.id ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS" ) {
                  activity.hasCoupon = data.coupons;
                  }else{
                    activity.hasCoupon = false;
                  }
                  })
            }catch(error){
              console.log(error);
              activity.hasCoupon = false;
            }
            if (activity.additionalinfo && activity.additionalinfo != null && activity.additionalinfo != "") {
              let parsedfrom = JSON.parse(activity.additionalinfo);
              activity.difficulty = parsedfrom.difficulty;
            }
            try {
              this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'activity', dataForId: activity.id }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.avgReview = Math.round(data.reviews.average);
                  activity.total = data.reviews.total;
                }
              });
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=activity&dataforid=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        activity.src.push(res.upload.thumbnail)
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            activity.min_price= "";
            try {
              this.http.get(environment.apiUrl + `/range/minpricebyactivityid?activityId=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.min_price = data.min_price;
                }
              });
            } catch (error) {
              console.log(error)
            }
          });

          this.quickActivitiesData.push(...data.activities);
          this.quickActivitiesLoading = false;

          if (Number(data.count) > this.quickActivitiesData.length) {
            this.quickActivitiesLoadMore = true;
            this.quickActivityPage += 1;
          } else {
            this.quickActivitiesLoadMore = false;
          }
        }
      }
    });
  }
  loadMoreFilteredQuickActivities() {
    let filter: any = { count: this.viewCount, location: this.location, page: this.filteredQuickActivityPage, status: 1 };
    if (this.locationId != null) {
      filter.locationId = this.locationId;
    }
    const activityTypeId = this.activitytypeid ? this.activitytypeid : this.activitiesForm.value.activities
    this.filteredQuickActivitiesLoading = false;
    this.http.post(environment.apiUrl + `/activities/listbyactivitytype`, { ...filter, category: 'activity', activityTypeId: activityTypeId }, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        setTimeout(() => {
          const isMobile = window.innerWidth < 768;
          const element = this.elementRef.nativeElement.querySelector('.activity-section');
          if (element) {
            window.scroll(0, element.offsetTop + 30);
            if (isMobile) {
              element.scrollIntoView({ top: 0, behavior: 'smooth', block: 'start', inline: 'nearest' });
            }
          }
        });
        if (data.status === "SUCCESS") {
          data.activities.forEach((activity: any, index: number) => {
            try{
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId:0,activityId: activity.id ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS" ) {
                  activity.hasCoupon = data.coupons;
                  }else{
                    activity.hasCoupon = false;
                  }
                  })
            }catch(error){
              console.log(error);
              activity.hasCoupon = false;
            }
            activity.src = [];
            let parsedfrom = JSON.parse(activity.additionalinfo);
            activity.difficulty = parsedfrom.difficulty;
            try {
              this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'activity', dataForId: activity.id }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.avgReview = Math.round(data.reviews.average);
                  activity.total = data.reviews.total;
                }
              });
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=activity&dataforid=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        activity.src.push(res.upload.thumbnail)
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.get(environment.apiUrl + `/range/minpricebyactivityid?activityId=${activity.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  activity.min_price = data.min_price;
                }
              });
            } catch (error) {
              console.log(error)
            }
          });
          this.filteredActivitiesData.push(...data.activities);
          this.filteredQuickActivitiesLoading = false
          if (Number(data.count) > this.filteredActivitiesData.length) {
            this.filteredQuickActivitiesLoadMore = true;
            this.filteredQuickActivityPage += 1;
          } else {
            this.filteredQuickActivitiesLoadMore = false;
          }
        }
      }
    });
  }
  loadMoreLandingPage(dataFrom: string, selectedSortBy: any) {
    let sortColumn: string;
    let sort: string;

    if (selectedSortBy === "Price - low to high") {
      sort = "ASC";
      sortColumn = "minimumactivityprice.price";
    } else if (selectedSortBy === "Price - high to low") {
      sort = "DESC";
      sortColumn = "minimumactivityprice.price";
    }
    else if (selectedSortBy === "Rating - low to high") {
      sort = "ASC";
      sortColumn = "total_star";
    } else if (selectedSortBy === "Rating - high to low") {
      sort = "DESC";
      sortColumn = "total_star";
    }

    let filter: any = { count: 6, page: this.landingPage, status: 1 };
    if (selectedSortBy) {
      filter.sortColumn = sortColumn;
      filter.sort = sort;
    }

    if (this.locationId != null) {
      filter.locationId = this.locationId;
    }
    // this.landingPageLoading = true;
    // this.http.post(environment.apiUrl + `/merchantactivities/sortactivity`, filter, { responseType: 'json' }).subscribe(async (data: any) => {
    //   {
    //     if (data.status === "SUCCESS") {
    //       this.nonFiteredLandingData.push(...data.merchantactivities);
    //       data.merchantactivities = this.removeDuplicatesLandingPage(data.merchantactivities, "activityid", "merchantid", this.landingPageData);
    //       data.merchantactivities.forEach((activity: any, index: number) => {
    //         activity.src = [];
    //         try{
    //           this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId:activity.merchantid,activityId:activity.activityid ,currentTimeStamp: new Date().getTime()}, { responseType: 'json' }).subscribe((data: any) => {
    //             if (data.status === "SUCCESS" ) {
    //               activity.hasCoupon = data.coupons;
    //               }else{
    //                 activity.hasCoupon = false;
    //               }
    //               })
    //         }catch(error){
    //           console.log(error);
    //           activity.hasCoupon = false;
    //         }
    //       try{
    //         this.http.get(environment.apiUrl + `/locations/get?id=${activity.locationid}`).subscribe(async (data: any) => {
    //           activity.locationname = data.location.locationname;
    //         })
    //       }catch(error){
    //         console.log(error);
    //       }
    //         if (activity.activitiescategory == "camping") {
    //           activity.campingName = "";
    //           if (activity.additionalinfo && activity.additionalinfo != null && activity.additionalinfo != "") {
    //             if (JSON.parse(activity.additionalinfo).campingname) {
    //               activity.campingName = JSON.parse(activity.additionalinfo).campingname;
    //             }
    //             if (JSON.parse(activity.additionalinfo).latitude != undefined && JSON.parse(activity.additionalinfo).longitude != undefined && JSON.parse(activity.additionalinfo).latitude != null && JSON.parse(activity.additionalinfo).longitude != null && JSON.parse(activity.additionalinfo).latitude != 0 && JSON.parse(activity.additionalinfo).longitude) {
    //               activity.campingLatitude = JSON.parse(activity.additionalinfo).latitude;
    //               activity.campingLongitude = JSON.parse(activity.additionalinfo).longitude;
    //               this.dataService.getActivitiesDistance(this.userLocation.latitude, this.userLocation.longitude, JSON.parse(activity.additionalinfo).latitude, JSON.parse(activity.additionalinfo).longitude).subscribe({
    //                 next: (res: any) => {
    //                   let km = Math.round(res.distance_in_km)
    //                   activity.distanceAway = `${km}${(Number(km) > 1) ? " Kms away" : "Km away"}`;
    //                 }, error: (error) => {
    //                   console.log(error)
    //                 }
    //               }
    //               );
    //             } else {
    //               activity.campingLatitude = 0;
    //               activity.campingLongitude = 0;
    //               activity.distanceAway = "0Km away";
    //             }
    //           };
    //         }
    //         if (activity.activitiescategory == "rafting") {
    //           if (activity.activitiesadditionalinfo != null && activity.activitiesadditionalinfo != "") {
    //             activity.raftingkm = JSON.parse(activity.activitiesadditionalinfo).raftingkm;
    //           }
    //         }
    //         if (activity.activitiescategory == "tour") {
    //           if (activity.activitiesadditionalinfo != null && activity.activitiesadditionalinfo != "") {
    //             let parsedfrom = JSON.parse(activity.activitiesadditionalinfo);
    //             activity.totaldistance = parsedfrom.totaldistance;
    //             activity.numberofdays = parsedfrom.numberofdays;
    //             activity.difficulty = parsedfrom.difficulty;
    //           };
    //         }
    //         if (activity.activitiescategory == "trekking") {
    //           if (activity.activitiesadditionalinfo != null && activity.activitiesadditionalinfo != "") {
    //             let parsedfrom = JSON.parse(activity.activitiesadditionalinfo);
    //             activity.totalaltitude = parsedfrom.totalaltitude;
    //             activity.numberofdays = parsedfrom.numberofdays;
    //             activity.difficulty = parsedfrom.difficulty;
    //           };
    //         }
    //         if (activity.activitiescategory == "activity") {
    //           if (activity.activitiesadditionalinfo != null && activity.activitiesadditionalinfo != "") {
    //             let parsedfrom = JSON.parse(activity.activitiesadditionalinfo);
    //             activity.difficulty = parsedfrom.difficulty;
    //           };
    //         }

    //         if (activity.activitiescategory == "camping" || activity.activitiescategory == "bikerental") {
    //           // try {
    //           //   this.http.post(environment.apiUrl + `/merchantactivities/pricestartsfrom`, { activityId: activity.activityid, merchantId: activity.merchantid }, { responseType: 'json' }).subscribe((data: any) => {
    //           //     if (data.status === "SUCCESS") {
    //           //       activity.priceFrom = data.priceStartsFrom;
    //           //     }
    //           //   });
    //           // } catch (error) {
    //           //   console.log(error)
    //           // }
    //           if (activity.mapimages && activity.mapimages != null && activity.mapimages != "") {
    //             let Images = JSON.parse(activity.mapimages)
    //             Images.forEach((x: any) => {
    //               this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${x}`).subscribe((value: any) => {
    //                 if (value.status === "SUCCESS") {
    //                   activity.src.push(value.upload.thumbnail)
    //                 }
    //               })
    //             })
    //           };
    //         }else {
    //           try {
    //             this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=activity&dataforid=${activity.activityid}`).subscribe((data: any) => {
    //               if (data.status === "SUCCESS") {
    //                 for (const upload of data.uploads) {
    //                   this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
    //                     if (res.status === "SUCCESS") {
    //                       activity.src.push(res.upload.thumbnail)
    //                     }
    //                   });
    //                 }
    //               }
    //             })
    //           } catch (error) {
    //             console.log(error)
    //           }
    //         }
    //       });
    //       const filteredActivities = data.merchantactivities.filter((activity:any) => activity.price != "0" && activity.price != undefined && activity.price != null);
    //       this.landingPageData.push(...filteredActivities);

    //       this.landingPageLoading = false;
        
    //       if (Number(data.count) > this.nonFiteredLandingData.length) {
    //         this.landingPageLoadMore = true;
    //         this.landingPage += 1;
    //         if ((dataFrom == "showmore" && data.merchantactivities.length < 6) || this.landingPageData.length < 4) {  
    //           this.loadMoreLandingPage('landingpage', this.selectedSortBy);
    //         }
    //       } else {
    //         this.landingPageLoadMore = false;
    //       }

    //     }
    //   }
    // });
    // console.log(this.trekkingData,"this.trekkingData",dataFrom);
    // console.log(this.raftingData,"this.raftingData");
    // console.log(this.bikeTourData,"this.bikeTourData");
    // console.log(this.campingData,"this.campingData");
    this.loadMoreTrekkings();
    this.loadMoreRafting();
    this.loadMoreBiketour();
    this.loadMoreCampings();
    this.loadMoreQuickActivities();
    this.loadMoreBikeRentals();
    
    
  }

  loadMoreBikeRentals() {
    let filter: any = { count: this.viewCount, location: this.location, page: this.bikeRentalPage, status: 1 }
    if (this.locationId != null) {
      filter.locationId = this.locationId;
    }
    this.http.post(environment.apiUrl + `/merchants/merchantcategory`, { ...filter, category: 'bikerental' }, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          data.merchants.forEach((merchant: any) => {
           const matchingActivity = merchant.activities.find((activity: any) => {
              if(activity.activitiecategory === 'bikerental'){
                 activity.activityid
                 return true; 
                }
                return false;
            })
            merchant.activityid =  matchingActivity.activityid
            merchant.src = [];
            merchant.shopsrc = [];
            try{
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId:merchant.merchantid,activityId:merchant.activityid ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS" ) {
                  merchant.hasCoupon = data.coupons;
                  }else{
                    merchant.hasCoupon = false;
                  }
                  })
            }catch(error){
              console.log(error);
              merchant.hasCoupon = false;
            }
            if (merchant.latitude != undefined && merchant.longitude != undefined && merchant.latitude != null && merchant.longitude != null && merchant.latitude != 0 && merchant.longitude) {
              this.dataService.getActivitiesDistance(this.userLocation.latitude, this.userLocation.longitude, merchant.latitude, merchant.longitude).subscribe({
                next: (res: any) => {
                  let km = Math.round(res.distance_in_km)
                  merchant.distanceAway = `${km}${(Number(km) > 1) ? " Kms away" : "Km away"}`;
                }, error: (error) => {
                  console.log(error)
                }
              }
              );
            } else {
              merchant.distanceAway = "0Km away";
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantprofile&dataforid=${merchant.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        merchant.src.push(res.upload.thumbnail)
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantshopprofile&dataforid=${merchant.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        merchant.shopsrc.push(res.upload.thumbnail)
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'merchant', dataForId: merchant.id }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  merchant.avgReview = Math.round(data.reviews.average);
                  merchant.rating = data.reviews.total;
                }
              });
            } catch (error) {
              console.log(error)
            }
            merchant.min_price = "";
            try {
              this.http.get(environment.apiUrl + `/range/minpricebycategory?category=bikerental&merchantId=${merchant.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  merchant.min_price = data.min_price;
                }
              });
            } catch (error) {
              console.log(error)
            }
          });
          this.bikeRentalData.push(...data.merchants);
          if (Number(data.count) > this.bikeRentalData.length) {
            this.bikeRentalLoadMore = true;
            this.bikeRentalPage += 1;
          } else {
            this.bikeRentalLoadMore = false;
          }
        }
      }
    })
  }

  loadMoreAdventurists() {
    let filter: any = { count: this.viewCount, location: this.location, page: this.topAdventuristsPage, status: 1 };
    if (this.locationId != null) {
      filter.locationId = this.locationId;
    }
    this.adventuristsLoading = true;
    this.http.post(environment.apiUrl + `/merchants/topmerchants`, { ...filter, sortColumn: "average_star" }, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          data.merchants.forEach((merchant: any) => {
            merchant.src = [];
            merchant.shopsrc = [];
            try{
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId:merchant.id,activityId:0 ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS" ) {
                  merchant.hasCoupon = data.coupons;
                  }else{
                    merchant.hasCoupon = false;
                  }
                  })
            }catch(error){
              console.log(error);
              merchant.hasCoupon = false;
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantprofile&dataforid=${merchant.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        merchant.src.push(res.upload.thumbnail)
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantshopprofile&dataforid=${merchant.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        merchant.shopsrc.push(res.upload.thumbnail)
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            merchant.averagestar = parseInt(merchant.average_star);
          });
          this.topAdventuristsData.push(...data.merchants);
          this.adventuristsLoading = false;
          if (Number(data.count) > this.topAdventuristsData.length) {
            this.topAdventuristsLoadMore = true;
            this.topAdventuristsPage += 1;
          } else {
            this.topAdventuristsLoadMore = false;
          }
        }
      }
    })
  }

  async onChangeRentalVariations() {
    // if (this.rentalForm.invalid) {
    //   return;
    // }
    if (this.rentalForm.value.variations.length == 0) {
      return;
    }
    // this.rentalForm.get('time')?.setValue('');
    let filter: any = {};
    if (this.locationId != null) {
      filter.locationId = this.locationId;
    }
    let values: any = await this.http.post(environment.apiUrl + `/merchants/merchantcategory`, { ...filter, category: 'bikerental' }, { responseType: 'json' }).toPromise();
    if (values.status === "SUCCESS") {
      if (values.merchants.length > 0) {
        let activityId = values.merchants[0].activities.find((x: any) => x.activitystatus == 1 && x.activitiecategory == "bikerental").activityid;
        this.rentalForm.get('activityId')?.setValue(activityId);
      }
    }
    let data: any = {};
    data.activityId = this.rentalForm.value.activityId;
    data.variations = [];
    this.rentalForm.value.variations.forEach((x: any) => {
      data.variations.push({ variationtypeid: x.variationid, variationvalueid: x.variationvalueid })
    })
    if (this.locationId != null) {
      data.locationId = this.locationId;
    }
    this.http.post(environment.apiUrl + `/merchantactivities/filterMerchant`, data).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          let start_time = [], end_time = [];
          data.merchantactivities.forEach((x: any, i: any) => {
            start_time.push(this.timings.indexOf(x.timefrom));
            end_time.push(this.timings.indexOf(x.timeto));
          })
          let selected_start_time = this.rentalForm.value.time;
          let selected_end_time = this.rentalForm.value.dropdowntime;

          let minNumber = Math.min(...start_time);
          let maxNumber = Math.max(...end_time);
          this.newTimings = [];
          for (let i = minNumber; i <= maxNumber; i++) {
            this.newTimings.push(this.timings[i]);
          }
          if(!this.newTimings.includes(selected_start_time)){
             this.rentalForm.get('time')?.setValue('');
          }
          if(!this.newTimings.includes(selected_end_time)){
            this.rentalForm.get('dropdowntime')?.setValue('');
          }
          setTimeout(() => {
            if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
              let userData = JSON.parse(sessionStorage.getItem('userData'));
              if (userData.category === 'bike-rental') {
                if(this.rentalForm.value.time == ''){
                   this.rentalForm.get('time')?.setValue(userData.value.time);
                }
                if(this.rentalForm.value.dropdowntime == ''){
                  this.rentalForm.get('dropdowntime')?.setValue(userData.value.dropdowntime);
                }
              }
              this.searchRentalMerchants()
            }
          })
        }
      }
    })

  }

  onChangeRaftingValues() {
    this.raftingForm.get('time')?.setValue('');
    let activityData = [];
    let filter: any = {};
    if (this.locationId != null) {
      filter.locationId = this.locationId;
    }
    this.http.post(environment.apiUrl + `/activities/raftingdistance`, { ...filter, category: 'rafting', distance: this.raftingForm.value.distance, location: this.location, status: 1 }, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          data.activities.forEach((activity: any) => {
            if (activity.additionalinfo != null && activity.additionalinfo != "") {
              activity.raftingkm = JSON.parse(activity.additionalinfo).raftingkm;
              if (activity.raftingkm === this.raftingForm.value.distance) {
                activityData.push(activity);
              }
            }
          });
          if (activityData.length > 0) {
            let filter: any = { activityId: activityData[0].id, variations: [] };
            if (this.locationId != null) {
              filter.locationId = this.locationId;
            }
            let start_time = [], end_time = [];
            this.http.post(environment.apiUrl + `/merchantactivities/filterMerchant`, filter, { responseType: 'json' }).subscribe(async (data: any) => {
              {
                if (data.status === "SUCCESS") {
                  data.merchantactivities.forEach((x: any, i: any) => {
                    start_time.push(this.timings.indexOf(x.timefrom));
                    end_time.push(this.timings.indexOf(x.timeto));
                  })
                  let minNumber = Math.min(...start_time);
                  let maxNumber = Math.max(...end_time);
                  this.newTimings = [];
                  for (let i = minNumber; i <= maxNumber; i++) {
                    this.newTimings.push(this.timings[i]);
                  }
                }
              }
            })
          }
        }
      }
    })
  }

  // onChangeActivitiesValues() {
  //   this.activitiesForm.get('time')?.setValue('');
  //   let activityData = [];
  //   let filter: any = {};
  //   if (this.locationId != null) {
  //     filter.locationId = this.locationId;
  //   }
  //   this.http.post(environment.apiUrl + `/activities/raftingdistance`, { ...filter, category: 'rafting', distance: this.raftingForm.value.distance, location: this.location, status: 1 }, { responseType: 'json' }).subscribe(async (data: any) => {
  //     {
  //       if (data.status === "SUCCESS") {
  //         data.activities.forEach((activity: any) => {
  //           if (activity.additionalinfo != null && activity.additionalinfo != "") {
  //             activity.raftingkm = JSON.parse(activity.additionalinfo).raftingkm;
  //             if (activity.raftingkm === this.raftingForm.value.distance) {
  //               activityData.push(activity);
  //             }
  //           }
  //         });
  //         if (activityData.length > 0) {
  //           let filter: any = { activityId: activityData[0].id, variations: [] };
  //           if (this.locationId != null) {
  //             filter.locationId = this.locationId;
  //           }
  //           let start_time = [], end_time = [];
  //           this.http.post(environment.apiUrl + `/merchantactivities/filterMerchant`, filter, { responseType: 'json' }).subscribe(async (data: any) => {
  //             {
  //               if (data.status === "SUCCESS") {
  //                 data.merchantactivities.forEach((x: any, i: any) => {
  //                   start_time.push(this.timings.indexOf(x.timefrom));
  //                   end_time.push(this.timings.indexOf(x.timeto));
  //                 })
  //                 let minNumber = Math.min(...start_time);
  //                 let maxNumber = Math.max(...end_time);
  //                 this.newTimings = [];
  //                 for (let i = minNumber; i <= maxNumber; i++) {
  //                   this.newTimings.push(this.timings[i]);
  //                 }
  //               }
  //             }
  //           })
  //         }
  //       }
  //     }
  //   })
  // }
  openInfoDialog(locationid:any,event: any, description: any, id: string, datafrom: string) {

    event.stopPropagation();
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '500px',
      width: '50%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      }
    }
    this.dialog.open(InfoComponent, {
      ...sizes,
      disableClose: true,
      data: {
        locationid,id, description, datafrom
      },
    });
  }

  changeSortby(sortBy: any): void {
    if (this.selectedSortBy !== sortBy) {
    this.selectedSortBy = sortBy;
    this.landingPageData = [];
    this.nonFiteredLandingData = [];
    this.landingPage = 1;
    this.landingPageLoadMore = false;
    this.loadMoreLandingPage('showmore', this.selectedSortBy);
    localStorage.setItem('selectedSortBy', this.selectedSortBy);
    }
  }


  openEnquireDialog(activitiesaddressline1: any, merchantId: any) {
    let datafromValue = activitiesaddressline1;
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '87%',
      width: '33%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      };
    }

    let data:any = {
      datafrom: datafromValue,
      activityType: "0",
    };
    if(merchantId && merchantId != ""){
      data.merchantId = merchantId
    }
    this.dialog.open(EnquiryFormComponent, {
      ...sizes,
      disableClose: true,
      data: data,
    });

  }
  openMap(event: any, latitude: any, longitude: any) {
    event.stopPropagation()
    this.dataService.openMap(latitude, longitude);
  }

  async loadFilteredRentalMerchants() {
    this.filterRentalMerchantsloading = true;
    let data: any = {};
    data.activityId = this.rentalForm.value.activityId;
    data.variations = [];
    this.rentalForm.value.variations.forEach((x: any) => {
      data.variations.push({ variationtypeid: x.variationid, variationvalueid: x.variationvalueid })
    })
    if (this.locationId != null) {
      data.locationId = this.locationId;
    }

    if (this.rentalForm.value.inventoryvariations.length > 0) {
      this.rentalForm.value.inventoryvariations.forEach((x: any) => {
        x.variationvalueid = this.rentalForm.value.variations.find((y: any) => y.variationid == x.variationid).variationvalueid
      })
    }
    let bookingsDates: any = [];
    let dates = this.getDates(new Date(this.rentalForm.value.date), new Date(this.rentalForm.value.dropdowndate));
    bookingsDates = dates;
    this.rentalForm.get('guest').setValue(bookingsDates.length);

    if (this.rentalForm.value.inventoryvariations.length > 0) {
      let inventoryMerchants: any = [];
      let inventoryInputData: any = {};
      inventoryInputData.activityId = this.rentalForm.value.activityId;
      inventoryInputData.variations = [];
      this.rentalForm.value.inventoryvariations.forEach((x: any) => {
        inventoryInputData.variations.push({ variationid: x.variationid, variationvalueid: x.variationvalueid })
      })
      if (this.locationId != null) {
        inventoryInputData.locationId = this.locationId;
      }
      let inventoryRes: any = await this.http.post(environment.apiUrl + `/merchantinventories/filterMerchant`, inventoryInputData).toPromise();
      if (inventoryRes.status === "SUCCESS") {
        inventoryMerchants = inventoryRes.merchantinventories;
      }

      if (inventoryMerchants.length == 0) {
        this.snackBar.open('Please fill all the fields.', 'close', { duration: 2000, verticalPosition: 'top' });
      } else {
        this.filteredInventoryMerchants = [];
        for (let i = 0; i < inventoryMerchants.length; i++) {
          let inventoryRes: any = await this.http.post(environment.apiUrl + `/merchantinventories/checkavailabilitydates`, { bookingDate: bookingsDates, merchantInventoryId: inventoryMerchants[i].id }).toPromise();
          if (inventoryRes.status === "SUCCESS") {
            if (inventoryRes.availability) {
              this.filteredInventoryMerchants.push(inventoryMerchants[i]);
            }
          }
        }
      }
    }
    this.http.post(environment.apiUrl + `/merchantactivities/filterMerchant`, data).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          this.filterRentalMerchantsloading = false;
          // this.showDateField = false;
          // this.showShadow = false;
          this.filteredRentalMerchants = [];
          for (let i=0; i<data.merchantactivities.length; i++){
            if (this.filteredInventoryMerchants.find((y: any) => y.merchantid == data.merchantactivities[i].merchantid)) {
              data.merchantactivities[i].rating = Math.round(data.merchantactivities[i].average_star);
           
              try {
                let price_res:any = await this.http.get(environment.apiUrl + `/range/actualprice?merchantActivityId=${data.merchantactivities[i].id}&date=${this.rentalForm.value.date} `).toPromise();
                  if (price_res.status === "SUCCESS") {
                    data.merchantactivities[i].price= price_res.price;
                  }
              } catch (error) {
                console.log(error)
              }
              if(!this.filteredRentalMerchants.find((x:any)=> x.id === data.merchantactivities[i].id)?.id){
                this.filteredRentalMerchants.push(data.merchantactivities[i]);
              }
              // this.filteredRentalMerchants.push(data.merchantactivities[i]);
            }
          }
          this.filteredRentalMerchants.sort((a, b) => Number(a.price) - Number(b.price));
          this.filteredRentalMerchants.forEach((merchant: any) => {
            try{
              this.http.post(environment.apiUrl + '/coupons/is-coupon-available',{status:1,locationId:this.locationId, merchantId: merchant.merchantid,activityId: merchant.activityid ,currentTimeStamp: new Date().getTime(), isMobileApp: this.ifMobileApp()}, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS" ) {
                  merchant.hasCoupon = data.coupons;
                  }else{
                    merchant.hasCoupon = false;
                  }
                  })
            }catch(error){
              console.log(error);
              merchant.hasCoupon = false;
            }
            merchant.src = [];
            merchant.shopsrc = [];
            if (merchant.merchantlatitude != undefined && merchant.merchantlongitude != undefined && merchant.merchantlatitude != null && merchant.merchantlongitude != null && merchant.merchantlatitude != 0 && merchant.merchantlongitude) {
              this.dataService.getActivitiesDistance(this.userLocation.latitude, this.userLocation.longitude, merchant.merchantlatitude, merchant.merchantlongitude).subscribe({
                next: (res: any) => {
                  let km = Math.round(res.distance_in_km)
                  merchant.distanceAway = `${km}${(Number(km) > 1) ? " Kms away" : "Km away"}`;
                }, error: (error) => {
                  console.log(error)
                }
              }
              );
            } else {
              merchant.distanceAway = "0Km away";
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantprofile&dataforid=${merchant.merchantid}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        merchant.src.push(res.upload.thumbnail)
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.get(environment.apiUrl + `/uploads/listbydatafor?datafor=merchantshopprofile&dataforid=${merchant.merchantid}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  for (const upload of data.uploads) {
                    this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${upload.id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        merchant.shopsrc.push(res.upload.thumbnail)
                      }
                    });
                  }
                }
              })
            } catch (error) {
              console.log(error)
            }
            try {
              this.http.post(environment.apiUrl + `/reviews/reviewstar`, { dataFor: 'merchant', dataForId: merchant.merchantid }, { responseType: 'json' }).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  merchant.avgReview = Math.round(data.reviews.average);
                  merchant.rating = data.reviews.total;
                }
              });
            } catch (error) {
              console.log(error)
            }
          })
        }
      }
    })
  }


  openBikeTour() {
    this.router.navigate(['/biketour'])
  }
  openLandingData(id: any, activityid: any, category: any, e: any) {
    if (e.target instanceof HTMLElement && (e.target.classList.contains('carousel-control-next-icon') || e.target.classList.contains('carousel-control-prev-icon'))) {
      e.stopPropagation();
      return;
    }
    
    if (category === "bikerental") {
      this.showPhase('bike-rental')
    }
   }
}