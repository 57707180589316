import { Component,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-popup-booking-details',
  templateUrl: './popup-booking-details.component.html',
  styleUrls: ['./popup-booking-details.component.css']
})
export class PopupBookingDetailsComponent {
  bookingDetails:any;
  variations:any;
  drivingSrc:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public bookingDetailsDialogRef: MatDialogRef<PopupBookingDetailsComponent>, public dialog: MatDialog,  private http:HttpClient) { 
    this.bookingDetails = data;
    if(data.bookingdetailsjson != null && data.bookingdetailsjson != '' && JSON.parse(data.bookingdetailsjson) != undefined){
      this.variations = JSON.parse(data.bookingdetailsjson);
    }
    if(this.bookingDetails.category === "camping"){
      this.bookingDetails.guest = this.variations.guest;
    }
    if(this.bookingDetails.category === "bikerental" && this.variations.drivinglicenceId){
      this.http.get(environment.apiUrl + `/uploads/getThumbnail?id=${this.variations.drivinglicenceId}`).subscribe((data:any)=>{
        if(data.status === "SUCCESS"){
          this.drivingSrc = data.upload.thumbnail;
        }
      })
      if(this.variations && this.variations != ""){
      this.bookingDetails.dropdowndate = this.variations.dropdowndate;
      this.bookingDetails.dropdowntime = this.variations.dropdowntime;
      }
    }
    this.bookingDetails.paymentamount =   this.bookingDetails.paymentamount === null ? "N/A" : Number(this.bookingDetails.paymentamount);
   }

   ngOnInit(){

   }

   closePopUpDialog(){
      this.bookingDetailsDialogRef.close();
   }
}
