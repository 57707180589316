import {  Component ,Inject} from '@angular/core';
import { MatDialog,MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.css']
})

export class ModalAlertComponent {
  title='';
  message = '';
  status= '';
  constructor(public dialog: MatDialog, private modalAlertRef: MatDialogRef<ModalAlertComponent>, @Inject(MAT_DIALOG_DATA) data: {title:string, message: string, status:string }) {
    this.title = data ? data.title : '';
    this.message = data ? data.message : '';
    this.status = data ? data.status : '';
  }
  
  closeAlert(){
    this.modalAlertRef.close();

  }
}
